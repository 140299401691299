<template>
  <div class="scroll-wrapper">
    <div class="task-wrapper">
      <FeedbackDisplay v-if="shouldBeShowingFeedback"
                       :on-press-next="() => {}"/>
      <slide-header :description="slideContent.description" :title="slideContent.title"/>
      <div class="slide-image-wrapper">
        <img :src="slideContent.questionContent.content" class="slide-image">
      </div>
    </div>
  </div>
  <game-footer :continue-button-click="finishText" :continue-button-text="$t('STATIC.NEXT')"/>
</template>

<script>
import SlideHeader from '@/components/elements/SlideHeader'
import { POST_SLIDE_ANSWER } from '@/store/actions'
import FeedbackDisplay from '../FeedbackDisplay'

import { mapGetters } from 'vuex'

export default {
  name: 'Image',
  components: { SlideHeader, FeedbackDisplay },
  props: {
    slideContent: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({ historyLocation: 'getHistoryLocation' }),
    shouldBeShowingFeedback() {
      return this.historyLocation?.isPostAnswer
    },
  },
  emits: ['nextSlide'],
  methods: {
    finishText() {
      const formData = new FormData()
      this.$store.dispatch(POST_SLIDE_ANSWER, formData).then(response => {
        this.$emit('nextSlide')
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";
@import "~@/assets/css/mission.scss";

.slide-image-wrapper {
  margin: 0 auto 0 auto;
  max-width: max-content;
  border-radius: 10px;
  padding: rem(12);
  background-color: white;
}

.slide-image {
  max-height: rem(500);
  max-width: 100%;
  border-radius: 10px;

  @media screen and (min-width: $screen-xl) {
    max-width: 30rem;
  }
}

@media screen and (max-width: $screen-720p) {
  .slide-image-wrapper {
    height: 35vh;

    .slide-image {
      max-height: 100%;
    }
  }
}

</style>
