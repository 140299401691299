<template>
  <div class="media-quiz-banner-wrapper">
    <p class="banner-text">Speel de MediaQuiz en verdien vandaag {{ maximumBits }} bits voor je klas. De volgende dag is
      opnieuw dit maximale aantal bits te behalen.</p>
    <div v-if="isLoggedIn" class="progress-bar-wrapper">
      <BitsBar :bit-count="earned" :maximum-bits="maximumBits" :percentage="progress" class="bits-bar"/>
      <img class="bits-icon" src="@/assets/icons/icn_bits.svg"/>
    </div>
    <BasicButton :button-text="$t('MEDIA_QUIZ.START')" class="start-mediaquiz-button" @click="startedMediaQuiz"/>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
import BitsBar from '../progress/BitsBar.vue'
import BasicButton from '../basic/BasicButton.vue'

export default {
  name: 'MediaQuizBanner',
  components: {
    BitsBar,
    BasicButton,
    InlineSvg
  },
  props: {
    earned: {
      type: Number,
      required: true
    },
    startedMediaQuiz: {
      type: Function,
      required: true
    },
    progress: {
      type: Number,
      required: true
    },
    maximumBits: {
      type: Number,
      required: false,
      default: 100,
    },
    isLoggedIn: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.media-quiz-banner-wrapper {
  display: flex;
  position: relative;
  background-color: var(--back_color);
  flex-direction: column;
  width: rem(620);
  margin: rem(60) auto rem(0);
  border-radius: rem(20);
  padding: rem(20) rem(115);
  gap: rem(20);
  box-shadow: inset 0 1px 2px 0 #341C69, 0 2px 30px 0 rgba(0, 0, 0, 0.2);
}

.progress-bar-wrapper {
  display: flex;
  gap: rem(10);
  position: relative;
}

.bits-icon {
  position: absolute;
  top: -10px;
  right: 0px;
}

.bits-bar {
  width: 95%;
}

.banner-text {
  font-size: rem(17);
  color: white;
}

a {
  display: flex;
  text-decoration: none;

  * {
    flex-grow: 1;
  }
}

.start-mediaquiz-button {
  font-weight: 500;
}

@media screen and (max-width: 800px) {
  .media-quiz-banner-wrapper {
    width: calc(100% - 32px);
    padding: rem(25) rem(20) rem(20);
  }
}
</style>
