import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import nlStrings from './utils/language/nl.json'
import { createI18n } from 'vue-i18n'
import VimeoPlayer from '@/components/partials/video/VimeoPlayer'
import GameFooter from '@/components/GameFooter'
import VueGtag from 'vue-gtag-next'

const i18n = createI18n({
  locale: 'nl',
  fallbackLocale: 'nl',
  messages: { nl: nlStrings },
})

const vueApp = createApp(App)

// enable Analytics only for staging and
if (!process.env.VUE_APP_IS_LOCAL) {
  vueApp.use(VueGtag, {
    property: {
      id: process.env.VUE_APP_GTAG,
      // NOTE: THIS MUST BE FALSE OTHERWISE PAGE VIEWS ARE COUNTED DOUBLE. trackRouter() FUNCTION IN THE ROUTER FILE HANDLES SENDING PAGE VIEW EVENTS TO GOOGLE ANALYTICS!
      params: {
        send_page_view: false,
      }
    }
  })
}

vueApp.use(router)
vueApp.use(store)
vueApp.use(i18n)
vueApp.component(VimeoPlayer.name, VimeoPlayer)
// Register footer globally, because it is used in many places
vueApp.component(GameFooter.name, GameFooter)

vueApp.mount('#app')
