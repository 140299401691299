import { createStore } from 'vuex'
import { getters } from './getters'
import { mutations } from './mutations'
import { actions } from './actions'
import { state } from './state'
import { Extras } from './modules/extras/index.js'

export default createStore({
  namespaced: false,
  modules: {
    Extras
  },
  state,
  mutations,
  actions,
  getters,
})
