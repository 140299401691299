<template>
  <div class="scroll-wrapper">
    <div class="task-wrapper">
      <FeedbackDisplay v-if="shouldBeShowingFeedback"
                       :on-press-next="closeFeedback"
                       :was-correct="showCorrectVideo"/>
      <div v-else>
        <SlideHeaderQuiz v-if="isQuiz"
                         :description="slideContent.questionContent.question"
                         :title="slideContent.title"/>
        <slide-header :description="slideContent.description" :title="slideContent.title"/>

        <div :style="historyLocation !== null ? 'pointer-events: none' : ''" class="answers-container">
          <!-- todo check why here there is :data, which seems illegal-->
          <div
            v-for="(answer, index) in slideContent.questionContent.answers"
            :key="answer.id"
            class="answer-wrapper"
          >
            <div class="answer-letter">{{ alphabet[index] }}.</div>

            <div :class="getAnswerClass(answer.id)"
                 class="answer"
                 @click="() => {
                   if(historyLocation === null) {
                     selectedAnswer = answer.id
                   }
                 } ">
              <input
                :id="`answer-${answer.id}`"
                v-model="selectedAnswer"
                :disabled="result || removedAnswers.includes(answer.id)"
                :value="answer.id"
                name="basic-checkbox"
                type="radio"
              >
              <div>{{ answer.answer }}</div>
            </div>
          </div>
        </div>
      </div>

      <!--      <FeedbackModal
              :callback="closeFeedback"
              :is-shown="showFeedbackModal"
              :modal-text="feedbackText"
              :modal-title="$t('FEEDBACK.FEEDBACK')"
              :video-url="feedbackVideoUrl"
            />-->
    </div>

  </div>
  <game-footer
    v-if="!shouldBeShowingFeedback"
    :continue-button-click="checkAnswers"
    :continue-button-disabled="!selectedAnswer"

    :continue-button-text="$t('FOOTER.SINGLE_ANSWER.CONTINUE_BUTTON_TEXT')"
    :feedback-button-click="showFeedback"
    :feedback-button-text="$t('FOOTER.FEEDBACK_BUTTON_TEXT')"
    :feedback-result="result"
    :force-next="shouldBeShowingFeedback"
    :has-feedback-popup="feedbackText"
  />
</template>

<script>
import GameFooter from '@/components/GameFooter'
import { UPDATE_BITS } from '@/store/mutations'
import i18n from '@/setup/i18n/i18n'
import SlideHeader from '@/components/elements/SlideHeader'
import SlideHeaderQuiz from '@/components/elements/SlideHeaderQuiz'
import { POST_SLIDE_ANSWER, POST_SLIDE_ANSWER_MEDIA_QUIZ } from '@/store/actions'
import { mapGetters } from 'vuex'
import FeedbackDisplay from './FeedbackDisplay'

export default {
  name: 'SingleChoice',
  components: { FeedbackDisplay, SlideHeader, GameFooter, SlideHeaderQuiz },
  props: {
    slideContent: {
      type: Object,
      required: true,
    },
    isQuiz: {
      type: Boolean,
      required: false,
      default: false
    },
    classId: {
      type: Number,
      required: false,
      default: null
    }
  },
  emits: ['nextSlide'],
  data: () => ({
    alphabet: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
    selectedAnswer: null,
    result: undefined,
    feedbackText: '',
    feedbackVideoUrl: '',
    removedAnswers: [],
    startTime: new Date(),
  }),
  computed: {
    ...mapGetters({
      jokerResult: 'getJokerResult',
      /** @type {HistoryLocation} */
      historyLocation: 'getHistoryLocation',
      showFeedbackModal: 'getShowFeedbackModal',

    }),
    shouldBeShowingFeedback() {
      return this.historyLocation?.isPostAnswer || this.showFeedbackModal
    },
    correctAnswers() {
      const answers = this.$store.getters.getCorrectAnswers
      if (answers === undefined || answers === null) {
        return null
      }
      console.log('got correct answers', answers)
      return answers
    },
    givenAnswers() {
      /*      if (this.selectedAnswers.length > 0) {
              return this.selectedAnswers
            }*/

      const answers = this.$store.getters.getClientAnswer
      console.log('got given answers', answers)
      if (Array.isArray(answers)) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.selectedAnswer = answers
        return answers
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.selectedAnswers = answers
        return answers
      }
    },
    showCorrectVideo() {
      return this.correctAnswers === this.givenAnswers
    },
    wasCorrect() {
      return this.result && this.result.successPercentage === 1
    }
  },
  watch: {
    jokerResult: {
      deep: true,
      handler: function(curr, prev) {
        if (curr && curr.wrongAnswersToRemove) {
          this.removedAnswers = curr.wrongAnswersToRemove.map(value => value.id)
          if (this.removedAnswers.includes(this.selectedAnswer)) {
            this.selectedAnswer = null
          }
        }
      }
    },
    slideContent: {
      deep: true,
      handler: function(curr, prev) {
        this.selectedAnswer = null
      }
    }
  },
  methods: {
    checkAnswers() {
      let storeDispatchFunction

      const formData = new FormData()
      if (this.isQuiz) {
        storeDispatchFunction = POST_SLIDE_ANSWER_MEDIA_QUIZ
        const schoolData = localStorage.getItem('schoolClass')
        const timeSpend = new Date() - this.startTime
        formData.append('timeTakenInMilliseconds', timeSpend)
        if (schoolData) {
          const schoolDataParsed = JSON.parse(schoolData)
          formData.append('schoolClassId', schoolDataParsed.schoolClass.id)
        }
        formData.append('mediaQuizSlideId', this.slideContent.mediaQuizSlideId)
      }
      // mission
      else {
        storeDispatchFunction = POST_SLIDE_ANSWER
        formData.append('missionId', this.slideContent.missionId)
        formData.append('slideIndex', this.slideContent.slideIndex)
      }

      // console.log('this.selectedAnswer', this.selectedAnswer);

      formData.append('answer', this.selectedAnswer)
      this.$store.dispatch(storeDispatchFunction, formData)
        .then(response => {
          if (response.isAnswerCorrect) {
            this.result = {
              successPercentage: 1,
              title: i18n.global.tc('FOOTER.GOOD_JOB'),
              id: response.correctAnswer
            }
          } else {
            const correctAnswer = this.slideContent.questionContent.answers.find(elem => elem.id === response.correctAnswer).answer
            this.result = {
              successPercentage: 0,
              title: i18n.global.tc('FOOTER.SINGLE_ANSWER.FEEDBACK_TITLE') + " '" + correctAnswer + "'.",
              id: response.correctAnswer
            }
          }
          this.feedbackText = response.feedback
          this.feedbackVideoUrl = response.feedbackVideoUrl
          this.$store.commit(UPDATE_BITS, response.awardedBits)

          this.$nextTick().then(() => {
            const mainContent = document.getElementById('main-content')
            if (mainContent) {
              mainContent.scrollTop = mainContent.scrollHeight
            }
          })
        })
    },
    showFeedback() {
      //Show feedback modal
      if (this.feedbackText || this.feedbackVideoUrl) {
        this.$store.dispatch('showFeedbackModal')
      } else {
        this.$emit('nextSlide', this.result && this.result.successPercentage === 1)
      }
    },
    closeFeedback() {
      // Hides feedback modal
      this.$store.dispatch('hideFeedbackModal')
      this.$emit('nextSlide', this.result && this.result.successPercentage === 1)
    },
    getAnswerClass(answerId) {
      const answerGiven = this.selectedAnswer != null ? this.selectedAnswer : this.givenAnswers
      // Answer is selected, before result is in
      if (!this.correctAnswers && answerGiven === answerId) {
        return 'answer-selected'
      } else if (this.correctAnswers) {
        // We got a result & styling our selected answer
        if (answerGiven === answerId) {
          // Is selected answer correct?
          if (this.correctAnswers === answerId) {
            return 'answer-correct'
          } else {
            return 'answer-incorrect'
          }
          // Is answer not selected & correct?
        } else if (this.correctAnswers === answerId) {
          return 'answer-correct-dark'
        } else {
          // Default not selected answer
          return 'answer-dark'
        }
      } else if (this.removedAnswers.includes(answerId)) {
        return 'answer-removed'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";
@import "~@/assets/css/mission.scss";
@import "~@/assets/css/quiz.scss";

// Override task-wrapper, from mission.scss. Because margin is to wide
.answers-container {
  margin-top: rem(48);

  .answer-wrapper {
    display: flex;
    align-items: center;
    margin-top: 18px;
    margin-right: 42px;

    .answer-letter {
      display: flex;
      align-self: center;
      font-size: rem(24);
      font-weight: 800;
      margin-right: 16px;
    }

    .answer {
      width: 100%;
      height: 100%;
      background-color: var(--answer_default_color);
      color: var(--text_color);
      padding: 12px;
      cursor: pointer;
      border-radius: rem(10);
      font-weight: 800;
      font-size: rem(24);

      @media screen and (min-width: $screen-xl) {
        font-size: rem(20);
      }

      display: flex;
      align-items: center;
      justify-content: center;

      transition: background-color 0.15s, color 0.15s;

      // Shadow
      @include box-shadow(4px, 4px, 0px, rgba(0, 0, 0, .2), 0px, false);

      span {
        text-align: center;
      }

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
      }
    }

    .answer-dark {
      background-color: var(--answer_default_dark_color)
    }

    .answer-selected {
      background-color: var(--primary_color);
      color: #FFFFFF;
    }

    .answer-correct {
      background-color: var(--answer_correct_color);
      color: #FFFFFF;
    }

    .answer-correct-dark {
      @extend .answer-correct;
      background-color: var(--answer_correct_dark_color);
      color: var(--answer_white_dark_text_color);
    }

    .answer-incorrect {
      background-color: var(--answer_incorrect_color);
      color: #FFFFFF;
    }

    .answer-removed {
      pointer-events: none;
      background-color: #9590A4;
    }
  }
}

@media screen and (max-width: $screen-720p) {
  .answers-container {
    margin-top: 14px;

    .answer-wrapper {
      margin-right: 0;

      .answer {
        font-size: rem(16);
      }

      .answer-letter {
        font-size: rem(18);
      }
    }
  }

  .task-wrapper {
    padding: 2.4rem 2rem;
  }
}

// pure mobile
//@media screen and (max-width: $screen-sm) {
//.scroll-wrapper {
//  margin-top: 16px;
//}
//}

// landscape mobile
@media (orientation: landscape) and (max-height: 500px) {
  .scroll-wrapper .task-wrapper {
    padding-top: 0;
    padding-left: 16px;
    padding-right: 16px;
  }
  .answers-container {
    margin-top: 0;
  }
}
</style>
