export default class NetiquetteType {
  /**
   * @type {number} the id of the answer.
   */
  id;

  /**
   * @type {string} the text or image url of the answer.
   */
  name;

  constructor(id, name) {
    this.id = id
    this.name = name
  }

  static parseFromDataObject(data) {
    return new NetiquetteType(
      data.id,
      data.name)
  }
}
