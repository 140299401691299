<template>
  <div class="game-box-wrapper">
    <img src="@/assets/icons/icn_info.svg" class="info-icon" alt="" />
    <div class="image-wrapper">
      <img
        src="@/assets/images/img_extras_info.svg"
        class="cover-image"
        loading="lazy"
        alt=""
      />
    </div>
    <div class="info-text-container">
      <h1 class="info-title">Bonus</h1>
      <p class="info-text">
        Doe je met de klas 6 verschillende opdrachten per dag? Dan verdien je 60
        extra bonus bits per dag!
      </p>

      <div class="amount-played-ribbon">
        <img src="@/assets/icons/joystick.svg" />
        <div class="amount-played-text">
          {{ extraGamesPlayed }} / 6 gespeeld
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
export default {
  name: 'InfoGameBox',
  props: {
    extraGames: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    console.log('calulating')
    const extraGamesPlayed = computed(() => {
      const value = props.extraGames.reduce((acc, game) => {
        if (game.extraGamePlayedCount !== '0') {
          acc++
        }
        return acc
      }, 0)
      return Math.min(value, 6)
    })

    return {
      extraGamesPlayed,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/global.scss";
@import "~@/assets/css/base.mixins";

$border-radius-game-box: rem(24);

.game-box-wrapper {
  position: relative;
  height: 400px;
  max-height: 400px;
  border-radius: $border-radius-game-box;
  background: #4d4c8a;
  box-shadow: inset 0 1px 2px 0 #341c69, 0 2px 30px 0 rgba(0, 0, 0, 0.2);
}

.info-icon {
  @include position(absolute, $top: rem(15), $left: 50%);
  transform: translateX(-50%);
  width: rem(40);
}

.image-wrapper {
  width: inherit;
  height: inherit;
  border-radius: $border-radius-game-box;
  .cover-image {
    height: 100%;
    width: 100%;
    border-radius: $border-radius-game-box;
  }
}

.info-text-container {
  @include position(absolute, $bottom: 0);
  text-align: center;
  width: 100%;
  color: #fff;
  padding: 0 rem(25) rem(12);

  .info-title {
    font-size: rem(24);
    margin-bottom: rem(10);
    font-weight: 400;
  }

  .info-text {
    font-size: rem(16);
  }
}

.amount-played-ribbon {
  display: flex;
  align-items: center;
  margin: 16px auto 0 auto;
  justify-content: center;
  padding: 10px;
  width: 210px;
  height: 48px;
  background-color: #402f79;
  color: white;
  font-size: 18px;
  font-weight: 600;
  border-radius: $border-radius-game-box;

  img {
    margin-left: 2px;
    margin-right: 6px;
    width: 32px;
  }
}

@media screen and (max-width: 800px) {
  .game-box-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .image-wrapper {
    border-radius: $border-radius-game-box;
    .cover-image {
      height: 100%;
      width: 100%;
      border-radius: $border-radius-game-box;
    }
  }
}
</style>
