<template>
  <div class="conversation-card">
    <!--    <div class="duration-wrapper">-->
    <!--      <img src="../../assets/icons/icn_duration.svg" alt="" class="duration-icon">-->
    <!--      <p class="duration-text">{{ durationMinutes }}m</p>-->
    <!--    </div>-->
    <h1 class="title">{{ title }}</h1>
    <div class="center-wrapper">
      <div class="background-gradient" />
      <img :src="image" alt="" class="center-icon">
    </div>
    <p class="description">{{ description }}</p>
    <button @click="buttonClicked">
      {{ $t('CLASS_CONVERSATION.START_CONVERSATION') }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'ConversationCard',
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    image: {
      type: String,
      required: true
    },
    buttonClicked: {
      type: Function,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";
@import "~@/assets/css/global.scss";

.conversation-card {
  background-color: #626DC6;
  border-radius: rem(20);
  width: rem(258);
  padding: rem(24) rem(24) rem(28) rem(24);
  display: flex;
  gap: rem(12);
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;

  .duration-wrapper {
    padding: rem(8);
    display: flex;
    gap: rem(7);
    background-color: rgba(white, 0.16);
    width: fit-content;
    border-radius: 50vh;

    .duration-text {
      font-weight: 600;
      font-size: rem(16);
      color: white;
      line-height: rem(19);
    }
  }
  .title {
    text-transform: uppercase;
    color: white;
    font-size: rem(24);
  }
  .center-wrapper {
    height: rem(237);
    background: url("../../assets/images/img_pattern_day1.png"), linear-gradient(180deg, #3D4CC1 0%, #707EF1 100%);
    border-radius: rem(20);
    padding: rem(16);
    position: relative;
    z-index: 2;

    .background-gradient {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: radial-gradient(circle, rgba(255,255,255,0.6) 0%, rgba(0,0,0,0) 50%);
      z-index: 1;
    }

    .center-icon {
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
      z-index: 3;
    }
  }
  .description {
    color: white;
    font-size: rem(16);
    line-height: rem(24);
    height: 10rem;
    overflow: hidden auto;

    &::-webkit-scrollbar {
      width: rem(10);
      border-radius: rem(10);
      margin-bottom: rem(20);
      position: absolute;
      left:10px
    }

    &::-webkit-scrollbar-thumb {
      background-color: #b6b9c7;
      @include border-radius(rem(4));
    }

  }
  button {
    cursor: pointer;
    border-radius: rem(8);
    background-color: white;
    border: none;
    padding: rem(13) rem(44);
    font-family: var(--font-family-primary);
    box-shadow: rem(4) rem(4) 0 0 rgba(0,0,0,0.1);
    color: #373B52;
    font-size: rem(18);
    font-weight: 600;
    transition: filter .1s;

    &:hover {
      filter: brightness(.9);
    }
    &:active {
      filter: brightness(.8);
    }
  }
}
</style>
