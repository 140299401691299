<template>
  <div class="bits-container" :class="{'large-bits-container' : large}">
    <img class="bits-icon" src="@/assets/icons/icn_bits.svg"/>
    <div class="bits-amount">
      <div v-if="large" class="bits-title">{{ $t('STATIC.BITS') }}</div>
      <div class="bits bits-placeholder">{{ bitStringLengthPlaceholder }}</div>
      <div class="bits">{{ animateAmount }}</div>
    </div>
    <transition
      name="bounce"
      @after-enter="afterEnter"
    >
      <div v-if="showNotification" class="bit-popup">
        <div class="bit-popup-amount">
          <img src="@/assets/icons/icn_bits.svg"/>
          <div class="bits">{{ bitsGained }}  </div>
        </div>
        <div v-if="showDouble" class="bit-popup-double bits"> x2 </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'BitsDisplay',
  props: {
    amount: {
      type: Number,
      default: 0,
    },
    animate: {
      type: Boolean,
      default: true,
    },
    large: {
      type: Boolean,
      default: false,
    },
    popupOnIncrease: {
      type: Boolean,
      default: false,
    },
    neverDecrease: {
      type: Boolean,
      default: false
    },
    showDouble: {
      type: Boolean,
      default: false
    },
  },
  data() {
    const timeout = null

    return {
      showNotification: false,
      bitsGained: 0,
      timeout,
      animateAmount: this.animate ? 0 : this.amount
    }
  },
  computed: {
    // used to prevent layout shift while 'counting up slowly' bit animation is running
    bitStringLengthPlaceholder() {
      const bitAmountStringLength = this.amount.toString().length
      // 0 is the widest digit char
      return Array(bitAmountStringLength).fill('0').join('')
    }
  },
  watch: {
    amount: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal < oldVal && this.neverDecrease) {
          return
        }
        if (this.timeout) {
          clearTimeout(this.timeout)
        }
        if (this.animateAmount !== newVal) {
          this.bitsGained = newVal - oldVal
          if (this.popupOnIncrease && this.bitsGained > 0) {
            this.showNotification = true
          }
          if (this.animate) {
            this.animateTo(newVal)
          } else {
            this.animateAmount = newVal
          }
        }
      }
    }
  },
  methods: {
    animateTo(newAmount, maxChange) {
      maxChange = Math.ceil((Math.abs(this.animateAmount - newAmount) / 100))
      if (newAmount === this.animateAmount) {
        clearTimeout(this.timeout)
        return
      }

      const change = Math.min(Math.abs(newAmount - this.animateAmount), maxChange) * Math.sign(newAmount - this.animateAmount)

      this.animateAmount += change

      this.timeout = setTimeout(() => {
        this.animateTo(newAmount, maxChange)
      }, Math.min(800, (maxChange * 1000)) / Math.abs(newAmount - this.animateAmount))
    },
    afterEnter() {
      // Play "disappear" animation after x amount of milliseconds
      setTimeout(() => {
        this.showNotification = false
      }, 800)
    }
  }
}

</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";
@import "~@/assets/css/global.scss";

.bits-container {
  display: flex;
  position: relative;
  align-items: center;
  max-height: 60px;
}

.large-bits-container {
  padding:  rem(16);
  min-width: rem(150);
  width: fit-content;
  border-radius:rem(10);
  background-color: var(--back_color);
}

.bits {
  color: #FEEAA7;
  font-size: rem(38);
  font-weight: bold;
  letter-spacing: 0;
  line-height: rem(38);
  text-shadow: 0 4px #312C64;
}

.bits-placeholder {
  visibility: hidden;
  height: 0;
}

.bits-amount {
  padding-left: rem(14);
}

.bits-icon{
  width: 50px;
}

.bits-title{
  font-size: rem(14);
  color: #dbdbdb;
}

.bit-popup {
  position: absolute;
  left: rem(15);
  top: 100px;
  color: #FFE0AC;
}

.bit-popup-amount{
  display:flex;
  gap: rem(14);
  align-items: center;

}

.bit-popup-double{
  text-align: center;
  margin-top: rem(10);
  font-size: rem(50);
  opacity: 0.8;
}

.new-line{
  clear: both;
}
.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
</style>
