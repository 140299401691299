/**
 * A history location holds at what point the user is in the history of the session.
 */
export default class HistoryLocation {
  currentSlideIndex;

  currentSubSlideIndex;

  isPostAnswer;

  isGoingForward;

  constructor(currentSlideIndex, isPostAnswer, currentSubSlideIndex, isGoingForward = true) {
    this.currentSlideIndex = currentSlideIndex
    this.currentSubSlideIndex = currentSubSlideIndex
    this.isPostAnswer = isPostAnswer
    this.isGoingForward = isGoingForward
  }

  /**
   *
   * @param {SessionHistory} sessionHistory
   * @param submissionResult
   * @param isPostAnswer
   * @return {HistoryLocation}
   */
  static createFromSessionHistory(sessionHistory, submissionResult, isShowingFeedbackModal) {
    /** @type {HistoryLocation} */
    //now move back one step.
    var historyAtPreviousPoint = sessionHistory.getCurrentHistoryLocation().goBackward(sessionHistory)
    console.log('were working out the history')
    if (submissionResult != null && !isShowingFeedbackModal) {
      historyAtPreviousPoint = historyAtPreviousPoint.goBackward(sessionHistory).goBackward(sessionHistory)
    }
    return historyAtPreviousPoint
  }

  /**
   * @param {SessionHistory} sessionHistory
   * @return {HistoryLocation| null} returns our new location, or null if we have reached the end of the history (current slide).
   */
  goForward(sessionHistory) {
    const currentSlide = sessionHistory.previousSlides[this.currentSlideIndex]

    //see if we can move forward on a sub slide.
    if (this.currentSubSlideIndex != null) {
      const currentSubSlide = currentSlide.subSlideAnswers[this.currentSubSlideIndex]
      if (currentSubSlide.hasFeedback && !this.isPostAnswer) {
        return new HistoryLocation(this.currentSlideIndex, true, this.currentSubSlideIndex, true)
      }

      if (currentSlide.subSlideAnswers != null && currentSlide.subSlideAnswers.length > this.currentSubSlideIndex + 1) {
        if (sessionHistory.currentSubSlideIndex < this.currentSubSlideIndex + 1 && currentSlide.slideIndex === sessionHistory.currentSlideIndex) {
          return null
        } else {
          return new HistoryLocation(this.currentSlideIndex, false, this.currentSubSlideIndex + 1, true)
        }
      }
    }

    //else move forward on the main slide

    if (sessionHistory.currentSlideIndex === this.currentSlideIndex) {
      return null
    } else {
      if (currentSlide.hasFeedback && !this.isPostAnswer) {
        return new HistoryLocation(this.currentSlideIndex, true, this.currentSubSlideIndex, true)
      }

      const nextSlideIndex = this.currentSlideIndex + 1

      if (nextSlideIndex >= sessionHistory.currentSlideIndex - 1) {
        return null
      }
      //Get the next slide, and see if it has subslides
      const nextSlide = sessionHistory.previousSlides[nextSlideIndex]

      return new HistoryLocation(nextSlideIndex, false, nextSlide.subSlideAnswers == null ? null : 0, true)
    }
  }

  /**
   * Goes back in history, if possible.
   * @param {SessionHistory} sessionHistory
   * @return {HistoryLocation} returns our new location.
   */
  goBackward(sessionHistory) {
    console.error('going back')
    if (this.isPostAnswer) {
      return new HistoryLocation(this.currentSlideIndex, false, this.currentSubSlideIndex, false)
    }

    //check if we can move backwards in subslides.
    if (this.currentSubSlideIndex != null && this.currentSubSlideIndex > 0) {
      return new HistoryLocation(this.currentSlideIndex, true, this.currentSubSlideIndex - 1, false)
    }

    //else move backwards on the main slide

    //If we are on the first slide, we can't go back.
    if (this.currentSlideIndex === 0) {
      return new HistoryLocation(this.currentSlideIndex, this.isPostAnswer, this.currentSubSlideIndex, false)
    }

    //check if the previous slide has subslides.
    const previousSlide = sessionHistory.previousSlides[this.currentSlideIndex - 1]

    if (previousSlide.subSlideAnswers != null && previousSlide.subSlideAnswers.length > 0) {
      return new HistoryLocation(this.currentSlideIndex - 1, previousSlide.hasFeedback, previousSlide.subSlideAnswers.length - 1, false)
    }

    return new HistoryLocation(this.currentSlideIndex - 1, previousSlide.hasFeedback, null, false)
  }

  /**
   * Fast-forward to the end of the history, this returns a null object in the current setup, as no history location, means the current slide.
   * @param sessionHistory
   * @return {null}
   */
  fastForward(sessionHistory) {
    return null
  }

  getCurrentSlideId(sessionHistory) {
    if (this.currentSlideIndex == null) {
      return sessionHistory.currentSlideId
    }
    return sessionHistory.previousSlides[this.currentSlideIndex].slideId
  }
}
