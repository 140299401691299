import AccessibilityTypeId from '@/models/AccessibilityTypeId'

export class SlideVideo {
  /**
   * The url of the video.
   * @type {string}
   */
  videoUrl;

  /**
   * The url of the video with ASL.
   * @type {string}
   */
  videoDeafUrl;

  /**
   * The url of the video with audio description.
   * @type {string}
   */
  videoBlindUrl;

  /**
   * The url of the video with subtitles.
   * @type {string}
   */
  videoSubtitlesUrl;

  constructor(videoUrl, videoDeafUrl, videoBlindUrl, videoSubtitlesUrl) {
    this.videoUrl = videoUrl
    this.videoDeafUrl = videoDeafUrl
    this.videoBlindUrl = videoBlindUrl
    this.videoSubtitlesUrl = videoSubtitlesUrl
  }

  static parseFromDataObject(json) {
    if (json == null) {
      return null
    }
    return new SlideVideo(json.videoUrl, json.videoDeafUrl, json.videoBlindUrl, json.videoSubtitlesUrl)
  }

  getVideoUrl() {
    console.log('getting video URL')
    try {
      const loginData = JSON.parse(localStorage.getItem('schoolClass'))
      const accessibilityType = loginData?.schoolClass?.accessibilityTypeId

      switch (accessibilityType) {
        case AccessibilityTypeId.USING_AUDIO_DESCRIPTION:
          return this.videoBlindUrl ?? this.videoUrl
        case AccessibilityTypeId.SIGN_LANGUAGE_ASSISTED:
          return this.videoDeafUrl ?? this.videoUrl
        case AccessibilityTypeId.USING_SUBTITLES:
          return this.videoSubtitlesUrl ?? this.videoUrl
        default:
          return this.videoUrl
      }
    } catch (e) {
      console.log('Error getting accessibility type')
      console.error(e)
      return this.videoUrl
    }
  }
}
