<template>
  <Modal :is-shown="isShown" :custom-class="'bonus-modal-wrapper'">
    <template #content>
      <img :src="bonusData.icon" alt="">
      <h1 class="bonus-title">{{ bonusData.title }}</h1>
      <p class="reward-text">{{ $t('SHARED.EXTRA') + ' ' + $t('SHARED.REWARD') }}:</p>
      <div class="reward-container">
        <BitsDisplay :amount="bonus.bits"/>
      </div>
      <button class="bonus-confirm-btn pink-button" @click="$emit('dismiss')">{{ $t('SHARED.OK') }}</button>
      <div class="banner-holder"/>
      <img class="banner banner-left" src="@/assets/images/img_flag_blue.png"/>
      <img class="banner banner-right" src="@/assets/images/img_flag_red.png"/>
      <Balloons :count="10"/>
    </template>
  </Modal>

</template>

<script>

import imgTime from '@/assets/images/img_gold_time.png'
import imgFire from '@/assets/images/img_gold_fire.png'
import BitsDisplay from '../../elements/basic/BitsDisplay.vue'
import Modal from './Modal.vue'
import Balloons from '@/components/elements/Balloons'
import { computed } from '@vue/runtime-core'

export default {
  name: 'BonusModal',
  components: {
    Balloons,
    BitsDisplay,
    Modal,
  },
  props: {
    bonus: {
      type: Object,
      required: true
    },
    isShown: {
      type: Boolean,
      required: true,
    }
  },
  emits: ['dismiss'],
  setup(props) {
    const bonuses = [
      { id: 2, title: 'Snelheidsbonus!', icon: imgTime },
      { id: 1, title: 'Streakbonus!', icon: imgFire }
    ]

    const bonusData = computed(() => {
      return props.bonus ? bonuses.find(bonus => bonus.id === props.bonus.id) : null }
    )

    return {
      bonusData
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.bonus-modal-wrapper {
  width: rem(550);
  height: rem(630);
  background-color: #4D4C89;
  border-radius: rem(20);
  padding: rem(90) rem(75) 0;
}

.bonus-title {
  font-size: rem(42);
  font-weight: bold;
  letter-spacing: 0;
  line-height: rem(36);
  text-align: center;
  margin-top: rem(40);
}

.reward-text {
  opacity: 0.65;
  font-size: rem(28);
  letter-spacing: 0;
  line-height: rem(36);
  text-align: center;
  margin-top: rem(32);
}

.reward-container {
  display: flex;
  justify-content: center;
  margin: rem(10) 0 rem(32);
}

.bits {
  color: #FEEAA7;
  font-size: rem(42);
  font-weight: bold;
  letter-spacing: 0;
  line-height: rem(46);
  text-shadow: 0 4px #312C64;
  margin-left: rem(14);
}

.banner-holder{
  overflow:hidden;
}

.banner{
  position: absolute;
}

.banner-left{
  top:0;
  left:0;
  transform-origin:left top;
  transform: scale(0.95,1.1);
}

.banner-right{
  transform-origin:right top;
  transform: scale(0.9,1.2);
  top:0;
  right:0;
}

.bonus-confirm-btn {
  width: rem(215);
  border-radius: 8px;
  background-color: var(--primary_color);
  box-shadow: 4px 4px 0 0 rgba(0,0,0,0.1);
  padding: rem(14) 0;
  color: #FFFFFF;
  font-size: rem(18);
  font-weight: 600;
  letter-spacing: 0;
  line-height: rem(20);
  text-align: center;
}
</style>
