import ApiHandler from '@/utils/handlers/ApiHandler'
import ExtraGame from '@/models/ExtraGame'

export const SET_EXTRA_GAMES = 'setExtraGames'
export const SET_CLASS_CONVERSATION_MISSIONS = 'setClassConversationMissions'

export const mutations = {
  setExtraGames(state, data) {
    state.extraGames = data.map(item => ExtraGame.parseDataFromObject(item))
  },
  setClassConversationMissions(state, data) {
    state.classConversationMissions = data
  }
}
