export default class MultipleChoiceQuestionContentAnswer {
  /**
   * @type {number} the id of the answer.
   */
  id

  /**
   * @type {string} the text or image url of the answer.
   */
  answer

  constructor(id, answer) {
    this.id = id
    this.answer = answer
  }

  static parseFromDataObject(data) {
    return new MultipleChoiceQuestionContentAnswer(
      data.id,
      data.answer)
  }
}
