import TextQuestionContent from './TextQuestionContent'
import ImageQuestionContent from './ImageQuestionContent'
import MultipleChoiceQuestionContent from './MultipleChoiceQuestionContent'
import DilemmaQuestionContent from './DilemmaQuestionContent'
import DragAndDropQuestionContent from './DragAndDropQuestionContent'
import VideoQuestionContent from './VideoQuestionContent'
import TrueOrFalseQuestionContent from './TrueOrFalseQuestionContent'
import NetiquetteQuestionContent from './netiquette/NetiquetteQuestionContent'
import PhishingQuestionContent from './phishing/PhishingQuestionContent'
import MazeQuestionContent from './maze/MazeQuestionContent'

export default class QuestionContent {
  static questionContentTypes = {
    1: TextQuestionContent,
    2: ImageQuestionContent,
    3: VideoQuestionContent,
    4: MultipleChoiceQuestionContent,
    5: TrueOrFalseQuestionContent,
    6: DragAndDropQuestionContent,
    7: MultipleChoiceQuestionContent,
    8: MultipleChoiceQuestionContent,
    9: MultipleChoiceQuestionContent,
    10: MultipleChoiceQuestionContent,
    11: MazeQuestionContent,
    12: NetiquetteQuestionContent,
    13: PhishingQuestionContent,
    14: DilemmaQuestionContent,
  }

  static convertToCorrectQuestionContent(data, questionSlideTypeId) {
    const slideType = this.questionContentTypes[questionSlideTypeId]
    return slideType.parseFromDataObject(data)
  }
}
