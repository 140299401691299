<template>
  <Modal>
    <template #content>
      <div class="modal-wrapper">
        <h2 class="title">{{ $t('EXTRA_GAMES.GAME_NOT_AVAILABLE') }}</h2>
      </div>
      <button class="base-button" @click="$emit('onClose')">
        <span>{{ $t('EXTRA_GAMES.I_UNDERSTAND') }}</span>
      </button>
    </template>
  </Modal>
</template>

<script>
import Modal from './Modal'

export default {
  name: 'PlayWithoutBitsModal',
  components: { Modal },
  emits: ['onClose'],
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.details {
  width: rem(500);
  position: relative;
}

.modal-wrapper {
  display: flex;
  flex-direction: column;
  padding: rem(20) rem(50);
  align-items: center;
  color: white;

  .title {
    margin-bottom: rem(20);
  }

  .description {
    margin-bottom: rem(45);
  }
}

.login-input {
  height: 50px;
  width: 100%;
  border-radius: 8px;
  background-color: #FEFEFE;
  box-shadow: 2px 2px 0 0 rgba(8, 13, 43, 0.2);
  padding: 16px;
  outline: none;
  font-family: 'Rubik', sans-serif;
  color: #373B52;
  text-align: center;
  font-size: 16px;
  border: none;
}

.button-wrapper {
  display: flex;
  flex-direction: column;
  gap: rem(20);
  width: rem(400);
}

.start-game {
  background-color: var(--primary_color);
  height: rem(59);
  width: 100%;
  display: flex;
  align-items: center;
  padding-right: rem(6.5);
  color: white;
  margin-top: rem(5);
  margin-bottom: rem(60);

  span {
    text-align: center;
    flex-grow: 1;
  }
}

.subtitle-text {
  opacity: 0.8;
  font-size: rem(15);
}

.error-flash-block {
  height: 44px;
  width: 100%;
  border-radius: 8px;
  background-color: rgba(217, 82, 107, 0.2);
  color: white;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>
