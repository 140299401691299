<template>
  <div class="reward-wrapper">
    <img src="../../../assets/icons/icn_bits.svg" class="bits-icon">
    <div class="reward">
      <p class="title">{{ $t('SHARED.REWARD') }}</p>
      <p class="bits-amount">{{ bitsAmount }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BitsReward',
  props: {
    bitsAmount: {
      type: Number,
      required: true,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.reward-wrapper {
  background: var(--primary_color_shade);
  border-radius: rem(9);
  display: flex;
  align-items: center;
  padding: rem(6) rem(8);
  gap: rem(9);

  .bits-icon {
    width: rem(34);
    height: rem(34);
  }

  .reward {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-right: rem(8);
    .title {
      font-size: rem(12);
      line-height: rem(13);
    }
    .bits-amount {
      font-size: rem(18);
      line-height: rem(20);
    }
  }
}
</style>
