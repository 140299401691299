<template>
  <div class="vote-wrapper">
    <div class="vote-input-container">
      <InlineSvg :src="require('@/assets/icons/btn_minus.svg')" class="vote-button" @click="decrease"/>
      <!-- <img class="vote-button" style="transform: rotateZ(90deg)" > -->
      <number-input ref="numberInput"
                    :max-value="maxInput"
                    :min-value="minInput"
                    class="vote-number-input"
                    @updateNumberValue="emitUpdateValueEvent"/>
      <InlineSvg :src="require('@/assets/icons/btn_plus.svg')" class="vote-button" @click="increase"/>
    </div>
    <div class="vote-title">{{ answer.answer }}</div>
  </div>
</template>

<script>
import NumberInput from './NumberInput'
import InlineSvg from 'vue-inline-svg'

export default {
  name: 'Vote',
  components: {
    NumberInput,
    InlineSvg
  },
  props: {
    answer: {
      type: Object,
      required: true
    },
    currentValue: {
      type: Number,
      required: true
    }
  },
  emits: ['updateVoteValue'],
  data: () => ({
    minInput: 0,
    maxInput: 999,
  }),
  mounted() {
    if (this.currentValue) {
      this.$refs.numberInput.updateValue(this.currentValue)
    }
  },
  methods: {
    /**
     * Emits event to 'Voting' component, to update button
     * @param value of NumberInput
     */
    emitUpdateValueEvent(value) {
      this.$emit('updateVoteValue', { id: this.answer.id, value: value })
    },
    /**
     * Increases NumberInput's value by 1
     */
    increase() {
      this.$refs.numberInput.increaseValue()
    },
    /**
     * Decreases NumberInput's value by 1
     */
    decrease() {
      this.$refs.numberInput.decreaseValue()
    },
    /**
     * Get reference of NumberInput
     * @returns {any} NumberInput component
     */
    getNumberInput() {
      return this.$refs.numberInput
    },
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";

$white-space: .8rem;

.vote-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.vote-input-container {
  margin-bottom: $white-space;
  align-items: center;
  display: flex;
  justify-content: center;

  .vote-button {
    height: rem(40);
    width: rem(40);
    border-radius: 50%;
    border: none;
    display: inline-block;
    transform: rotate(90deg);
    // Hover
    @include hover-active-pointer;
  }

  .vote-number-input {
    height: rem(46);
    width: rem(109);
    margin-left: rem(16);
    margin-right: rem(16);
    border-radius: .8rem;
    border: none;
  }
}

@media (min-width: $screen-sm) {
  .vote-number-input {
    width: 6rem;
    margin: 0 1rem 0 1rem;
  }
}

.vote-title {
  font-weight: 600;
  font-size: 24px;
  user-select: none;
}
</style>
