<template>
  <div id="main-content" class="main-content scrollable">
    <router-view v-if="dataReady" class="main-content-scroll"/>
    <Loading v-if="!dataReady" @loadingFinished="dataReady = true" />
  </div>
</template>

<script>
// import Register from './views/Register'
import Loading from '@/views/game/Loading'
export default {
  name: 'App',
  components: {
    Loading
    // Register
  },
  // props: {
  // },
  data() {
    return {
      dataReady: false
    }
  },
  // computed: {
  //   env() {
  //     return process.env.NODE_ENV
  //   }
  // },
  // watch: {
  // },
  // created() {
  // },
  // mounted() {
  // },
  // methods: {
  // }
}
</script>

<style lang="scss">
  @import "/assets/css/global.scss";

  .main-content {
    background-color: var(--background_color);
    background-image: var(--background_overlay_image);
    background-size: cover;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  .main-content-scroll {
    height: 100%;
    margin-bottom: rem(120);
  }
</style>
