<template>
  <div class="feedback-display">

    <div v-if="!videoStep" class="feedback-text">
      <div class="feedback-display__container__content__title">
        <h2 class="feedback-display__container__content__title__text">{{ customFeedbackTitle ?? "Feedback" }}</h2>
      </div>
      <div class="feedback-title">
        <p>{{ feedback ?? customFeedbackText }}</p>
      </div>
      <img class="feedback-tail" src="@/assets/images/img_bubble_tail.svg"/>

    </div>
    <vimeo-player v-if="videoUrl && videoStep"
                  :hide-fullscreen-button="false"
                  :options="options"
                  :player-height="playerHeight"
                  :player-width="playerWidth"
                  :video-id="videoUrl"
                  @ended="ended"
                  @fullscreenchange="fullScreen"
                  @loaded="onResize"
                  @pause="pause"
                  @playing="play"
    />
    <button class="continue-button action-button pink-button" @click="continueToNextSlide"> Ok</button>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'FeedbackDisplay',
  props: {
    wasCorrect: {
      type: Boolean,
      default: true,
    },
    onPressNext: {
      type: Function,
      required: true,
    },
    customFeedbackText: {
      type: String,
      required: false,
      default: null,
    },
    customFeedbackVideo: {
      type: String,
      required: false,
      default: null,
    },
    customFeedbackTitle: {
      type: String,
      required: false,
      default: null,
    },
  },
  data: () => ({
    options: {
      muted: false,
      autoplay: true,
    },
    videoStep: false,
    // note defaults 21:9
    playerWidth: (1680 * 0.7),
    playerHeight: (720 * 0.7),
    hidden: true,
    loginData: null,
  }),
  computed: {
    ...mapGetters({
      feedback: 'getSlideFeedback',
      correctVideo: 'getCorrectFeedbackVideo',
      incorrectVideo: 'getIncorrectFeedbackVideo',
      historyLocation: 'getHistoryLocation',
    }),
    videoUrl() {
      return this.customFeedbackVideo ?? (this.wasCorrect ? this.correctVideo : this.incorrectVideo)?.getVideoUrl()
    },
  },
  mounted() {
    this.loginData = JSON.parse(localStorage.getItem('schoolClass'))
    if (this.videoUrl) {
      this.setHidden()
    }
    if (!this.feedback && !this.customFeedbackText) {
      this.videoStep = true
    }
    window.addEventListener('click', this.onClickListener)

    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })

    // wait 500ms then check
    setTimeout(() => {
      console.log('feedback mounted, checking if we should skip', this.feedback)
      const shouldSkip = this.historyLocation && this.historyLocation.isPostAnswer && !this.feedback && !this.correctVideo.videoUrl && !this.incorrectVideo.videoUrl && !this.customFeedbackText && !this.customFeedbackVideo
      if (shouldSkip) {
        console.log('skipping feedback')
        this.$store.dispatch('skipFeedback')
      }
    }, 10)
  },
  beforeUnmount() {
    this.setShown()
    window.removeEventListener('resize', this.onResize)
    window.removeEventListener('click', this.onClickListener)
  },
  methods: {
    onClickListener(click) {
      // console.log('click Video listener', click)
      this.setShown()
    },
    onResize() {
      // console.log('hitting on resize?')
      // const videoPlayerWrapper = document.getElementById('video-player-wrapper')

      const maxWidth = Math.min(window.innerWidth * 0.8, window.innerHeight * 0.9)
      // console.log('window.innerHeight', window.innerHeight)
      // console.log('window.innerWidth', window.innerWidth)
      this.playerHeight = maxWidth * 0.5
      this.playerWidth = maxWidth
    },
    fullScreen(data) {
      // console.log('full screen', data)
      if (!data.fullScreen) {
        this.hidden = false
      }
    },
    play(data) {
      this.setHidden()
      // console.log('play', data)
    },
    pause(data) {
      this.setShown()
      // console.log('pause', data)
    },
    ended(data) {
      this.setShown()
      // console.log('end', data)
    },
    // toggle(data) {
    // },
    setHidden() {
      const headerWrapperElem = document.getElementById('header-wrapper')
      headerWrapperElem.style.opacity = '0'
      this.hidden = true
    },
    setShown() {
      const headerWrapperElem = document.getElementById('header-wrapper')
      headerWrapperElem.style.opacity = '1'
      this.hidden = false
    },
    continueToNextSlide() {
      if (!this.videoStep && this.videoUrl) {
        this.videoStep = true
        return
      }
      if (this.historyLocation) {
        this.$store.dispatch('goForwardInHistory')
        return
      }
      this.onPressNext()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";
@import "~@/assets/css/global.scss";

.continue-button {
  position: absolute;
  bottom: rem(30);
  right: rem(30);
  width: rem(250);
  justify-content: center;
  margin-top: 2rem;
}

.feedback-display {
  justify-self: start;
  margin-bottom: rem(100);
  display: flex;
  flex-direction: column;
  gap: rem(25);
  max-width: rem(900);

  .feedback-title {
    max-width: rem(600);
  }

  .feedback-text {
    padding: rem(35);
    border-radius: rem(10);
    gap: rem(25);
    justify-self: start;
    margin-bottom: rem(100);
    display: flex;
    background-color: var(--back_color);
    flex-direction: column;
    position: relative;
    min-width: rem(600);
    min-height: rem(200);

    .feedback-tail {
      position: absolute;
      bottom: -44px;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%)
    }
  }

}

</style>
