export default class PhishingQuestionContentSubSlide {
  /**
   * @type {String} the email or phone number of the sender.
   */
  emailorPhoneNumber;

  /**
   * @type {String} the feedback to be shown once an answer is given
   */
  feedback;

  /**
   * @type {number} the id of the sub slide.
   */
  id;

  /**
   * @type {String} the message of the email.
   */
  message;

  /**
   * @type {String} the sender of the email slide.
   */
  sender;

  /**
   * @type {String} the topic/subject line of the email.
   */
  topic;

  constructor(emailorPhoneNumber, feedback, id, message, sender, topic) {
    this.emailorPhoneNumber = emailorPhoneNumber
    this.feedback = feedback
    this.id = id
    this.message = message
    this.sender = sender
    this.topic = topic
  }

  static parseFromDataObject(data) {
    return new PhishingQuestionContentSubSlide(
      data.emailorPhoneNumber,
      data.feedback,
      data.id,
      data.message,
      data.sender,
      data.topic)
  }
}
