<template>
  <Modal :is-shown="joker">
    <template #content>
      <div class="joker-wrapper">
        <img
          :src="joker.jokerTypeIcon"
          class="joker-icon"
          :alt="joker.jokerTypeName + ' joker icoon'"
          :title="joker.jokerTypeName + ': ' + joker.jokerTypeDescription"
        />
        <div class="joker-title-section">
          <h2 class="joker-title">{{ joker.jokerTypeName }}</h2>
          <p class="joker-description joker-description-italic">{{ joker.jokerTypeDescription }}</p>
        </div>

        <p class="joker-description">{{ $t('JOKERS.CONFIRM') }}</p>
        <p class="joker-description">{{ $t('JOKERS.JOKER_USAGE_WARNING') }}</p>
        <div class="button-options">
          <BasicButton :button-text="$t('JOKERS.CANCEL')" :button-class="'white-button'" @click="$emit('choice',false)"/>
          <BasicButton :button-text="$t('JOKERS.ACTIVATE')" @click="$emit('choice',true)"/>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from './Modal'
import BasicButton from '@/components/elements/basic/BasicButton.vue'
export default {
  name: 'JokerActivateModal',
  components: {
    Modal,
    BasicButton
  },
  props: {
    joker: {
      type: Object,
      default: null,
    }
  },
  emits: ['choice'],
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";
@import "~@/assets/css/global.scss";
@import "~@/assets/css/mission.scss";

.joker-wrapper {
  border-radius: rem(20);
  .joker-icon {
    margin-bottom: 32px;
  }

  .joker-title-section {
    .joker-title {
      color: #FFFFFF;
      font-size: 28px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 36px;
      text-align: center;
    }
  }

  .joker-description {
    margin-bottom: rem(40);
    color: #FFFFFF;
    font-size: 24px;
    max-width: rem(800);
    letter-spacing: 0;
    line-height: 38px;
    text-align: center;
    white-space: pre-line;

    &.joker-description-italic {
      font-style: italic;
    }
  }

  .button-options {
    display: flex;
    justify-content: space-evenly;
  }
}
</style>
