<template>
  <transition name="fade">
    <div v-if="isShown" :style="cssVars" class="modal-bg">
      <div :class="customClass">
        <slot name="content"/>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    isShown: {
      type: Boolean,
      default: true,
    },
    customClass: {
      type: String,
      default: 'modal-wrapper',
    }
  },
  computed: {
    cssVars() {
      return {
        '--modal-opacity': this.isShown ? 1 : 0
      }
    }
  },
  watch: {
    isShown(newVal, oldVal) {
      const mainScrollBody = document.getElementById('main-content')
      if (mainScrollBody) {
        mainScrollBody.style.overflow = newVal ? 'hidden' : 'auto'
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.modal-bg {
  @include position(fixed, $top: 0, $right: 0, $bottom: 0, $left: 0);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(3px);
  background-color: rgba(0,0,0,.30);
  //overflow-y: auto;
}

.modal-wrapper {
  background-image: linear-gradient(#4D346F, #3C3A6F);
  text-align: center;
  padding: rem(45);
  border-radius: rem(15);
  border-style: solid;
  border-color: #53417D;
  border-width: 2px;
  position: relative;
  max-width: 45rem;
}

.bonus-modal-wrapper{
  width: rem(550);
  height: rem(630);
  background-color: #4D4C89;
  border-radius: rem(20);
  padding: rem(90) rem(75) 0;
  color: #FFFFFF;
  position: relative;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

// Pure mobile
@media screen and (max-width: $screen-sm) {
  .modal-wrapper {
    max-width: 95vw;
  }
}
</style>
