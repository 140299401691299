export default class SessionHistorySlide {
  /**
   * The index of the slide in the mission
   * @type {number}
   */
  slideIndex;

  /**
   * The id of the slide
   * @type {number}
   */
  slideId;

  /**
   * The correct answer of the slide
   * @type {number|number[]|null}
   */
  correctAnswer;

  /**
   * The answer given by the user
   * @type {number[]|number|null}
   */
  answerGiven;

  /**
   * The bits earned by the user
   * @type {number|null}
   */
  bitsEarned;

  /**
   * @type {SessionHistorySlide[]}
   */
  subSlideAnswers;

  constructor(slideIndex, slideId, correctAnswer, answerGiven, bitsEarned, subSlideAnswers) {
    this.slideIndex = slideIndex
    this.slideId = slideId
    this.correctAnswer = correctAnswer
    this.answerGiven = answerGiven
    this.bitsEarned = bitsEarned
    this.subSlideAnswers = subSlideAnswers
  }

  /**
   *
   * @param {MissionSlide} missionSlide
   * @return {boolean}
   */
  get hasFeedback() {
    return true//this.correctAnswer !== null && this.answerGiven !== null && missionSlide.feedback !== null
  }

  static parseFromDataObject(data) {
    return new SessionHistorySlide(
      data.slideIndex ?? data.subSlideIndex,
      data.slideId,
      data.correctAnswer,
      data.answerGiven,
      data.bitsEarned,
      data.subSlideAnswers?.map(subSlideAnswer => SessionHistorySlide.parseFromDataObject(subSlideAnswer)) ?? null
    )
  }
}
