/**
 * This includes the result of a submitted answer.
 *
 * example:
 * {
 *     "clientAnswer": 0,
 *     "correctAnswer": 1,
 *     "isAnswerCorrect": false,
 *     "isPartiallyCorrect": false,
 *     "awardedBits": 0,
 *     "totalBitsThatCouldHaveBeenAwarded": 150,
 *     "feedback": "Dat iets onaardig overkomt op WhatsApp kan gebeuren. Vaak is het een misverstand. Word niet meteen boos of verdrietig en vraag je vriend(in) IRL (in real life) wat hij\/zij daarmee bedoelde. Wie van jullie herkent dit en heeft een voorbeeld van een misverstand via WhatsApp?",
 *     "isPartOfSession": true
 * }
 */
export default class SubmissionResult {
  /**
   * The answer the client gave.
   * @type {number|number[]|null}
   */
  clientAnswer;

  /**
   * The correct answer.
   * @type {number|number[]|null}
   */
  correctAnswer;

  /**
   * Whether the answer is correct.
   * @type {boolean|null}
   */
  isAnswerCorrect;

  /**
   * Whether the answer is partially correct.
   * @type {boolean|null}
   */
  isPartiallyCorrect;

  /**
   * The number of bits awarded.
   * @type {number}
   */
  awardedBits;

  /**
   * The total number of bits that could have been awarded.
   * @type {number|null}
   */
  totalBitsThatCouldHaveBeenAwarded;

  /**
   * The feedback.
   * @type {string|null}
   */
  feedback;

  /**
   * Whether this submission is part of a session.
   * @type {boolean}
   */
  isPartOfSession;

  /**
   * The id of the slide this submission was made on.
   * @type {number|null}
   */
  slideId;

  currentStreak;

  constructor(clientAnswer, correctAnswer, isAnswerCorrect, isPartiallyCorrect, awardedBits, totalBitsThatCouldHaveBeenAwarded, feedback, isPartOfSession, currentStreak) {
    this.clientAnswer = clientAnswer
    this.correctAnswer = correctAnswer
    this.isAnswerCorrect = isAnswerCorrect
    this.isPartiallyCorrect = isPartiallyCorrect
    this.awardedBits = awardedBits
    this.totalBitsThatCouldHaveBeenAwarded = totalBitsThatCouldHaveBeenAwarded
    this.feedback = feedback
    this.isPartOfSession = isPartOfSession
    this.currentStreak = currentStreak
  }

  static parseFromDataObject(data) {
    return new SubmissionResult(
      data.clientAnswer,
      data.correctAnswer,
      data.isAnswerCorrect,
      data.isPartiallyCorrect,
      data.awardedBits,
      data.totalBitsThatCouldHaveBeenAwarded,
      data.feedback,
      data.isPartOfSession,
      data.currentStreak,
    )
  }

  setSlideId(slideId) {
    this.slideId = slideId
  }
}
