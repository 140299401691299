<template>
  <div v-if="dataReady" :class="{'hidden-while-loading' : showLoadingScreen}" class="content-wrapper mission-wrapper">

    <TipModal :is-shown="!!tipText" :tip-text="tipText" @dismiss="dismissTip"/>
    <BonusModal :bonus="currentBonus" :is-shown="!!currentBonus" @dismiss="dismissBonus"/>

    <Text
      v-if="missionSlideTypeId === 1"
      :slide-content="slideContent"
      @nextSlide="nextSlide"
    >
    </Text>

    <Image
      v-else-if="missionSlideTypeId === 2"
      :slide-content="slideContent"
      @nextSlide="nextSlide"
    >
    </Image>

    <Video
      v-else-if="missionSlideTypeId === 3"
      :slide-content="slideContent"
      @nextSlide="nextSlide"
    >
    </Video>

    <VotingScreen
      v-else-if="missionSlideTypeId === 4"
      :slide-content="slideContent"
      @nextSlide="nextSlide"
    >
    </VotingScreen>

    <TrueOrFalse
      v-else-if="missionSlideTypeId === 5"
      :slide-content="slideContent"
      @nextSlide="nextSlide"
    >
    </TrueOrFalse>

    <DragAndDrop
      v-else-if="missionSlideTypeId === 6"
      :slide-content="slideContent"
      @nextSlide="nextSlide"
    >
    </DragAndDrop>

    <SingleChoice
      v-else-if="missionSlideTypeId === 7"
      :slide-content="slideContent"
      @nextSlide="nextSlide"
    >
    </SingleChoice>

    <MultipleChoice
      v-else-if="missionSlideTypeId === 8"
      :slide-content="slideContent"
      @nextSlide="nextSlide"
    >
    </MultipleChoice>

    <SingleImageSelect
      v-else-if="missionSlideTypeId === 9"
      :slide-content="slideContent"
      @nextSlide="nextSlide"
    >
    </SingleImageSelect>

    <MultipleImageSelect
      v-else-if="missionSlideTypeId === 10"
      :slide-content="slideContent"
      @nextSlide="nextSlide"
    >
    </MultipleImageSelect>

    <Maze
      v-else-if="missionSlideTypeId === 11"
      :slide-content="slideContent"
      @nextSlide="nextSlide"
    >
    </Maze>

    <Netiquette
      v-else-if="missionSlideTypeId === 12"
      :slide-content="slideContent"
      @nextSlide="nextSlide"
    >
    </Netiquette>

    <JudgePhishing
      v-else-if="missionSlideTypeId === 13"
      :slide-content="slideContent"
      @nextSlide="nextSlide"
    >
    </JudgePhishing>
    <Dilemma
      v-else-if="missionSlideTypeId === 14"
      :slide-content="slideContent"
      @nextSlide="nextSlide">
    </Dilemma>

  </div>

</template>

<script>

// const TASK_TYPES: Object.freeze{
//     1: Text,
//     2: Image,
//     3: Video,
//     4: VotingScreen,
//     5: TrueOrFalse,
//     6: DragAndDrop,
//     7: SingleChoice,
//     8: MultipleChoice,
//     9: SingleImageSelect,
//     10: MultipleImageSelect,
//     11: Maze,
//     12: Netiquette,
//     13: JudgePhishing,
// }

import { mapGetters } from 'vuex'
import Text from '../../components/tasks/static/Text'
import Image from '@/components/tasks/static/Image'
import Video from '../../components/tasks/static/Video'
import VotingScreen from '@/components/tasks/voting/VotingScreen'
import DragAndDrop from '@/components/tasks/DragAndDrop'
import SingleChoice from '@/components/tasks/SingleChoice'
import MultipleChoice from '@/components/tasks/MultipleChoice'
import SingleImageSelect from '@/components/tasks/SingleImageSelect'
import MultipleImageSelect from '@/components/tasks/MultipleImageSelect'
import Maze from '@/components/tasks/Maze/Maze'
import Netiquette from '@/components/tasks/netiquette/Netiquette'
import JudgePhishing from '@/components/tasks/phishing/JudgePhishing'
import TrueOrFalse from '../../components/tasks/TrueOrFalse'
import Dilemma from '@/components/tasks/dilemma/Dilemma.vue'
import TipModal from '@/components/partials/modal/TipModal.vue'
import BonusModal from '@/components/partials/modal/BonusModal'
import { CLEAR_JOKER_RESULT, RESET_SLIDE_BONUSES, SET_BONUS_EARNED } from '@/store/mutations'

export default {
  name: 'GameSlide',
  components: {
    JudgePhishing,
    Netiquette,
    Maze,
    MultipleImageSelect,
    SingleImageSelect,
    MultipleChoice,
    SingleChoice,
    DragAndDrop,
    BonusModal,
    Text,
    Image,
    Video,
    VotingScreen,
    TrueOrFalse,
    Dilemma,
    TipModal
  },
  props: {
    slideContent: {
      type: Object,
      required: true
    },
    dataReady: {
      type: Boolean,
      required: true
    },
    missionSlideTypeId: {
      type: Number,
      required: true
    },
    showBits: {
      type: Boolean,
      default: true
    }
  },
  emits: ['slideComplete'],
  data: () => ({
    newBonus: false,
    shouldGoForward: false, //used to check if the bonuses popup should go to the next slide
  }),
  computed: {
    ...mapGetters({
      currentSlide: 'getCurrentSlide',
      bits: 'getBits',
      jokerResult: 'getJokerResult',
      bonus: 'getBonusEarned',
      historyLocation: 'getHistoryLocation',
      showLoadingScreen: 'getShowLoadingScreen',
    }),
    orderedBonuses() {
      return this.bonus ? [...this.bonus].sort((x, y) => x.bits - y.bits) : null
    },
    currentBonus() {
      return this.orderedBonuses && this.orderedBonuses.length > 0 && this.orderedBonuses[0].bits !== 0 ? this.orderedBonuses[0] : null
    },
    tipText() {
      return this.jokerResult ? this.jokerResult.tip : null
    },
  },
  watch: {
    bonus: {
      handler(curr, prev) {
        if (!curr) {
          return
        }
        // console.log('curr', curr)
        const nonZeroBonuses = curr.filter(x => x.bits !== 0)
        if (nonZeroBonuses.length < curr.length) {
          this.$store.commit(SET_BONUS_EARNED, nonZeroBonuses)
        }
      },
      deep: true,
    },
    historyLocation: {
      handler(curr, prev) {
        console.log('history location changed', curr)
        this.$store.dispatch('getSlideContentFromCurrentHistoryPosition')
      },
      deep: true,
    },
  },
  // mounted() {
  //   console.log('this', this)
  // },
  methods: {
    nextSlide(param) {
      this.shouldGoForward = true
      this.attemptNextSlide(param)
    },
    attemptNextSlide(param) { //used so that sublides don't jump forward when dismissing bonuses.
      if (!this.shouldGoForward || this.currentBonus) {
        return
      }
      //print('we are going forward')
      this.shouldGoForward = false
      this.$store.commit(RESET_SLIDE_BONUSES)
      this.$store.dispatch('showLoading')
      this.$emit('slideComplete', param)
    },
    dismissTip() {
      this.$store.commit(CLEAR_JOKER_RESULT)
    },
    dismissBonus() {
      const newBonus = this.orderedBonuses.slice(1)
      this.$store.commit(SET_BONUS_EARNED, newBonus)
      this.attemptNextSlide()
    }
  }
}
</script>

<style lang="scss" scoped>

.hidden-while-loading {
  display: none;
}
</style>
