import NetiquetteType from './NetiquetteType'
import NetiquetteMessage from './NetiquetteMessage'

export default class NetiquetteQuestionContentSubSlide {
  /**
   * @type {number} the id of the sub slide.
   */
  id;

  /**
   * @type {String} the feedback to be shown once an answer is given
   */
  feedback;

  /**
   * @type {number} the id of the netiquette type.
   */
  netiquetteTypeId;

  /**
   * @type {NetiquetteType} the netiquette type.
   */
  netiquetteType;

  /**
   * @type {String[]|()}
   */
  messages;

  message;

  mediaFileId;

  mediaFileUrl;

  constructor(id, feedback, netiquetteTypeId, netiquetteType, messages, message, mediaFileId, mediaFileUrl) {
    this.id = id
    this.feedback = feedback
    this.netiquetteTypeId = netiquetteTypeId
    this.netiquetteType = netiquetteType
    this.messages = messages
    this.message = message
    this.mediaFileId = mediaFileId
    this.mediaFileUrl = mediaFileUrl
  }

  static parseFromDataObject(data) {
    return new NetiquetteQuestionContentSubSlide(
      data.id,
      data.feedback,
      data.netiquetteTypeId,
      NetiquetteType.parseFromDataObject(data.netiquetteType),
      data.messages?.map(message => NetiquetteMessage.parseFromDataObject(message)) ?? null,
      data.message,
      data.mediaFileId,
      data.mediaFileUrl)
  }
}
