import { SlideVideo } from '../slide/SlideVideo'

export default class DilemmaQuestionContentAnswer {
  /**
   * @type {number}
   */
  id;

  /**
   * @type {string}
   */
  text

  /**
   * @type {boolean}
   */
  hasAnswerPicture

  /**
   * @type {string}
   */
  answerPictureUrl

  /**
   * @type {SlideVideo}
   */
  resultVideoId

  constructor(id, text, hasAnswerPicture, answerPictureUrl, resultVideoId) {
    this.id = id
    this.text = text
    this.hasAnswerPicture = hasAnswerPicture
    this.answerPictureUrl = answerPictureUrl
    this.resultVideoId = resultVideoId
  }

  static parseFromDataObject(data) {
    return new DilemmaQuestionContentAnswer(
      data.id,
      data.text,
      data.hasAnswerPicture,
      data.answerPictureUrl,
      SlideVideo.parseFromDataObject(data.resultVideoId))
  }
}
