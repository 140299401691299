<template>
  <div class="scroll-wrapper">

    <div v-if="shouldBeShowingFeedback" class="task-wrapper">
      <FeedbackDisplay
        :custom-feedback-text="feedbackText"
        :custom-feedback-title="$t('FEEDBACK.GOOD_TO_KNOW')"
        :on-press-next="onCloseFeedback"/>
    </div>
    <div v-else class="task-wrapper">
      <slide-header :description="slideContent.description" :title="slideContent.title"/>

      <div class="voting-statement">
        <div class="voting-statement-text">{{ slideContent.questionContent.statement }}</div>
      </div>

      <div :style="historyLocation !== null ? 'pointer-events: none' : ''" class="voting-vote-wrapper">
        <div v-for="vote in slideContent.questionContent.answers" :key="vote.id">
          <Vote :answer="vote" :current-value="votes ? (votes[vote.id] ?? 0) : 0" @updateVoteValue="onValueUpdated"/>
        </div>
      </div>
    </div>

  </div>
  <game-footer v-if="!shouldBeShowingFeedback"
               :continue-button-click="onClickContinue"
               :continue-button-disabled="!(hasVoted)"
               :continue-button-text="$t('PAUSE.CONTINUE')"/>
</template>

<script>
import Vote from './Vote'
import SlideHeader from '@/components/elements/SlideHeader'
import { POST_SLIDE_ANSWER } from '@/store/actions'
import { mapGetters } from 'vuex'
import FeedbackDisplay from '../FeedbackDisplay'

export default {
  name: 'VotingScreen',
  components: {
    FeedbackDisplay,
    Vote,
    SlideHeader
  },
  props: {
    slideContent: {
      type: Object,
      required: true
    },
  },
  emits: ['nextSlide'],
  data: () => ({
    statement: 'Als kind heb je meer aan techniek - en programmeerles, dan aan taalles.',
    doneVoting: false,
    hasVoted: false,
    votes: {},
    feedbackTextOptions: {
      1: 'Een groot deel is het eens met deze stelling. \u{2705}',
      2: 'Een groot deel is het oneens met deze stelling. \u{1F44E}',
      3: 'Over deze stelling is jullie mening neutraler. \u{2696}',
      4: 'Deze stelling roept veel verschillende meningen op. \u{1F937}'
    }
  }),
  computed: {
    ...mapGetters({
      givenAnswer: 'getClientAnswer',
      historyLocation: 'getHistoryLocation'
    }),
    shouldBeShowingFeedback() {
      return this.historyLocation?.isPostAnswer || this.doneVoting
    },
    feedbackText() {
      const majority = Object.values(this.votes).reduce((a, b) => a + b) / 2
      //find any above majority
      const found = Object.entries(this.votes).find(([key, value]) => value > majority)
      if (found) {
        return this.feedbackTextOptions[found[0]]
      }
      return this.feedbackTextOptions[4]
    }
  },
  watch: {
    givenAnswer: {
      immediate: true,
      handler(curr, prev) {
        if (curr != null) {
          this.votes = curr
        }
      }
    }
  },
  methods: {
    /**
     * Will be called when Vote component's value is changed. Responsible for enabling / disabling 'continue' button.
     */
    onValueUpdated(data) {
      this.votes[data.id] = data.value
      let voted = false
      Object.keys(this.votes).forEach(key => {
        if (this.votes[key] > 0) {
          voted = true
        }
      })
      this.hasVoted = voted
    },
    /**
     * Called when feedback-modal's button is pressed. Will bring user to next screen.
     */
    onClickNotificationButton() {
      const formData = new FormData()
      Object.entries(this.votes).forEach((voteItem) => {
        formData.append(`answer[${voteItem[0]}]`, voteItem[1])
      })
      this.$store.dispatch(POST_SLIDE_ANSWER, formData).then(response => {
        this.$emit('nextSlide')
      })
    },
    /**
     * Responsible for showing feedback modal, called when 'continue' button is pressed.
     */
    onClickContinue() {
      this.doneVoting = true
    },
    onCloseFeedback() {
      if (this.historyLocation) {
        this.$store.dispatch('goForwardInHistory')
      } else {
        this.onClickNotificationButton()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/global.scss";
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";
@import "~@/assets/css/mission.scss";

$space-between-votes: 5%;

.task-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.vote-slide-title {
  margin-bottom: rem(8);
}

.voting-slide-explanation {
  margin-bottom: 2.5rem;
  font-size: rem(18);
  line-height: 2.5rem;
}

.voting-statement {
  align-items: center;
  background-color: var(--container_background_color);
  border-radius: 10px;
  width: 70%;
  padding: rem(25);
  margin-top: rem(46);

  .voting-statement-text {
    color: #070C3E;
    text-align: center;
    font-size: rem(24);
    font-weight: 600;
    line-height: rem(26);
  }
}

.voting-vote-wrapper {
  display: flex;
  @media screen and (min-width: $screen-lg-minus) {
    flex-direction: row;
    justify-content: center;
    margin-top: rem(140);
    gap: rem(60);
  }

  @media screen and (min-width: $screen-xl) {
    margin-top: rem(50);
  }

  @media screen and (max-width: $screen-lg-minus) {
    margin-top: rem(70);
    flex-direction: column;
    gap: rem(30);
  }
}
</style>
