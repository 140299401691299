import Axios from 'axios'
import LocalDataHandler from './LocalDataHandler'

class ApiConnectionHandler {
  constructor() {
    this.api = Axios.create({
      baseURL: `${process.env.VUE_APP_ROOT_API}`,
      // Include cookies for API authentication
      withCredentials: true,
      xsrfHeaderName: 'X-Csrf-Token',
    })

    // this.setInterceptors()
  }

  // setInterceptors() {
  //   const self = this
  //
  // this.api.interceptors.request.use(
  //   async function(config) {
  //     // if (LocalDataHandler.isAuthenticated()) {
  //     // config.headers = self.getAuthHeader()
  //     // }
  //     return config
  //   },
  //   function(error) {
  //     return Promise.reject(error)
  //   }
  // )
  // }

  // getAuthHeader() {
  //   const accessToken = LocalDataHandler.getAccessToken()
  //   // Check if access token is present
  //   if (!accessToken) return null
  //   return {
  //     Authorization: `Bearer ${accessToken}`
  //   }
  // }

  static getInstance() {
    if (!this.instance) {
      this.instance = new ApiConnectionHandler()
    }
    return this.instance
  }
}

const apiHandler = ApiConnectionHandler.getInstance()

export default apiHandler
