<template>
  <div class="video-player-wrapper">
    <div class="video-player-container">
      <div ref="elementRef" class="vimeo-player"/>

      <div v-if="showReplayOverlay" class="replay-container">
        <button class="white-button" type="button" @click="replayVideo">{{ $t('VIDEO.REPLAY') }}</button>
        <p class="overlay-text">{{ $t('VIDEO.NO_BITS') }}</p>
      </div>
    </div>
    <div v-if="!hideFullscreenButton" class="pink-button fullscreen-button" @click="setFullscreen">
      {{ $t('VIDEO.FULLSCREEN') }}
    </div>
  </div>
</template>
<script>
import { onBeforeUnmount, onMounted, ref, toRef, toRefs, unref, watch } from 'vue'
import Player from '@vimeo/player'
import AccessibilityTypeId from '@/models/AccessibilityTypeId'

function emitVueEvent({ player, event, emit }) {
  player.on(event, data => emit(event, data, player))
}

const eventsToEmit = [
  'play',
  'playing',
  'pause',
  'ended',
  'timeupdate',
  'progress',
  'seeking',
  'seeked',
  'texttrackchange',
  'chapterchange',
  'cuechange',
  'cuepoint',
  'volumechange',
  'playbackratechange',
  'bufferstart',
  'bufferend',
  'error',
  'loaded',
  'durationchange',
  'fullscreenchange',
  'qualitychange',
  'camerachange',
  'resize'
]
export default {
  name: 'VimeoPlayer',
  props: {
    playerHeight: {
      type: Number,
      default: 480,
    },
    playerWidth: {
      type: Number,
      default: 720,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    videoId: {
      type: String,
      default: '',
    },
    videoUrl: {
      type: String,
      default: '',
    },
    loop: {
      type: Boolean,
      default: false
    },
    autoplay: {
      type: Boolean,
      default: false
    },
    controls: {
      type: Boolean,
      default: true
    },
    fullScreen: {
      type: Boolean,
      default: false,
    },
    eventsToEmit: {
      type: Array,
      default: () => eventsToEmit,
    },
    hideFullscreenButton: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['ready', 'error'].concat(eventsToEmit),
  setup(props, { emit }) {
    let player
    let iframe
    const elementRef = ref(null)
    const showReplayOverlay = ref(false)
    const { videoId, videoUrl } = toRefs(props)
    if (!props.videoId && !props.videoUrl) {
      console.warn('[VueVimeoPlayer: You mist provide at least a videoId or a videoUrl prop]')
    }
    const mergeOptions = ({ id, url }) => {
      const opts = {
        width: props.playerWidth,
        height: props.playerHeight,
        loop: props.loop,
        autoplay: props.autoplay,
        controls: props.controls
      }
      if (unref(url)) {
        opts.url = unref(url)
      }
      if (unref(id)) {
        opts.id = unref(id)
      }
      return Object.assign(opts, props.options)
    }
    const play = () => player.play()
    const pause = () => player.pause()
    const mute = () => player.setVolume(0)
    const unmute = (volume = 0.5) => player.setVolume(volume)
    const setEvents = () => {
      player.ready().then(() => {
        iframe = document.querySelector('iframe')
        emit('ready', player)
      }).catch((error) => {
        emit('error', error, player)
      })
      props.eventsToEmit.forEach(event => emitVueEvent({ player, event, emit }))

      player.on('ended', () => {
        showReplayOverlay.value = true
      })
    }
    onMounted(() => {
      player = new Player(elementRef.value, mergeOptions({ id: props.videoId, url: props.videoUrl }))

      const localStorageLoggedInData = JSON.parse(localStorage.getItem('schoolClass'))

      // console.log("local storage", localStorageLoggedInData?.schoolClass);

      if (localStorageLoggedInData?.schoolClass.accessibilityTypeId === AccessibilityTypeId.USING_SUBTITLES) {
        enableCaptions()
      }
      setEvents()
    })

    function replayVideo() {
      player.play().then(response => {
        showReplayOverlay.value = false
      }).catch(error => {
        console.log('error', error)
      })
    }

    function setFullscreen() {
      player.requestFullscreen().then(response => {
        // console.log('setFullscreen res', response)
      }).catch(error => {
        console.log('error', error)
      })
    }

    function enableCaptions() {
      const browserLang = navigator.language
      const browserLangIso = new Intl.Locale(browserLang).language
      const availableCaptions = player.getTextTracks()
      if (!availableCaptions?.length) {
        return
      }

      const browserLangCaption = availableCaptions.find(caption => caption.language === browserLangIso)
      if (browserLangCaption) {
        player.enableTextTrack(browserLangIso, 'captions')
      } else {
        player.enableTextTrack(availableCaptions[0].language, 'captions')
      }
    }

    onBeforeUnmount(() => player.unload())
    watch(videoId, (id) => player.loadVideo(mergeOptions({ id })))
    watch(videoUrl, (url) => player.loadVideo(mergeOptions({ url })))
    watch(toRef(props, 'controls'), () => player.loadVideo(mergeOptions({ url: videoUrl, id: videoId })))
    watch(() => props.playerWidth, () => {
      if (iframe) {
        iframe.width = props.playerWidth
      }
    })
    watch(() => props.playerHeight, () => {
      if (iframe) {
        iframe.height = props.playerHeight
      }
    })

    const update = (id) => player.loadVideo(mergeOptions({ id }))
    return {
      update, play, pause, mute, unmute, elementRef, showReplayOverlay, replayVideo, setFullscreen
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";
@import "~@/assets/css/mission.scss";

.vimeo-player {

}

.background-cover {

}

.video-player-container {
  position: relative;
}

.replay-container {
  @include position(absolute, $top: 0, $right: 0, $bottom: 0, $left: 0);
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  background-color: rgba(122, 122, 122, 0.25);
  border-radius: rem(20);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fullscreen-button {
  margin-top: 8px;
  margin-bottom: 12px;
}

.video-player-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  width: 100%;
}
</style>
