<template>
  <div class="chat-wrapper">
    <div
      v-for="(message, index) in data"
      :key="'chat-message-' + index"
      class="message"
    >
      <div :class="message.isMe ? 'message-left' : 'message-right'">
        {{ message.message }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExamineChat',
  props: {
    data: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";

.chat-wrapper {
  display: flex;
  flex-direction: column;
  padding: rem(20) rem(26);
  border-radius: rem(10);
  height: 100%;
  overflow: auto;
  background-image: url("~@/assets/images/img_chat_bg.png");
  background-size: cover;

  &::-webkit-scrollbar {
    width: rem(10);
  }

  &::-webkit-scrollbar-track {
    background-color: #b6b9c7;
  }

  &::-webkit-scrollbar-thumb {
    color: var(--text_color);
    @include border-radius(rem(4));
  }
}

.message {
  margin-bottom: rem(18);
  .message-left, .message-right {
    text-align: right;
    max-width: 70%;
    min-width: rem(100);
    padding: rem(12) rem(18);
    border-radius: rem(10);
    font-size: rem(18);
    line-height: rem(31);
  }

  .message-left {
    text-align: left;
    float: left;
    background-color: #C1D2DF;
    position: relative;

    &:before {
      @include position(absolute, $bottom:rem(0) ,$left: rem(-8));
      content:"";
      width: 0;
      height: 0;
      border-top: rem(8) solid
      transparent;
      border-right: rem(16) solid
      #C1D2DF;
      border-bottom: rem(8) solid
      transparent;
      transform: rotate(90deg);
    }
  }

  .message-right {
    float: right;
    position: relative;
    background-color: #656A89;
    color: #FFFFFF;

    &:before {
      @include position(absolute, $bottom:rem(0) ,$right: rem(-8));
      content:"";
      width: 0;
      height: 0;
      border-top: rem(8) solid
      transparent;
      border-right: rem(16) solid
      #656A89;
      border-bottom: rem(8) solid
      transparent;
      transform: rotate(90deg);
    }
  }
}

@media screen and (max-width: $screen-720p) {
  .message {
    margin-bottom: 10px;
    .message-left, .message-right {
      padding: 10px;
    }
  }
}
</style>
