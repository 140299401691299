<template>
  <div class="scroll-wrapper">
    <div class="task-wrapper">
      <FeedbackDisplay v-if="shouldBeShowingFeedback"
                       :custom-feedback-video="getSelectedVideo"
                       :on-press-next="closeFeedback"/>
      <div v-else>

        <h1 class="slide-title">{{ slideContent.title }}</h1>
        <p class="text" v-html="slideContent.description"></p>

        <div :style="historyLocation !== null ? 'pointer-events: none' : ''" class="picture-answers">
          <div
            v-for="(answer, index) in slideContent.questionContent.answers"
            :key="answer.id"
            class="answer-wrapper"
          >
            <label :for="`answer-${answer.id}`" class="check-label">
              <div
                :class="{'selected-answer': (actualAnswer ?? selectedAnswer) && (actualAnswer ?? selectedAnswer) === answer.id, 'unselected-answer': (actualAnswer ?? selectedAnswer) && (actualAnswer ?? selectedAnswer) !== answer.id}"
                class="answer"
              >
                <img v-if="answer.hasAnswerPicture" :src="answer.answerPictureUrl" alt="">
                <div v-if="answer.text" :class="{ 'halfway': answer.hasAnswerPicture }" class="answer-text">
                  <p>
                    {{ answer.text }}
                  </p>
                </div>
                <input
                  :id="`answer-${answer.id}`"
                  v-model="selectedAnswer"
                  :value="answer.id"
                  name="basic-checkbox"
                  type="radio"
                />
                <span class="answer-letter">{{ alphabet[index] }}.</span>
                <span class="check-mark"></span>
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <GameFooter
    v-if="!shouldBeShowingFeedback"
    :continue-button-click="checkAnswers"
    :continue-button-disabled="!selectedAnswer"
    :continue-button-text="$t('STATIC.NEXT')"
    :feedback-button-text="$t('FOOTER.FEEDBACK_BUTTON_TEXT')"
  />
</template>

<script>
import { POST_SLIDE_ANSWER } from '@/store/actions'
import { alphabet } from '@/utils/data/alphabet'
import GameFooter from '@/components/GameFooter'
import FeedbackDisplay from '../FeedbackDisplay'
import { mapGetters } from 'vuex'

export default {
  name: 'Dilemma',
  components: { FeedbackDisplay, GameFooter },
  props: {
    slideContent: {
      type: Object,
      required: true,
    }
  },
  emits: ['nextSlide'],
  data() {
    return {
      selectedAnswer: null,
      correctAnswer: null,
      showAnswersOverlay: false,
      showCorrect: false,
      showIncorrect: false,
      videoText: 'Result',
      videoUrl: null,
      alphabet
    }
  },
  computed: {
    ...mapGetters({
      mission: 'getMission',
      slide: 'getSlideContent',
      bit: 'getBits',
      session: 'getSession',
      givenAnswer: 'getClientAnswer',
      historyLocation: 'getHistoryLocation',
      showFeedbackModal: 'getShowFeedbackModal'
    }),
    shouldBeShowingFeedback() {
      return this.historyLocation?.isPostAnswer || this.showFeedbackModal
    },
    getSelectedVideo() {
      const video = this.videoUrl?.length > 0 ? this.videoUrl : this.slideContent.questionContent.answers.filter(answer => answer.id === this.givenAnswer)[0].resultVideoId
      return this.getVideoUrl(video)
    },
    actualAnswer() {
      /*      if (this.selectedAnswers.length > 0) {
              return this.selectedAnswers
            }*/

      const answers = this.$store.getters.getClientAnswer
      console.log('got given answers', answers)
      if (Array.isArray(answers)) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.selectedAnswer = answers
        return answers
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.selectedAnswers = answers
        return answers
      }
    },
  },
  methods: {
    getVideoUrl(video) {
      console.log('getting video URL')
      try {
        return video.getVideoUrl()
      } catch (e) {
        console.log('Error getting accessibility type')
        console.error(e)
        return this.videoUrl
      }
    },
    checkAnswers() {
      const formData = new FormData()
      formData.append('missionId', this.slideContent.missionId)
      formData.append('slideIndex', this.slideContent.slideIndex)
      formData.append('answer', this.selectedAnswer)
      this.$store.dispatch(POST_SLIDE_ANSWER, formData)
        .then(() => {
          const video = this.videoUrl?.length > 0 ? this.videoUrl : this.slideContent.questionContent.answers.filter(answer => answer.id === this.givenAnswer)[0].resultVideoId
          this.videoUrl = this.getVideoUrl(video)
          // next tick to make sure the video is loaded
          this.$nextTick(() => {
            this.$store.dispatch('showFeedbackModal')
          })
        })
    },
    // todo Ed unused??
    // todo Ed unused??
    // todo Ed unused??
    // givenAnswers() {
    //   /*      if (this.selectedAnswers.length > 0) {
    //           return this.selectedAnswers
    //         }*/
    //
    //   const answers = this.$store.getters.getClientAnswer
    //   console.log('got given answers', answers)
    //   if (Array.isArray(answers)) {
    //     // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    //     this.selectedAnswer = answers
    //     return answers
    //   } else {
    //     // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    //     this.selectedAnswers = answers
    //     return answers
    //   }
    // },
    closeFeedback() {
      // Hides feedback modal
      this.$store.dispatch('hideFeedbackModal')
      this.$emit('nextSlide')
    },
    closeVideo() {
      //Hides feedback modal
      this.$store.dispatch('hideFeedbackModal')
      this.$emit('nextSlide')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";

.task-wrapper {
  color: #FFFFFF;
  padding-top: 48px;
  width: 100%;
}

.slide-title {
  margin-bottom: rem(25);
}

.disabled {
  cursor: default;
  pointer-events: none;
}

.text {
  margin-bottom: rem(25);
  font-size: rem(20);
  line-height: rem(35);
}

.picture-answers {
  display: flex;
  gap: rem(30);
  justify-content: center;
  padding: rem(3) rem(20);
  flex-wrap: nowrap;
  height: 53vh;
  align-items: center;
  min-width: rem(1000);
  overflow: auto;
}

.answer-wrapper {
  display: flex;
  align-items: flex-start;
  width: 100%;
  justify-content: center;
  max-width: 25vw;
  max-height: 25vw;
  height: calc(100% - 2px);
  min-width: rem(200);

  .answer-letter {
    font-size: rem(30);
    font-weight: 500;
    top: rem(18);
    left: rem(18);
    user-select: none;
    position: absolute;
  }

  .answer.unselected-answer {
    opacity: 0.5;
  }

  .answer {
    width: 100%;
    height: 100%;
    font-size: rem(24);
    font-weight: 800;
    border-radius: rem(10);
    color: #FFFFFF;
    position: relative;

    background-color: #373B52;
    box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, 0.1);

    .answer-text {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 100%;

      display: flex;
      align-items: center;

      p {
        text-align: center;
        width: 100%;
      }

      &.halfway {
        height: fit-content;
        padding: rem(12);
        background-color: #373B52;
        border-radius: 0 0 rem(10) rem(10);
      }
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: inherit;
    }
  }

  .selected-answer {
    outline: solid 2px deeppink;
  }

  .correct-answer-outline {
    outline: solid 2px rgba(1, 170, 161, 1);
  }
}

.check-answer-wrapper {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  border-top: solid 1px #FFFFFF;
  padding: rem(40);

  .check-answer-btn {
    float: right;
    background-color: deeppink;
    color: #FFFFFF;
    font-size: rem(18);
    padding: rem(15) rem(30);
    border-radius: rem(5);
    border: none;

    &:disabled {
      background-color: #ACABB8;
    }
  }
}

.check-label {
  margin: 0;
  display: block;
  cursor: pointer;
  text-align: left;
  width: 100%;
  height: 100%;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked ~ .check-mark {
      background-color: deeppink;
      border: none;

      &:after {
        display: block;
      }
    }
  }

  .check-mark {
    position: absolute;
    top: rem(18);
    right: rem(18);
    border-radius: 50%;
    height: rem(27);
    width: rem(27);
    border: 2px solid deeppink;
    background-color: white;

    &:after {
      content: "";
      display: none;
      position: absolute;
      top: rem(2);
      left: rem(9);
      width: rem(10);
      height: rem(18);
      border: solid #FFFFFF;
      border-width: 0 rem(4) rem(4) 0;
      transform: rotate(45deg);
    }
  }

  .check-mark-error {
    @extend .check-mark;
    border: 2px solid red;
  }
}

@media screen and (max-width: $screen-sm) {
  .answer-wrapper .answer {
    font-size: 0.1rem;
  }
}

//@media screen and (max-width: $screen-720p) {
//}
</style>
