<template>
  <div class="request-wrapper">
    <img class="request-image" :src="require('@/assets/images/img_avatar_boy.svg')" alt="Avatar">
    <h1 class="friend-request-name">Vriendschapsverzoek</h1>
    <p class="friend-request-message">{{ data.message }}</p>
  </div>
</template>

<script>
export default {
  name: 'ExamineFriendRequest',
  props: {
    data: {
      type: Object,
      required: true,
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "~@/assets/css/_base.variables.scss";
  @import "~@/assets/css/_base.mixins.scss";

  .request-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    background-color: #E7EBF1;
    padding: rem(40) rem(100) 0 rem(100);
    border-radius: rem(10);
    height: 100%;

    .request-image {
      width: rem(150);
      margin-bottom: rem(25);
    }

    .friend-request-name {
      font-size: rem(25);
      margin-bottom: rem(22);
    }

    .friend-request-message {
      max-width: rem(450);
      font-size: rem(21);
    }
  }
</style>
