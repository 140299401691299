export default class MissionSession {
  /**
   * Example document
   *         "id": 212,
   *         "createdAt": "2023-06-01 09:54:50",
   *         "updatedAt": "2023-06-01 11:02:07",
   *         "finishedAt": null,
   *         "bitsEarned": 0,
   *         "currentStreak": 0,
   *         "currentMissionSlideIndex": 7,
   *         "schoolClassId": 227,
   *         "missionId": 24,
   *         "currentMissionSlideId": 136,
   *         "currentSubSlideIndex": null
   */

  constructor(id, createdAt, updatedAt, finishedAt, bitsEarned, currentStreak, currentMissionSlideIndex, schoolClassId, missionId, currentMissionSlideId, currentSubSlideIndex) {
    this.id = id
    this.createdAt = createdAt
    this.updatedAt = updatedAt
    this.finishedAt = finishedAt
    this.bitsEarned = bitsEarned
    this.currentStreak = currentStreak
    this.currentMissionSlideIndex = currentMissionSlideIndex
    this.schoolClassId = schoolClassId
    this.missionId = missionId
    this.currentMissionSlideId = currentMissionSlideId
    this.currentSubSlideIndex = currentSubSlideIndex
  }

  static parseFromDataObject(data) {
    return new MissionSession(data.id,
      data.createdAt,
      data.updatedAt,
      data.finishedAt,
      data.bitsEarned,
      data.currentStreak,
      data.currentMissionSlideIndex,
      data.schoolClassId,
      data.missionId,
      data.currentMissionSlideId,
      data.currentSubSlideIndex
    )
  }
}
