<template>
  <Modal :is-shown="!!explanationText">
    <template #content>
      <div class="cannot-apply-joker-content">
        <h2 class="cannot-apply-joker-title">Joker kan niet ingezet worden</h2>
        <p class="cannot-apply-joker-description">{{ explanationText }}</p>
      </div>

      <div class="button-options">
        <BasicButton button-text="Ok" @click="$emit('close' ,true)"/>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from './Modal'
import BasicButton from '@/components/elements/basic/BasicButton.vue'

export default {
  name: 'JokerCannotApplyModal',
  components: {
    Modal,
    BasicButton
  },
  props: {
    explanationText: {
      type: String,
      default: '',
    }
  },
  emits: ['close'],
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";
@import "~@/assets/css/global.scss";
@import "~@/assets/css/mission.scss";

.cannot-apply-joker-content {
  color: white;
  .cannot-apply-joker-title {
  }

  .cannot-apply-joker-description {
    margin-top: 32px;
  }
}

.button-options {
  display: flex;
  justify-content: space-evenly;
  margin-top: 32px;
}
</style>
