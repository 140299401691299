import MazeQuestionContentSubSlide from './MazeQuestionContentSubSlide'

export default class MazeQuestionContent {
  /**
   *
   * @type {MazeQuestionContentSubSlide[]}
   */
  subSlides = [];

  constructor(subSlides) {
    this.subSlides = subSlides
  }

  static parseFromDataObject(data) {
    return new MazeQuestionContent(
      data.subSlides.map(subSlide => MazeQuestionContentSubSlide.parseFromDataObject(subSlide)))
  }
}
