<template>
  <input
    v-model="inputValue"
    class="number-input"
    type="number"
    :class="{'zero-value': inputValue === null}"
    placeholder="0"
    :min="minValue"
    :max="maxValue"
  >
</template>

<script>
import MathUtils from '@/utils/MathUtils'

export default {
  name: 'NumberInput',
  props: {
    minValue: {
      type: Number,
      required: false,
      default: 0,
    },
    maxValue: {
      type: Number,
      required: true
    },
  },
  emits: ['updateNumberValue'],
  data: () => ({
    value: null
  }),
  computed: {
    /**
     * inputValue is used to clamp manual input
     */
    inputValue: {
      get() {
        return this.value
      },
      set(val) {
        this.updateValue(val)
      }
    }
  },
  methods: {
    /**
     * Update value to 'value' parameter, will clamp value between 'minValue' & 'maxValue'.
     * Method will also emit 'updateNumberValue', to let parent components know number is updated.
     */
    updateValue(value) {
      this.value = MathUtils.clamp(parseInt(value), this.minValue, this.maxValue)
      this.$emit('updateNumberValue', this.value ?? 0)
    },
    /**
     * Increment value by 1, clamp to 'maxValue'
     */
    increaseValue() {
      this.updateValue(this.value + 1)
    },
    /**
     * Decrease value by 1, clamp to 'minValue'
     */
    decreaseValue() {
      this.updateValue(this.value - 1)
    },
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";

.number-input {
  text-align: center;
  font-size: rem(24);
  font-family: 'Rubik', sans-serif;
  line-height: rem(28);
  font-weight: 500;
  color: var(--text_color);

  &.zero-value {
    color: #7A95B2;
  }
}
</style>
