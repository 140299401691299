<template>
  <div v-if="playing" class="content-wrapper quiz-wrapper">
    <QuizHeader @endGame="playing=false"/>
    <QuizSlide
      :class-id="loggedInData ? loggedInData.schoolClass.id : null"
      :slides-seen="slidesSeen"
      @increaseAnsweredCorrectCounter="correctlyAnswered++"
      @increasePlayCounter="increasePlayed"
      @newSlideSeen="updateSeenSlides"
    />
  </div>
  <QuizComplete v-if="!playing" :correctly-answered="correctlyAnswered" @continue="playing = true"/>
</template>

<script>

import QuizSlide from './QuizSlide'
import QuizHeader from '@/components/QuizHeader'
import QuizComplete from './QuizComplete.vue'
import { computed } from '@vue/runtime-core'
import { useRoute } from 'vue-router'
import apiHandler from '@/utils/handlers/ApiHandler'
import { SET_MEDIA_QUIZ_BITS } from '../../store/mutations'
import { GET_WEEK_STATUS } from '@/store/actions'

export default {
  name: 'QuizMain',
  components: {
    QuizSlide,
    QuizHeader,
    QuizComplete,
  },
  data() {
    return {
      correctlyAnswered: 0,
      played: 0,
      playing: true,
      slidesPerRound: 30,
      slidesSeen: [],
      loggedInData: null,
    }
  },
  created() {
    const route = useRoute()
    const queryClassId = computed(() => Number.parseInt(route.query['class-id']))
    if (queryClassId.value) {
      const formData = new FormData()
      formData.append('classId', queryClassId.value)
      this.loginClass(formData)
    }

    this.$store.dispatch(GET_WEEK_STATUS)

    this.$router.replace({ query: null })
    const localStorageLoggedInData = localStorage.getItem('schoolClass')
    this.loggedInData = localStorageLoggedInData ? JSON.parse(localStorageLoggedInData) : null
    this.$store.commit(SET_MEDIA_QUIZ_BITS, 0)
  },
  // mounted() {
  // },
  methods: {
    loginClass(formData) {
      apiHandler.api.post('/auth/login-extra-game-class', formData)
        .then((res) => {
          localStorage.setItem('schoolClass', JSON.stringify(res.data))
          // this.$router.push('/extra-games')
          // console.log('loginExtraGame', res.data)
          // console.log('res', res)
        })
        .catch((error) => {
          console.log('error', error)
        })
    },
    increasePlayed() {
      this.played++
      if (this.played % this.slidesPerRound === 0) {
        this.playing = false
      }
    },
    updateSeenSlides(newSlide) {
      this.slidesSeen.unshift(newSlide)
      if (this.slidesSeen.length > 50) {
        this.slidesSeen.pop()
      }
      // console.log('seen', this.slidesSeen)
    },
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.content-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  flex-grow: 1;
  text-align: center;

  :deep a {
    text-decoration: none;
    color: var(--highlight_color);
    font-weight: 700;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
