import { mutations } from "./mutations";
import { actions } from "./actions";
import { getters } from "./getters";

export const state = {
  extraGames: [],
  classConversationMissions: []
}

const namespaced = false

export const Extras = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
