<template>
  <div :class="mission.state" class="mission-container">
    <div class="day-wrapper">
      <p class="day">Missie {{ mission.dayOfMM }}</p>
    </div>

    <h2 v-if="!isOpen" class="mission-name">{{ mission.name }}</h2>
    <!--  <p v-if="!isOpen && isCompleted" class="mission-description-completed">{{ mission.description }}</p>-->
    <div class="mission-cover-image center-container">
      <img v-if="mission.coverImage" :src="mission.coverImage">
    </div>

    <div v-if="isOpen" class="mission-header-below-icon">
      <h2 class="mission-name">{{ mission.name }}</h2>
      <p :style="isFinale ? 'margin-top: 32px' : ''" class="mission-description">{{ mission.description }}</p>
    </div>

    <div v-if="(isCompleted || isOpen) && mission.joker" class="joker">
      <p v-if="isCompleted" class="joker-earned-title">VERDIENDE JOKER</p>
      <p v-else-if="isOpen" class="joker-earned-title">TE VERDIENEN JOKER</p>
      <p class="joker-title">{{ mission.joker.title }}</p>
      <div class="bottom">
        <img :src="mission.joker.icon" alt="">
        <p class="joker-description">{{ mission.joker.description }}</p>
      </div>
    </div>

    <BasicButton v-if="isOpen" :button-text="$t('SELECT_MISSION.START_MISSION')" @click="$emit('selected')"/>
    <p v-if="isCompleted" class="description">Deze missie hebben jullie succesvol afgerond!</p>
    <p v-else-if="isFinale && isLocked" class="description">De finale kunnen jullie starten wanneer de vorige missies
      zijn afgerond!</p>
    <p v-else-if="isLocked" class="description">Deze missie kunnen jullie starten wanneer de vorige missies zijn
      afgerond!</p>
    <!--    <p v-else-if="isOpen" class="description">Deze missie hebben jullie succesvol afgerond!</p>-->
    <!--    <p v-else class="description">{{ mission.description }}</p>-->
    <div v-if="hasFlags" class="flags">
      <div>
        <img class="flag" src="@/assets/images/img_flag_red.png">
        <img class="flag" src="@/assets/images/img_flag_blue.png">
      </div>
      <div>
        <img class="flag" src="@/assets/images/img_flag_blue.png">
        <img class="flag" src="@/assets/images/img_flag_red.png">
      </div>
    </div>
  </div>
</template>

<script>
import BasicButton from './elements/basic/BasicButton'

export default {
  name: 'MissionDayOverview',
  components: {
    BasicButton
  },
  props: {
    mission: {
      type: Object,
      required: true
    },
    hasFlags: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['selected'],
  data() {
    const centerContainerImages = {
      locked: require('@/assets/images/icn_lock.png'),
      finaleLocked: require('@/assets/images/icn_lock_finale.png')
    }
    return {
      centerContainerImages
    }
  },
  computed: {
    isCompleted() {
      return this.mission.state === 'completed'
    },
    isLocked() {
      return this.mission.state === 'locked'
    },
    isOpen() {
      return this.mission.state === 'open'
    },
    isFinale() {
      return this.mission.dayOfMM === 5
    },
  },
  // created() {
  // },
  // methods: {
  // },
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";
@import "~@/assets/css/global.scss";

p, h2, h3 {
  color: white;
}

.joker-earned-title {
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
}

.mission-container {
  width: rem(275);
  border-radius: rem(20);
  padding: rem(24);
  position: relative;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  .mission-cover-image {
    img {
      width: 100%;
    }
  }

  .day-wrapper {
    background-color: rgba(white, 0.16);
    border-radius: 30px;
    width: fit-content;
    margin-bottom: rem(19);

    .day {
      font-weight: 500;
      text-transform: uppercase;
      padding: rem(8) rem(13);
      color: #ffffff;
      font-size: rem(16);
      opacity: 1;
      line-height: 19px;
    }
  }

  h3 {
    opacity: .8;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 19px;
    margin-bottom: rem(3);
    font-weight: normal;
    text-transform: uppercase
  }

  .mission-name {
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
    text-transform: uppercase;
    height: rem(56);
    margin-bottom: 0;
    z-index: 100;
  }

  .mission-header-below-icon {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 16px;

    .mission-description {
      font-size: 16px;
      line-height: 24px;
      text-align: center;
    }
  }

  .center-container {
    width: 100%;
    max-height: rem(237);
    flex-grow: 1;
    border-radius: rem(20);
    margin-bottom: rem(8);
    margin-top: rem(8);
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: rem(8);
    }
  }

  .description {
    font-size: 16px;
    line-height: 24px;
    margin-top: auto;
    z-index: 10;
  }

  .flags {
    position: absolute;
    width: 100%;
    height: 100%;
    margin-left: rem(-24);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    z-index: 0;

    .flag {
      width: 100%;
      object-fit: contain;
    }
  }

  @media screen and (max-width: $screen-1440) {
    .flags {
      margin-left: rem(-16);
    }
  }

  &.completed {
    background-color: #148F8A;

    .mission-name {
      margin-top: 6px;
      margin-bottom: rem(0);
      z-index: 100;
    }

    .center-container {
      background: url("../assets/images/img_pattern_day1.png"), linear-gradient(180deg, #139E9A 0%, #0DD2C6 100%);

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border: none;
      }
    }

    .joker {
      margin-bottom: rem(21);
    }

    .description {
      margin-bottom: rem(8);
    }
  }

  &.locked {
    background-color: #553888;

    h3 {
      margin-top: auto;
    }

    h2 {
      margin-bottom: rem(33);
    }

    .center-container {
      background: url("../assets/images/img_pattern_day3.png"), #321C59;
      margin-bottom: rem(24);
    }

    .description {
      margin-bottom: rem(26);
    }
  }

  &.open {
    width: rem(300);
    height: 115%;
    background-image: url("../assets/images/img_pattern_day2.png"), linear-gradient(180deg, #35234E 0%, #252345 100%);
    background-repeat: no-repeat;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.29);

    .day-wrapper {
      margin: rem(20) auto 0;
      background-color: #4D4C89;
    }

    .mission-cover-image {
      width: 100%;
      max-height: rem(327);
      flex-grow: 1;
      position: relative;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    h3 {
      text-align: center;
      line-height: 24px;
      font-size: rem(20);
      opacity: 0.6;
      margin-bottom: rem(3);
    }

    h2 {
      text-align: center;
      line-height: 29px;
      font-size: 28px;
      //margin-bottom: rem(20);
      word-wrap: break-word;
    }

    .joker {
      margin-bottom: rem(40);
    }

    button {
      margin-top: auto;
      margin-bottom: rem(24);
      display: block;
      width: 100%;
    }
  }

  &.completed, &.open {
    .joker {
      z-index: 1;
      width: 100%;
      height: fit-content;
      border-radius: rem(17.5);
      background-color: rgba(white, 0.16);
      padding: rem(14) rem(16);

      .joker-title {
        text-transform: uppercase;
        font-weight: 600;
        margin-bottom: rem(8);
      }

      .joker-description {
        color: white;
        font-size: 14px;
        line-height: 18px;
        flex-basis: 70%;
      }

      .bottom {
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
        align-items: center;

        img {
          width: rem(44);
          height: rem(44);
        }
      }
    }
  }

  &.completed {
    background: #0DD2C6 url("../assets/images/img_pattern_day2_on_green.png") no-repeat;

    .day-wrapper {
      background-color: rgba(black, 0.26);
    }

    .joker {
      background-color: #199A94;
    }
  }

  &.open {
    .joker {
      background-color: #3C3165;
    }
  }
}
</style>
