<template>
  <Modal>
    <template #content>
      <div class="container">
        <h1>{{ extraGameObject.name }}</h1>
        <h3>Je hebt de extra game voltooid!</h3>
        <div class="reward">
          <h2 class="reward-message">Beloning:</h2>
          <BitsDisplay :amount="bitsAwarded"/>
        </div>
        <p v-if="isWeekActive">De klas <span style="font-weight: 500">{{ className }}</span> heeft {{ Math.round(bitsAwarded) }} bits verdiend.</p>
        <p v-else>De bits zijn niet daadwerkelijk toegekend omdat de week van MediaMasters momenteel nog niet actief is.</p>
        <div class="button-wrapper">
          <button class="button-main pink-button" @click="onOkButtonPressed">{{ modalButtonText ? modalButtonText : $t('FEEDBACK.OK') }}</button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/partials/modal/Modal'
import BitsDisplay from '@/components/elements/basic/BitsDisplay'

export default {
  name: 'GameCompletedModal',
  components: {
    Modal,
    BitsDisplay
  },
  props: {
    extraGameObject: {
      type: Object,
      required: true
    },
    bitsAwarded: {
      type: Number,
      required: true
    },
    className: {
      type: String,
      required: true
    },
    isWeekActive: {
      type: Boolean,
      required: true
    },
    onOkButtonPressed: {
      type: Function,
      required: false,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.container {
  width: rem(600);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: rem(16);

  h1, h2, h3, p {
    color: white;
  }
  .reward-message {
    opacity: .6;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 24px;
  }
  .button-wrapper {
    margin-top: rem(16);
  }
}
</style>
