<template>
  <div class="quiz-slide-header">
    <h2 v-if="title" class="slide-title">
      {{ title }}
      <slot name="title-slot"></slot>
    </h2>
    <div v-if="description" class="slide-description">
      <span v-html="description"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SlideHeaderQuiz',
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";
@import "~@/assets/css/quiz.scss";

</style>
