const CACHE_SIZE = 20

/**
 * This class is used to cache the slide content of any missions by id.
 * To reduce the stress on the server when loading a slide, the slide content is cached.
 */
export default class SlideContentCache {
  /** @type {MissionSlide[]} */
  cache = [];

  constructor() {

  }

  /**
   * Add a slide content to the cache.
   * @param {MissionSlide} slideContent
   */
  addToCache(slideContent) {
    this.cache.push(slideContent)
    if (this.cache.length > CACHE_SIZE) {
      this.cache.shift()
    }
    // console.log(`added to cache id ${slideContent.missionSlideId}, current cache`, this.cache)
  }

  /**
   * Get a slide content from the cache.
   * @param {number} slideId the id of the slide.
   * @return {MissionSlide|null} the slide content if it exists in the cache, null otherwise.
   */
  getFromCache(slideId) {
    const cachedSlide = this.cache.find(slideContent => slideContent.missionSlideId === slideId) ?? null
    // console.log(`get from cache id ${slideId}, found`, cachedSlide, 'current cache', this.cache)
    return cachedSlide
  }

  /**
   * Get a slide content from the cache.
   * @param {number} missionId
   * @param {number} slideIndex
   * @return {MissionSlide|null}
   */
  getFromCacheUsingMissionIdAndSlideIndex(missionId, slideIndex) {
    const cachedSlide = this.cache.find(slideContent => slideContent.missionId === missionId && slideContent.slideIndex === slideIndex) ?? null
    //console.log(`get from cache missionId ${missionId} and slideIndex ${slideIndex}, found`, cachedSlide, 'current cache', this.cache)
    return cachedSlide
  }
}
