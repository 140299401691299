import QuestionContent from '../questionContent/QuestionContent'
import MissionSlideType from './MissionSlideType'
import MissionCategory from './MissionCategory'
import { SlideVideo } from './SlideVideo'

export default class MissionSlide {
  /**
   *
   * @param {number }missionSlideId
   * @param {string} title
   * @param {string} description
   * @param {number} slideIndex
   * @param {any} questionContent
   * @param {number} missionId
   * @param {number} missionSlideTypeId
   * @param {string} feedback
   * @param {string} missionTitle
   * @param {string} missionDescription
   * @param {number} dayOfMM
   * @param {boolean} hasTip
   * @param {MissionSlideType} missionSlideType
   * @param {MissionCategory} missionCategory
   * @param {number} missionTotalSlideCount
   * @param {SlideVideo} correctVideo
   * @param {SlideVideo} incorrectVideo
   */

  constructor(missionSlideId,
    title,
    description,
    slideIndex,
    questionContent,
    missionId,
    missionSlideTypeId,
    feedback,
    missionTitle,
    missionDescription,
    dayOfMM,
    hasTip,
    missionSlideType,
    missionCategory,
    missionTotalSlideCount,
    correctVideo,
    incorrectVideo,
    mediaQuizSlideId) {
    this.missionSlideId = missionSlideId
    this.title = title
    this.description = description
    this.slideIndex = slideIndex
    this.questionContent = questionContent
    this.missionId = missionId
    this.missionSlideTypeId = missionSlideTypeId
    this.feedback = feedback
    this.missionTitle = missionTitle
    this.missionDescription = missionDescription
    this.dayOfMM = dayOfMM
    this.hasTip = hasTip
    this.missionSlideType = missionSlideType
    this.missionCategory = missionCategory
    this.missionTotalSlideCount = missionTotalSlideCount
    this.correctVideo = correctVideo
    this.incorrectVideo = incorrectVideo
    this.mediaQuizSlideId = mediaQuizSlideId
  }

  static parseFromDataObject(data) {
    return new MissionSlide(
      data.missionSlideId,
      data.title,
      data.description,
      data.slideIndex,
      QuestionContent.convertToCorrectQuestionContent(data.questionContent, data.missionSlideTypeId),
      data.missionId,
      data.missionSlideTypeId,
      data.feedback,
      data.missionTitle,
      data.missionDescription,
      data.dayOfMM,
      data.hasTip,
      MissionSlideType.fromJson(data.missionSlideType),
      MissionCategory.fromJson(data.missionCategory),
      data.missionTotalSlideCount,
      SlideVideo.parseFromDataObject(data.correctVideo),
      SlideVideo.parseFromDataObject(data.incorrectVideo),
      data.mediaQuizSlideId,
    )
  }
}
