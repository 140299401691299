export const getters = {
  getMission(state) {
    return state.mission
  },
  getDay(state) {
    return state.day
  },
  getBits(state, getters) {
    return getters.getSession ? getters.getSession.bitsEarned : 0
  },
  getMediaQuizBits(state, getters) {
    return state.mediaQuizBits
  },
  getSlideContent(state) {
    return state.slideContent
  },
  getCurrentSlide(state) {
    return state.slideContent
  },
  getWeekStatus(state) {
    return state.weekStatus
  },
  getSession(state) {
    return state.session
  },
  getAwardedJoker(state) {
    return state.awardedJoker
  },
  getAvailableJokers(state) {
    return state.availableJokers
  },
  getSlideStartTime(state) {
    return state.slideStartTime
  },
  getBonusEarned(state) {
    return state.bonusEarned
  },
  getJokerResult(state) {
    return state.jokerResult
  },
  getJokerUsed(state) {
    return state.jokerUsed
  },
  getHistoryIsValid(state) {
    /** @type {MissionSession|null} */
    const session = state.session
    /** @type {SessionHistory|null} */
    const history = state.history

    // If we are mid-loading a new session, then best to ignore the cache and load the history no matter what.
    const sessionIsLoading = getters.sessionIsLoading

    const historyIsValid = session != null && history != null && history.currentSlideId === session.currentMissionSlideId

    return historyIsValid && !sessionIsLoading
  },
  /**
   * @param state
   * @return {historyLocation}
   */
  getHistoryLocation(state) {
    return state.historyLocation
  },
  isHistoryMode(state, getters) {
    return getters.getHistoryLocation != null
  },
  /**
   * @param state
   * @return {SessionHistory}
   */
  getHistory(state) {
    return state.history
  },
  getSessionIsLoading(state) {
    return state.sessionIsLoading
  },
  getSlideFeedback(state) {
    return state.slideContent?.feedback ?? state.submission?.feedback
  },
  /**
   *
   * @param state
   * @return {SlideVideo|null}
   */
  getCorrectFeedbackVideo(state) {
    return state.slideContent?.correctVideo
  },
  /**
   *
   * @param state
   * @return {SlideVideo|null}
   */
  getIncorrectFeedbackVideo(state) {
    return state.slideContent?.incorrectVideo
  },
  /** Returns if we have recieved the correct answers so far, or if the user has not answered yet
   *
   * @param state
   * @return {number|number[]|null}
   */
  getCorrectAnswers(state, getters) {
    const session = getters.getSession
    /** @type {HistoryLocation | null} */
    const historyLocation = getters.getHistoryLocation
    /** @type {SessionHistory | null} */

    const history = getters.getHistory

    if (history && historyLocation) {
      const slideIndex = historyLocation.currentSlideIndex
      const slide = history.previousSlides[slideIndex]
      if (historyLocation.currentSubSlideIndex !== null) {
        return slide.subSlideAnswers[historyLocation.currentSubSlideIndex].correctAnswer
      } else {
        return slide.correctAnswer
      }
    } else {
      const submission = getters.getSubmission

      if (submission != null) {
        return submission.correctAnswer
      }
    }
    return null
  },
  getClientAnswer(state, getters) {
    const session = getters.getSession
    /** @type {HistoryLocation | null} */
    const historyLocation = getters.getHistoryLocation
    /** @type {SessionHistory | null} */

    const history = getters.getHistory

    if (history && historyLocation) {
      const slideIndex = historyLocation.currentSlideIndex
      const slide = history.previousSlides[slideIndex]
      if (historyLocation.currentSubSlideIndex != null) {
        return slide.subSlideAnswers[historyLocation.currentSubSlideIndex].answerGiven
      } else {
        return slide.answerGiven
      }
    } else {
      const submission = getters.getSubmission

      if (submission != null) {
        return submission.clientAnswer
      }
    }

    //else check if a submissions is valid for this slide.
  },
  getGivenAnswers(state, getters) {

  },
  getSubmission(state) {
    return state.submission
  },
  getShowLoadingScreen(state) {
    return state.showLoadingScreen
  },
  getShowFeedbackModal(state) {
    return state.showingFeedbackModal
  },
  getStreak(state) {
    return state.streak
  },
}
