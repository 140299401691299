/**
 * Safari is 10 years behind on proper support for ECMAScript 5 ISO-8601 format with Date objects,
 * so we need this to parse dates formatted as SQL's DATETIME format
 * @param dateStringSql
 * @returns {*}
 */
export function parseDateFromSqlSafariSupported(dateStringSql) {
  const dateTimeParts = dateStringSql.split(/[- :]/)
  dateTimeParts[1]--

  return new Date(...dateTimeParts)
}
