<template>
  <div class="scroll-wrapper">

    <div class="task-wrapper">
      <FeedbackDisplay v-if="shouldBeShowingFeedback"
                       :custom-feedback-video="getFeedbackVideo"
                       :on-press-next="showNextMaze"/>
      <div v-else>
        <slide-header :description="slideContent.description" :title="slideContent.title"></slide-header>
        <div class="maze-container">
          <div class="maze-cards-container">
            <div class="maze-cards">
              <cards
                :cards="activeMazes"
                :show-card-image="true"
                padding="3px"
              >
              </cards>
            </div>
          </div>

          <div :style="historyLocation !== null ? 'pointer-events: none' : ''" class="maze-result-container">
            <div>
              <div v-for="(answer, index) in currentMaze.answers"
                   :key="'maze-id-' + answer.id"
                   :class="{'disabled' : hasAnswered() || removedAnswers.includes(answer.id)}"
                   class="maze-path-option"
                   @click="selectedPath = answer.id"
              >
                <input v-model="selectedPath"
                       :disabled="result"
                       :value="answer.id"
                       class="input-radio"
                       style="display: none"
                       type="radio"
                >
                <div class="letter">{{ alphabet[index] }}.</div>
                <div :class="{
                       'removed-path': removedAnswers.includes(answer.id),
                       'selected-path': answer.id === selectedPath,
                       'correct-path': correctAnswer != null && correctAnswer === answer.id/* && answer.id === selectedPath*/,
                       'incorrect-path': correctAnswer != null && correctAnswer !== answer.id && answer.id === selectedPath,
                       'not-selected-path': correctAnswer != null && correctAnswer !== answer.id && answer.id !== selectedPath
                     }"
                     class="path"
                     v-html="result && correctAnswer === answer.id && answer.id === selectedPath ? revealedCharacters[revealedCharacters.length - 1].letter : getButtonContent(answer, answer === selectedPath)"
                >
                </div>
              </div>
            </div>

            <div class="word-container">
              <div
                v-for="(subSlide, index) in slideContent.questionContent.subSlides"
                :key="'subSlide-' + subSlide.id"
                class="character-container">
                <div v-if="revealedCharacters[index]"
                     :class="{ 'is-correct': revealedCharacters[index].correct }"
                     class="character">
                  {{ revealedCharacters[index].letter }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <game-footer
    v-if="!shouldBeShowingFeedback"
    :continue-button-click="checkAnswer"
    :continue-button-disabled="!selectedPath"

    :continue-button-text="$t('FOOTER.SINGLE_ANSWER.CONTINUE_BUTTON_TEXT')"
    :feedback-button-click="retryOrContinueButtonHandler"
    :feedback-button-text="$t(getFeedbackButtonText())"
    :feedback-result="result"
    :force-next="shouldBeShowingFeedback"

    :has-feedback-popup="feedbackText"
  ></game-footer>
</template>

<script>
import GameFooter from '@/components/GameFooter'
import SlideHeader from '@/components/elements/SlideHeader'
import Cards from '@/components/partials/cards/Cards'
import i18n from '@/setup/i18n/i18n'
import { RESET_SLIDE_BONUSES, SET_SUBMISSION, UPDATE_BITS } from '@/store/mutations'
import { POST_SUB_SLIDE_ANSWER } from '@/store/actions'
import { mapGetters } from 'vuex'
import FeedbackDisplay from '../FeedbackDisplay'

const arrow = require('@/assets/icons/placeholders/right-arrow.svg')
const arrowWhite = require('@/assets/icons/placeholders/right-arrow-white.svg')

export default {
  name: 'Maze',
  components: { FeedbackDisplay, GameFooter, SlideHeader, Cards },
  props: {
    slideContent: {
      type: Object,
      required: true,
    },
  },
  emits: ['nextSlide'],
  data: () => ({
    alphabet: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
    selectedPath: null,
    currentMaze: {},
    answeredCards: [],
    revealedCharacters: [],
    // active mazes will be 'sliced' after a subslide was finished
    activeMazes: [],
    result: null,
    feedbackText: '',
    feedbackVideoUrl: '',
    removedAnswers: [],

  }),
  computed: {
    ...mapGetters({
      session: 'getSession',
      jokerResult: 'getJokerResult',
      historyLocation: 'getHistoryLocation',
      showFeedbackModal: 'getShowFeedbackModal',
      history: 'getHistory',
    }),
    getFeedbackVideo() {
      return this.result?.successPercentage > 0.5 ? this.slideContent?.correctVideo?.getVideoUrl() : this.slideContent?.incorrectVideo?.getVideoUrl()
    },
    shouldBeShowingFeedback() {
      return this.historyLocation?.isPostAnswer || this.showFeedbackModal
    },
    correctAnswer() {
      const answers = this.$store.getters.getCorrectAnswers
      console.log('got correct answer', answers)
      if (answers === undefined || answers === null) {
        return null
      }
      console.log('got correct answers', answers)
      if (Array.isArray(answers)) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        //this.selectedPath = answers
        return answers
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        //this.selectedPath = answers
        return answers
      }
    },
    givenAnswers() {
      /*      if (this.selectedAnswers.length > 0) {
              return this.selectedAnswers
            }*/
      const answers = this.$store.getters.getClientAnswer
      console.log('got given answers', answers)
      if (Array.isArray(answers)) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        //this.selectedPath = answers
        return answers
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        //this.selectedPath = answers
        return answers
      }
    },
  },
  watch: {
    slideContent: {
      immediate: true,
      handler(newVal, oldVal) {
        // select the first card when API comes in
        this.activeMazes = [...newVal.questionContent.subSlides]

        if (this.historyLocation) {
          this.activeMazes.splice(0, this.historyLocation.currentSubSlideIndex)
          this.currentMaze = this.activeMazes[0]
        } else {
          if (this.session.currentSubSlideIndex) {
            this.activeMazes.splice(0, this.session.currentSubSlideIndex)
          }
          this.currentMaze = this.activeMazes[0]
        }

        /*        if (this.session.currentSubSlideIndex) {
                  this.activeMazes.splice(0, this.session.currentSubSlideIndex)
                }
                this.currentMaze = this.activeMazes[0]*/

        // console.log('this.currentMaze', this.currentMaze)
      },
    },
    givenAnswers: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal != null) {
          this.selectedPath = newVal
        }
      }
    },
    historyLocation: {
      immediate: true,
      handler(newVal, oldVal) {
        console.log('got new history location')
        this.activeMazes = [...this.slideContent.questionContent.subSlides]

        // console.log('newVal', newVal)
        // console.log('oldVal', oldVal)

        if (newVal) {
          this.activeMazes.splice(0, newVal.currentSubSlideIndex)
          this.currentMaze = this.activeMazes[0]
        } else {
          if (this.session.currentSubSlideIndex) {
            this.activeMazes.splice(0, this.session.currentSubSlideIndex)
          }
          this.currentMaze = this.activeMazes[0]
        }
      },
    },
    jokerResult: {
      deep: true,
      handler: function(curr, prev) {
        if (curr && curr.wrongAnswersToRemove) {
          this.removedAnswers = curr.wrongAnswersToRemove.map(value => value.id)
          if (this.removedAnswers.includes(this.selectedAnswer)) {
            this.selectedAnswer = null
          }
        }
      }
    }
  },
  created() {
    const subslideIndex = this.session.currentSubSlideIndex
    const word = this.slideContent.questionContent.wordToReveal
    for (let i = 0; i < subslideIndex; i++) {
      //this.revealedCharacters.push(word[i])
    }
  },
  // mounted() {
  // },
  methods: {
    checkAnswer() {
      const formData = new FormData()
      formData.append('missionId', this.slideContent.missionId)
      formData.append('slideIndex', this.slideContent.slideIndex)
      formData.append('subSlideIndex', this.currentMaze.id)
      formData.append('answer', this.selectedPath)

      this.$store.dispatch(POST_SUB_SLIDE_ANSWER, formData)
        .then(response => {
          // console.log('POST_SUB_SLIDE_ANSWER response', response)
          this.feedbackText = response.feedbackText
          this.revealedCharacters.push({ letter: response.letterToReveal, correct: response.isAnswerCorrect })
          this.feedbackVideoUrl = response.feedbackVideoUrl
          if (response.isAnswerCorrect) {
            this.result = {
              successPercentage: 1,
              title: i18n.global.tc('FOOTER.GOOD_JOB'),
              id: response.correctAnswer
            }
            this.$store.commit(UPDATE_BITS, response.awardedBits)
          } else {
            this.result = {
              successPercentage: 0,
              title: i18n.global.tc('FOOTER.MAZE.RETRY_FEEDBACK'),
            }
          }
        })
    },
    retryOrContinueButtonHandler() {
      console.log('we are checking if to show the feedback or the next maze')
      if (this.isLastMaze()) {
        this.showFeedback()
      } else {
        this.showNextMaze()
      }
    },
    showFeedback() {
      //Show feedback modal
      if (this.slideContent.feedback || this.slideContent.incorrectVideo || this.slideContent.correctVideo) {
        this.$store.dispatch('showFeedbackModal')
      } else {
        this.$emit('nextSlide')
      }
    },
    resetSubSlide() {
      this.selectedPath = null
      this.result = null
    },
    closeFeedback() {
      console.log("we've hidden the feedback model")
      // Hide feedback modal
      this.$store.dispatch('hideFeedbackModal')
      this.feedbackText = ''
      //If not the last card, show next card
      this.$emit('nextSlide')
    },
    showNextMaze() {
      console.log('we are showing the next maze')
      this.$store.dispatch('hideFeedbackModal')
      this.feedbackText = ''

      const nextMaze = this.slideContent.questionContent.subSlides[this.currentMaze.id]
      this.resetSubSlide()
      // Remove finished maze from active cards
      this.activeMazes.splice(0, 1)
      this.currentMaze = nextMaze
      this.$store.commit(SET_SUBMISSION, null)
      this.$store.commit(RESET_SLIDE_BONUSES)
      this.$emit('nextSlide')
    },
    getButtonContent(path, isSelectedPath) {
      let buttonContent = ''
      for (let i = 0; i < path.answer.length; i++) {
        const direction = path.answer[i]
        buttonContent += '<div class="arrow-wrapper">' +
          '<img class="arrow" src="' + (isSelectedPath ? arrowWhite : arrow) + '" style="' + this.getArrowStyling(direction) + '"  alt="arrow' + '_' + direction + '">' +
          '</div>'
      }
      return buttonContent
    },
    getArrowStyling(direction) {
      switch (direction) {
        case 'down':
          return 'transform: rotate(90deg)'
        case 'up':
          return 'transform: rotate(-90deg)'
        case 'left':
          return 'transform: rotate(180deg)'
      }
      return ''
    },
    hasAnswered() {
      return !!this.result
    },
    isAnswerCorrect() {
      return this.result?.successPercentage === 1
    },
    isAnswerIncorrect() {
      return this.result?.successPercentage === 0
    },
    isLastMaze() {
      return this.currentMaze.id === this.slideContent.questionContent.subSlides.length
    },
    getFeedbackButtonText() {
      if (this.isAnswerCorrect()) {
        if (this.isLastMaze()) {
          return 'FOOTER.FEEDBACK_BUTTON_TEXT'
        } else {
          return 'STATIC.NEXT'
        }
      } else {
        return 'FOOTER.MAZE.RETRY'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";
@import "~@/assets/css/mission.scss";

$maze-width-widescreen: 1250px;
$maze-width-720p: 1250px;

.task-wrapper {
  margin-top: 48px;
}

// Override mission values
.mission-wrapper {
  display: flex;
  flex-direction: column;
}

// Maze CSS
.maze-container {
  height: calc(100% - 140px);
  //width: 100%;
  max-width: $maze-width-widescreen;
  width: $maze-width-widescreen;
  display: flex;
  justify-content: center;

  // Stack of cards
  .maze-cards-container {
    .maze-cards {
      height: rem(400);
      width: rem(560);
    }
  }

  // Results section (word & answers)
  .maze-result-container {
    display: flex;
    flex-direction: column;
    margin-left: rem(50);
  }

  .disabled {
    pointer-events: none;
  }

  // Answers
  .maze-path-option {
    padding: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;

    &:checked {
      color: var(--primary_color);
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    .letter {
      font-size: rem(25);
      font-weight: 700;
      margin-right: rem(15);
    }

    .path {
      display: flex;
      background-color: #FFFFFF;
      padding: rem(10) rem(25);
      border-radius: rem(8);
      color: black;
      gap: 20px;
      flex-grow: 1;
      align-items: center;
      justify-content: center;

      :deep .arrow-wrapper {
        display: flex;
        align-items: center;

        .arrow {
          width: rem(30);
        }
      }
    }

    .removed-path {
      pointer-events: none;
      background-color: #9590A4;
    }

    .selected-path {
      background-color: var(--primary_color);
      color: #FFFFFF;

      :deep .arrow {
        color: #FFFFFF
      }
    }

    .correct-path {
      background-color: var(--answer_correct_color);
      font-size: rem(25);
      font-weight: 500;
      justify-content: center;
    }

    .incorrect-path {
      background-color: var(--answer_incorrect_color);
    }

    .not-selected-path {
      background-color: var(--answer_default_dark_color)
    }
  }

  // Word
  .word-container {
    width: 100%;
    //margin-left: rem(48);
    //margin-top: rem(120);
    display: flex;
    gap: rem(16);
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: auto;

    .character-container {
      width: rem(80);
      height: rem(80);
      max-width: rem(90);
      max-height: rem(90);

      border-radius: rem(15);
      background-image: linear-gradient(#221C3E, #272048);
      @include box-shadow(0, rem(5), rem(15), #000000, rem(-6), true);

      .character {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: rem(50);
        font-weight: 500;
        width: 100%;
        height: 100%;
        border-radius: rem(15);
        background-color: var(--answer_incorrect_color);
        color: white;
        @include box-shadow(rem(4.5), rem(4.5), 0, rgba(0, 0, 0, .3));

        &.is-correct {
          background-color: var(--answer_correct_color);
        }
      }
    }
  }
}

@media screen and (max-width: $screen-720p) {
  .task-wrapper {
    height: inherit;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-top: 16px;

    .title {
      margin-top: 16px;
    }

    .text {
      margin-bottom: 0;
    }
  }

  .maze-container {
    height: 55vh;

    .maze-cards-container {
      .maze-cards {
        height: rem(300);
        width: rem(420);
      }
    }

    .maze-result-container {

    }
  }

  .word-container {
    margin-bottom: 20px;
  }

}
</style>
