export default class NetiquetteMessage {
  /**
   * @type {string} the message.
   */
  message;

  /**
   * @type {boolean} whether the message is from the user or not.
   */
  isMe = false;

  constructor(message, isMe) {
    this.message = message
    this.isMe = isMe
  }

  static parseFromDataObject(data) {
    if (data == null) {
      return ''
    }
    if (typeof data === 'string') {
      return new NetiquetteMessage(data, false)
    } else {
      return new NetiquetteMessage(data.message, data.isMe)
    }
  }
}
