import { SlideVideo } from '../slide/SlideVideo'

export default class VideoQuestionContent {
  /**
   * @type {SlideVideo}
   */
  video;

  constructor(video) {
    this.video = video
  }

  static parseFromDataObject(data) {
    return new VideoQuestionContent(
      SlideVideo.parseFromDataObject(data.video)
    )
  }
}
