<template>
  <div class="content-wrapper">
    <div class="error-content-container">
      <img :src="error.icon" alt="Error" class="error-icon">

      <h1 class="error-title">{{ error.title }}</h1>
      <p class="error-description">{{ error.description }}</p>
    </div>
    
    <div class="error-button-container">
      <button class="pink-button">{{ $t("ERROR.RELOAD") }}</button>
      <button class="white-button">{{ $t("ERROR.RETURN_HOMEPAGE") }}</button>
    </div>

  </div>
</template>

<script>
import { computed } from 'vue'
import errorImg from '@/assets/images/img_error.svg'; // Only being used temporarily

export default {
  name: 'Error',
  // components: {
  // },
  setup () {
    const error = computed(() => {
      // TODO: replace with getter, error will likely be kept in store
      return {
        errorType: 'EXAMPLE',
        title: 'Sorry, er ging iets mis',
        description: 'Probeer je internetverbinding te controleren of de pagina opnieuw te laden',
        icon: errorImg
      }
    })
    return {
      error
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

h1, p {
  text-align: center;
}

.content-wrapper {
  padding: rem(200) rem(42) rem(42);
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 0;
}


.error-icon {
  max-width: 300px;
  width: 100%;
}

.error-title {
  color:white;
  font-size: rem(31);
  margin-top: rem(48);
  margin-bottom: rem(16);
}

.error-description {
  color: white;
  opacity: 0.7;
  font-size: rem(16);
}

.error-content-container, .error-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: rem(70);
}

.error-button-container {
  gap: rem(16);
  
  button {
    width: 100%;
  }
}

@media screen and (max-width: 800px) {
  .content-wrapper {
    padding: rem(80) rem(16) rem(42);
  }
}

@media screen and (max-width: 500px) {
  .error-title {
    font-size: 26px;
    margin-top: 32px;
  }

  .content-wrapper {
    padding: rem(32) rem(16) rem(42);
  }
}
</style>
