<template>
  <div class="loading-screen">
    <img src="@/assets/icons/icn_logo.svg" alt="Logo" class="loading-icon">
    <div class="loading-bar">

      <!--    Loading Start    -->
      <svg :width="loadingIconsSizeInPixels + 'px'"
           :height="loadingIconsSizeInPixels +'px'"
           class="loading-start loading-start-filled"
           viewBox="0 0 68 68"
           version="1.1"
           xmlns="http://www.w3.org/2000/svg"
           xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>icn_loading_begin</title>
        <g id="Slices"
           stroke="none"
           stroke-width="1"
           fill="none"
           fill-rule="evenodd">
          <g id="icn_loading_begin" transform="translate(-27.000000, -176.000000)">
            <g transform="translate(29.000000, 178.000000)">
              <circle id="Oval"
                      stroke="#6DD9BB"
                      stroke-width="3"
                      cx="32"
                      cy="32"
                      r="32"></circle>
              <circle id="Oval"
                      fill="#6DD9BB"
                      cx="18"
                      cy="32"
                      r="6"></circle>
              <circle id="Oval-Copy-2"
                      fill="#6DD9BB"
                      cx="41"
                      cy="41"
                      r="9"></circle>
              <circle id="Oval-Copy"
                      fill="#6DD9BB"
                      cx="32"
                      cy="12"
                      r="6"></circle>
            </g>
          </g>
        </g>
      </svg>

      <!--    Loading Middle Points   -->
      <div v-for="n in points" :key="n" :class="{'loading-point-filled': n < counter}" class="loading-point loading-shadow-small" />

      <!--    Loading End    -->
      <svg :width="loadingIconsSizeInPixels + 'px'"
           :height="loadingIconsSizeInPixels +'px'"
           class="loading-end"
           :class="{'loading-end-filled': counter > points + 1}"
           viewBox="0 0 68 68"
           version="1.1"
           xmlns="http://www.w3.org/2000/svg"
           xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>icn_loading_end</title>
        <g id="Slices"
           stroke="none"
           stroke-width="1"
           fill="none"
           fill-rule="evenodd">
          <g id="Artboard" transform="translate(-109.000000, -176.000000)">
            <g id="icn_loading_end" :class="{'loading-end-filled': counter > points + 1}" transform="translate(111.000000, 178.000000)">
              <circle id="Oval"
                      stroke="#161337"
                      stroke-width="3"
                      cx="32"
                      cy="32"
                      r="32"></circle>
              <polyline id="Check"
                        stroke="#1A163A"
                        stroke-width="5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        points="18 36 27.9351967 45.1329335 45.9257779 21.6693406"></polyline>
            </g>
          </g>
        </g>
      </svg>
    </div>

    <!--    Loading Text    -->
    <div class="loading-text">
      {{ loadingTexts[text] }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loading',
  emits: ['loadingFinished'],
  data: () => ({
    points: 10,
    counter: 0,
    text: 0,
    loadingTexts: ['Kennis verzamelen op het internet ...'],
    loadingIconsSizeInPixels: 49,
    loadingBarSpeed: 26,
  }),
  created() {
    this.initIntervals()
  },
  methods: {
    initIntervals() {
      setInterval(() => {
        this.increaseCount()
      }, this.loadingBarSpeed)
      setInterval(() => {
        this.changeText()
      }, this.loadingBarSpeed)
    },

    /*
     * Temporary, will most likely use the progress of http request to calculate counter
     */
    increaseCount() {
      if (this.counter > this.points + 1) {
        this.$emit('loadingFinished')
      } else {
        this.counter++
      }
    },

    changeText() {
      if (this.text === this.loadingTexts.length - 1) {
        this.text = 0
      } else {
        this.text++
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "~@/assets/css/_base.variables.scss";
  @import "~@/assets/css/_base.mixins.scss";
  $filled-color: #6DD9BB;
  $not-filled-color: #1A163A;
  $margin-between-points: rem(7.5);
  $loading-point-size: rem(7);
  $shadow-outer: 18px;
  $shadow-inner: 15px;

  ///////////////////////////
  //      Loading bar      //
  ///////////////////////////
  .loading-screen {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
  }

  .loading-bar {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ////////////////////////////
  //      Loading icon      //
  ////////////////////////////
  .loading-icon {
    width: rem(210);
    height: auto;
    margin-bottom: rem(78.75);
  }

  //////////////////////////////
  //      Loading points      //
  //////////////////////////////
  .loading-point {
    width: $loading-point-size;
    height: $loading-point-size;
    border-radius: 50%;
    background-color: $not-filled-color;
    margin: 0 $margin-between-points;
  }

  .loading-point-filled {
    background-color: #6DD9BB;
    -webkit-box-shadow: 0 0 $shadow-outer 0 $filled-color;
    -moz-box-shadow: 0 0 $shadow-outer 0 $filled-color;
    -o-box-shadow: 0 0 $shadow-outer 0 $filled-color;
    box-shadow: 0 0 $shadow-outer 0 $filled-color;
  }

  ///////////////////////////////////
  //      Loading start & end      //
  ///////////////////////////////////
  .loading-point-big-shadow {
    border-radius: 50%;
    -webkit-box-shadow:
      0 0 $shadow-outer 0 $filled-color,
      inset 0 0 $shadow-inner 0 $filled-color;
    -moz-box-shadow:
      0 0 $shadow-outer 0 $filled-color,
      inset 0 0 $shadow-inner 0 $filled-color;
    -o-box-shadow:
      0 0 $shadow-outer 0 $filled-color,
      inset 0 0 $shadow-inner 0 $filled-color;
    box-shadow:
      0 0 $shadow-outer 0 $filled-color,
      inset 0 0 $shadow-inner 0 $filled-color;
  }

  .loading-start {
    margin-right: $margin-between-points;
  }

  .loading-end {
    margin-left: $margin-between-points;
  }

  g.loading-start-filled > * {
    stroke: $filled-color;
    fill: $filled-color;
  }

  g.loading-end-filled > * {
    stroke: $filled-color;
  }

  svg.loading-start-filled {
    @extend .loading-point-big-shadow;
  }

  svg.loading-end-filled {
    @extend .loading-point-big-shadow;
  }

  ///////////////////////////
  //      Loading text     //
  ///////////////////////////
  .loading-text {
    font-size: rem(16);
    margin-top: rem(21);
    opacity: 0.7;
  }
</style>
