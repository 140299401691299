import apiHandler from '@/utils/handlers/ApiHandler'
import {
  ADD_MEDIA_QUIZ_BITS,
  CLEAR_JOKER_USED,
  SET_AVAILABLE_JOKERS,
  SET_AWARDED_JOKER,
  SET_BONUS_EARNED,
  SET_FEEDBACK_MODAL,
  SET_HISTORY,
  SET_HISTORY_LOCATION,
  SET_JOKER_RESULT,
  SET_LOADING,
  SET_MISSION,
  SET_SESSION,
  SET_SESSION_IS_LOADING,
  SET_SLIDE_CONTENT,
  SET_STREAK,
  SET_SUBMISSION,
  SET_WEEK_STATUS,
} from './mutations'
import ApiService from '../api/apiService'
import HistoryLocation from '../models/mission/history/HistoryLocation'
import SubmissionResult from '../models/mission/submission/submissionResult'
import MissionSlide from '../models/mission/slide/MissionSlide'
import router from '@/router'

// mission
export const GET_SLIDE_CONTENT = 'getSlideContent'
export const GET_HISTORY = 'getHistory'
export const GET_SLIDE_CONTENT_BY_ID = 'getSlideContentById'
export const GET_SLIDE_CONTENT_SESSION = 'getSlideContentSession'
export const POST_SLIDE_ANSWER = 'postSlideAnswer'
export const POST_SUB_SLIDE_ANSWER = 'postSubSlideAnswer'
export const GET_SESSION = 'getSession'
export const GO_BACK_IN_HISTORY = 'goBackInHistory'
export const GO_FORWARD_IN_HISTORY = 'goForwardInHistory'
export const GO_FAST_FORWARD_IN_HISTORY = 'goFastForwardInHistory'
// media-quiz
export const GET_SLIDE_CONTENT_MEDIA_QUIZ = 'getSlideContentMediaQuiz'
export const POST_SLIDE_ANSWER_MEDIA_QUIZ = 'postSlideAnswerMediaQuiz'
export const ACTIVATE_JOKER = 'activateJoker'
export const GET_WEEK_STATUS = 'getWeekStatus'
export const SKIP_FEEDBACK = 'skipFeedback'

export const actions = {
  getSession({ commit, dispatch }, resetSession = false) {
    console.log('router', router)
    console.log(router.currentRoute)
    console.log(router.currentRoute.value.params)
    const missionId = router.currentRoute.value.params.id
    const classId = router.currentRoute.value.params.classId

    const formData = new FormData()

    formData.append('missionId', missionId)
    formData.append('schoolClassId', classId)

    if (resetSession) {
      commit(SET_SESSION_IS_LOADING, true)
    }
    // console.log('getSession formData', formData)
    return apiHandler.api.post('/game/mission/get-mission-session', formData).then(response => {
      if (resetSession) {
        dispatch('showLoading')
      }

      commit(SET_SESSION, response.data.missionSession, resetSession)
      commit(SET_MISSION, response.data.mission)
      commit(SET_AVAILABLE_JOKERS, response.data.availableJokers)
      if (resetSession) {
        commit(SET_SESSION_IS_LOADING, false)
      }
      // console.log('session', response.data.missionSession)
      return response.data
    }).catch(error => {
      console.log(error.response)
      return error.response.data
    })
  },
  async getHistory({ commit, getters }, ignoreCache = false) {
    //check the current store state if the history is already ok for the current slide.
    //if so, do not call the api again.
    //if not, call the api and update the store state.

    const historyIsValid = getters.getHistoryIsValid
    if (historyIsValid && !ignoreCache) {
      return getters.getHistory
    }
    commit(SET_HISTORY, null)
    const newHistory = await ApiService.getHistory(getters.getSession.id)
    commit(SET_HISTORY, newHistory)
    return newHistory
  },
  async goBackInHistory({ commit, getters, dispatch }) {
    const history = await dispatch('getHistory')

    const currentModalState = getters.getShowFeedbackModal //check if we are already on the feedback page.

    dispatch('showLoading')
    console.log('we got here', history)

    /** @type {HistoryLocation|null} **/
    const historyLocation = getters.getHistoryLocation

    //if already at current place, then do nothing.
    if (historyLocation == null) {
      const submission = getters.getSubmission
      const isShowingFeedback = getters.getShowFeedbackModal
      const newLocation = HistoryLocation.createFromSessionHistory(history, submission, isShowingFeedback)

      if (currentModalState == true) {
        newLocation.isPostAnswer = false
      }
      //newLocation = newLocation.goBackward(history)
      commit(SET_HISTORY_LOCATION, newLocation)
      commit(SET_SUBMISSION, null)
      commit(SET_FEEDBACK_MODAL, false)
      return newLocation
    }

    const newLocation = historyLocation.goBackward(history)
    commit(SET_HISTORY_LOCATION, newLocation)
    commit(SET_SUBMISSION, null)
    commit(SET_FEEDBACK_MODAL, false)
    return newLocation
  },
  async goForwardInHistory({ commit, getters, dispatch }) {
    const history = await dispatch('getHistory')
    dispatch('showLoading')
    /** @type {HistoryLocation|null} **/
    const historyLocation = getters.getHistoryLocation

    commit(SET_FEEDBACK_MODAL, false)

    //if already at current place, then do nothing.
    if (historyLocation == null) {
      return
    }

    const newLocation = historyLocation.goForward(history)
    commit(SET_HISTORY_LOCATION, newLocation)
    if (newLocation == null) {
    }
  },
  goFastForwardInHistory({ commit, getters, dispatch }) {
    commit(SET_FEEDBACK_MODAL, false)

    dispatch('showLoading')
    commit(SET_HISTORY_LOCATION, null)
  },
  getSlideContentSession({ commit }, sessionID) {
    ApiService.getHistory(sessionID).then(response => {
      console.log(response)
    })

    return apiHandler.api.get(`/game/mission/get-next-mission-slide-question/${sessionID}`)
      .then(response => {
        commit(SET_SLIDE_CONTENT, response.data)
        return response.data
      })
  },
  getSlideContentById({ commit }, slideId) {
    console.log('getSlideContentById', slideId)
    ApiService.getSlideContentById(slideId).then(response => {
      commit(SET_SLIDE_CONTENT, response)
    })
  },
  getSlideContent({ commit }, data) {
    return ApiService.getSlideContentByMissionAndSlide(data.mission, data.slide).then(response => {
      commit(SET_SLIDE_CONTENT, response)
      console.log('got response', response)
      return response
    })

    /*    return apiHandler.api.get(`/game/mission/${data.mission}/slide/${data.slide}`)
          .then(response => {
            commit(SET_SLIDE_CONTENT, response.data)
            return response.data
          })*/
  },
  activateJoker({ commit }, formData) {
    return apiHandler.api.post('/game/mission/apply-joker', formData).then(response => {
      // console.log('joker-activated', response)
      commit(SET_JOKER_RESULT, response.data)
      return response.data
    }).catch(error => {
      console.log(error.response)
    })
  },
  postSlideAnswer({ commit, getters, dispatch }, formData) {
    commit(SET_HISTORY, null)
    commit(SET_HISTORY_LOCATION, null)

    const session = getters.getSession
    const startTime = getters.getSlideStartTime
    const jokerUsed = getters.getJokerUsed
    const timeTaken = Date.now() - startTime
    const slideId = session?.missionSlideId ?? getters.getSlideContent.missionSlideId
    // console.log('timeTaken', timeTaken)
    if (timeTaken) {
      formData.append('timeTaken', timeTaken)
    } else {
      formData.append('timeTaken', 1)
    }

    let url = '/game/mission/post-slide-answer'
    if (session && session.id !== -1) {
      formData.append('sessionId', session.id)
      formData.append('slideId', slideId)
      formData.delete('missionId')
      //formData.delete('slideIndex')
      if (jokerUsed) {
        formData.append('schoolClassJokerIdUsed', jokerUsed)
      }
      url += '/' + session.id
    }
    return apiHandler.api.post(url, formData)
      .then(response => {
        const submissionResult = SubmissionResult.parseFromDataObject(response.data)
        commit(CLEAR_JOKER_USED)
        // console.log('postSlideAnswer', response)
        if (response.data.awardedJoker) {
          commit(SET_AWARDED_JOKER, response.data.awardedJoker)
        }
        if (response.data.bonus) {
          commit(SET_BONUS_EARNED, response.data.bonus)
        }
        if (session) {
          submissionResult.setSlideId(session.currentMissionSlideId)
        } else {
          submissionResult.setSlideId(getters.getSlideContent.missionSlideId)
        }
        commit(SET_SUBMISSION, submissionResult)
        dispatch('getSession')
        return response.data
      }).catch(error => {
        console.log(error.response)
      })
  },
  postSubSlideAnswer({ commit, getters, dispatch }, formData) {
    const session = getters.getSession
    let url = '/game/mission/post-sub-slide-answer'
    const startTime = getters.getSlideStartTime
    const timeTaken = Date.now() - startTime
    const jokerUsed = getters.getJokerUsed
    const slideId = session?.missionSlideId ?? getters.getSlideContent.missionSlideId

    // console.log('timeTaken', timeTaken)
    formData.append('timeTaken', timeTaken)
    if (session && session.id !== -1) {
      formData.append('sessionId', session.id)
      formData.append('slideId', slideId)

      formData.delete('missionId')
      formData.delete('slideIndex')
      if (jokerUsed) {
        formData.append('schoolClassJokerIdUsed', jokerUsed)
      }
      url += '/' + session.id
    }
    return apiHandler.api.post(url, formData)
      .then(response => {
        const submissionResult = SubmissionResult.parseFromDataObject(response.data)
        commit(SET_SUBMISSION, submissionResult)

        commit(CLEAR_JOKER_USED)
        // console.log('CLEAR_JOKER_USED res', response)
        if (response.data.awardedJoker) {
          commit(SET_AWARDED_JOKER, response.data.awardedJoker)
        }
        if (response.data.bonus) {
          commit(SET_BONUS_EARNED, response.data.bonus)
        }
        dispatch('getSession')
        return response.data
      }).catch(error => {
        console.log(error.response)
      })
  },
  getSlideContentMediaQuiz({ commit }, payload) {
    // if mediaQuizSlideId === null, a random slide will be fetched
    const formData = new FormData()
    for (let i = 0; i < payload.previouslyAnsweredSlides.length; i++) {
      formData.append(`previouslyAnsweredSlides[${i}]`, payload.previouslyAnsweredSlides[i])
    }
    if (payload.schoolClassId) {
      formData.append('schoolClassId', payload.schoolClassId)
    }

    let url = '/game/media-quiz'
    if (payload.questionId) url += ('/' + payload.questionId)
    const call = payload.questionId ? apiHandler.api.get : apiHandler.api.post
    return call(url, formData)
      .then(response => {
        const missionSlide = MissionSlide.parseFromDataObject(response.data)
        commit(SET_SLIDE_CONTENT, missionSlide)

        return missionSlide
      })
  },
  postSlideAnswerMediaQuiz({ commit, getters }, formData) {
    formData.append('currentStreak', getters.getStreak)
    return apiHandler.api.post('/game/media-quiz/post-slide-answer', formData)
      .then(response => {
        const submissionResult = SubmissionResult.parseFromDataObject(response.data)
        commit(SET_SUBMISSION, submissionResult)
        commit(ADD_MEDIA_QUIZ_BITS, submissionResult.awardedBits)
        commit(SET_STREAK, response.data.currentStreak)
        return submissionResult
      })
  },
  getWeekStatus({ commit }) {
    return apiHandler.api.get('/global-settings/get-week-data').then(response => {
      commit(SET_WEEK_STATUS, response.data)
      return response.data
    })
  },

  async getSlideContentFromCurrentHistoryPosition({ commit, getters, dispatch }) {
    console.log('getSlideContentFromCurrentHistoryPosition')
    /** @type {SessionHistory|null} **/
    const history = await dispatch('getHistory')
    /** @type {HistoryLocation|null} **/
    const historyLocation = getters.getHistoryLocation

    if (historyLocation == null) {
      if (history != null) {
        const currentSlideId = history.currentSlideId
        dispatch('getSlideContentById', currentSlideId)
      }

      return
    }

    const currentSlideId = historyLocation.getCurrentSlideId(history)

    dispatch('getSlideContentById', currentSlideId)
  },
  skipFeedback({ commit, getters, dispatch }) {
    const isGoingForward = getters.getHistoryLocation?.isGoingForward
    dispatch('showLoading')
    if (isGoingForward) {
      dispatch('goForwardInHistory')
    } else {
      dispatch('goBackInHistory')
    }
  },
  async showLoading({ commit, getters, dispatch }, timeInMs = 300) {
    console.log('showLoading')
    commit(SET_LOADING, true)
    await new Promise(resolve => setTimeout(resolve, timeInMs))
    commit(SET_LOADING, false)
  },
  showFeedbackModal({ commit, getters, dispatch }) {
    commit(SET_FEEDBACK_MODAL, true)
  },
  hideFeedbackModal({ commit, getters, dispatch }) {
    commit(SET_FEEDBACK_MODAL, false)
  }
}
