<template>
  <Modal class="no-extra-games-modal-wrapper" :is-shown="isShown">
    <template #content>
      <img class="no-extra-games-image" src="@/assets/images/ic_laptop@2x.png" alt="">
      <p class="text">{{ $t('EXTRA_GAMES.NO_GAMES_ON_MOBILE') }}</p>
      <button class="pink-button" @click="$emit('onClose')">{{ $t('SHARED.OK') }}</button>
    </template>
  </Modal>
</template>

<script>
import Modal from './Modal'

export default {
  name: 'NoExtraGamesModal',
  components: {
    Modal
  },
  props: {
    isShown: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'onClose'
  ]
}
</script>

<style lang="scss" scoped>
.no-extra-games-modal-wrapper {
  img {
    width: 100%;
  }

  .text {
    color: white;
    margin-bottom: 2rem;
  }

  button {
    width: 100%;
  }
}

// landscape mobile
@media (orientation: landscape) and (max-height: 500px) {
  :deep(.modal-wrapper) {
    width: 95%;
    height: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .no-extra-games-modal-wrapper {
    .no-extra-games-image {
      height: unset;
      width: 35%;
    }
  }
}
</style>
