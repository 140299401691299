<template>
  <Modal :is-shown="isShown">
    <template #content>
      <h1 class="modal-title">{{ modalTitle }}</h1>

      <div v-if="showBits" class="modal-bits-earned">
        <p>Aantal verdiende & opgeslagen bits:</p>
        <div class="modal-bit-box">
          <img class="bits-icon" src="@/assets/icons/icn_bits.svg">
          <p class="bits">{{ bits }}</p>
        </div>
      </div>

      <div class="button-container">
        <button class="button-main pink-button button-with-arrow" @click="$emit('dismiss')">
          <span>{{ $t("PAUSE.CONTINUE") }}</span>

          <img class="arrow" src="@/assets/icons/play_arrow.svg">
        </button>
        <button class="white-button" @click="$emit('leave')">{{ $t("PAUSE.BACK") }}</button>
      </div>
    </template>
  </Modal>
</template>

<script>

import Modal from '@/components/partials/modal/Modal'
import BitsDisplay from '@/components/elements/basic/BitsDisplay'

export default {
  name: 'FeedbackModal',
  components: {
    Modal,
    BitsDisplay,
  },
  props: {
    modalTitle: {
      type: String,
      required: true
    },
    bits: {
      type: [String, Number],
      required: true
    },
    showBits: {
      type: Boolean,
      required: false,
      default: false
    },
    isShown: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  emits: ['leave', 'dismiss'],
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.modal-title, .modal-text {
  color: white;
}

.modal-title {
  font-size: rem(32);
  margin-bottom: rem(25);
}

.modal-text {
  font-size: rem(24);
  margin-bottom: rem(50);
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.button-with-arrow {
  justify-content: center;
  position: relative;
  padding: 16px;
  width: 324px;
}

.white-button {
  box-shadow: 4px 4px 0 0 rgba(0,0,0,0.1);
  width: rem(324);
  font-weight: 600;
}

.modal-bits-earned {
  color: #FCFCFC;
}

.modal-bit-box {
  margin: rem(10) auto rem(27);
  width: rem(216);
  height: rem(63);
  border-radius: rem(12);
  background-color: #4D4C89;
  text-align: center;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.bits-icon {
  //height: rem(56);
  //width: rem(56);
  position: absolute;
  left: rem(12);
  bottom: rem(7);
}

.bits {
  color: #FEEAA7;
  font-size: rem(31);
  font-weight: bold;
  letter-spacing: 0;
  line-height: rem(36);
  text-shadow: 0 4px #312C64;
}

.modal-text{
  align-self: center;
  margin: rem(13) rem(61) rem(13) 0;
  min-height: rem(36);
  min-width: rem(84);
  text-align: center;
  font-size: rem(31);
  font-weight: bold;
  letter-spacing: 0;
  line-height: rem(36);
}

.arrow{
  position: absolute;
  width: rem(40);
  height: rem(40);
  right: 20px;
}
</style>
