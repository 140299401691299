class MathUtils {
  /**
   * Clamps the x to be between a and b.
   *
   * @param value Value to be clamped.
   * @param min Minimum value
   * @param max Maximum value.
   */
  static clamp(value, min, max) {
    return Math.max(Math.min(value, max), min)
  }
}

export default MathUtils