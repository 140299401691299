<template>
  <div>

    <div class="progress-bar-wrapper" :style="cssVars">
      <div class="progress-bar-filled"/>
      <div class="markers">
        <div v-for="index in markerCount" :key="index">
          <div class="marker"/>
        </div>
      </div>
    </div>
    <div v-if="bitCount || bitCount === 0" class="lower-text"> {{ bitCount + ' / ' + maximumBits + " " + $t("SHARED.BITS_EARNED") }}</div>
  </div>
</template>

<script>

export default {
  name: 'BitsBar',
  props: {
    percentage: {
      type: Number,
      default: 0
    },
    animate: {
      type: Boolean,
      default: true
    },
    bitCount: {
      type: Number,
      default: null
    },
    maximumBits: {
      type: Number,
      default: 100
    }
  },
  data() {
    const displayValue = 0
    const markerCount = 8
    return {
      markerCount,
      displayValue
    }
  },
  computed: {
    cssVars() {
      return {
        '--progress': ((this.displayValue) * 100) + '%'
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.displayValue = this.percentage
    }, 1)
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.progress-bar-wrapper {
  border-radius: rem(30);
  background: #2D295D;
  height: rem(20);
  // max-width: rem(400);
  // width: rem(400);
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
}

//the + 10% move is so it doesn't "squish" at low percentages
.progress-bar-filled {
  overflow: hidden;
  position: absolute;
  height: 100%;
  left: -10%;
  width: calc(var(--progress, 0) + 10%);
  transition: width 2s ease-in-out;
  border-radius: inherit;
  background: linear-gradient(180deg, #FF9E78 0%, #FFE597 26.81%, #FCF6E5 38.88%, #FEE193 48.47%, #F0A460 84.04%, #FFE597 100%);
}

.markers {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.marker {
  width: 3px;
  height: 100%;
  background-color: rgba(#615C9F, 0.3);
}

.lower-text {
  color: white;
  margin-top: rem(10);
  margin-left: rem(4);
  font-weight: 500;
}

@media screen and (max-width: 1100px) {
  .progress-bar-wrapper {
    // max-width: rem(250);
    max-width: 100%;
  }
}

// @media screen and (max-width: 600px) {
//   .progress-bar-wrapper {
//     max-width: 40vw;
//   }
// }
</style>
