import MultipleChoiceQuestionContentAnswer from './MultipleChoiceQuestionContentAnswer'
import FeedbackResult from '../submission/feedbackResult'
import { alphabet } from '../../../utils/data/alphabet'
import i18n from '@/setup/i18n/i18n'

export default class MultipleChoiceQuestionContent {
  /**
   * The question of the slide.
   * @type {string|null}
   */
  question = null;

  /**
   * @type {MultipleChoiceQuestionContentAnswer[]}
   */
  answers = [];

  constructor({ question, answers, statement }) {
    this.answers = answers
    this.question = question
    this.statement = statement
  }

  static parseFromDataObject(data) {
    return new MultipleChoiceQuestionContent({
      question: data.question,
      answers: data.answers.map(answer => MultipleChoiceQuestionContentAnswer.parseFromDataObject(answer)).sort((a, b) => Math.random() - 0.5),
      statement: data.statement
    })
  }

  getFeedbackResult(userAnswer, correctAnswer) {
    const isMultipleChoice = correctAnswer.length > 1

    const answer = ''

    if (userAnswer === correctAnswer) {
      return FeedbackResult.GoodJobFeedbackResult
    }
  }

  getSingleChoiceFeedbackResult(userAnswer, correctAnswer) {
    const answer = ''

    const isCorrect = userAnswer === correctAnswer

    if (isCorrect) {
      return FeedbackResult.GoodJobFeedbackResult
    } else {
      const correctAnswerIndex = this.answers.findIndex((answer) => answer.id === correctAnswer).id
      const correctLetter = alphabet[correctAnswer]
      return new FeedbackResult(0, i18n.global.tc('FOOTER.SINGLE_ANSWER.FEEDBACK_TITLE'), correctLetter)
    }
  }

  getMultipleChoiceFeedbackResult(userAnswer, correctAnswer) {
    const answer = ''

    const isCorrect = userAnswer.length == correctAnswer.length && userAnswer.every((answer) => correctAnswer.includes(answer))

    if (isCorrect) {
      return FeedbackResult.GoodJobFeedbackResult
    } else {
      return FeedbackResult
    }
  }
}
