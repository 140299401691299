<template>
  <Modal :is-shown="isShown">
    <template #content class="details">
      <div class="details">
        <div class="detail-image-border">
          <div class="detail-image-background">
            <InlineSvg v-if="useInlineSvg" :src="imageSrc" class="detail-image"/>
            <img v-else :src="imageSrc" class="detail-image"/>
          </div>
        </div>
        <div class="detail-information">
          <h2 class="title">{{ title }}</h2>
          <div class="preview-image-wrapper">
            <InlineSvg v-if="useInlineSvg" :src="imageSrc" class="preview-image"/>
            <img v-else :src="imageSrc" class="preview-image"/>
          </div>
          <p class="description" v-html="description"></p>
          <div class="info-wrapper">
            <div v-if="onWeek && timesPlayed !== null" class="amount-played-wrapper">
              <img alt="" class="controller-icon" src="../../../assets/icons/joystick.svg">
              <div class="amount-played">
                <span class="amount-played-highlight">{{ timesPlayed }} keer gespeeld</span> door
                je klasgenoten
              </div>
            </div>

            <div v-if="bitsEarned !== null && onWeek" class="bits-earned">
              <img alt="" class="bits-icon" src="../../../assets/icons/icn_bits_white.svg">
              <span class="bits-earned-text"><b>{{ bitsEarned }} bits</b> verdiend in dit spel</span>
            </div>

            <div v-if="maximumBitsReached && onWeek" class="max-bits-reached">
              <img alt="" class="bits-icon" src="../../../assets/icons/icn_bits_white.svg">
              <span class="max-bits-reached-text">Maximaal aantal bits voor vandaag behaald!</span>
            </div>

            <div v-if="!isLoggedIn" class="amount-played-wrapper">
              <img class="error-icon" src="../../../assets/icons/icn_error.svg">
              <span class="bits-earned-text"><b> Log in met je klassennaam</b> {{ ' om bits te verdienen' }}</span>
            </div>

          </div>
          <div v-if="!isLoggedIn">
            <input id="login-class-name-input"
                   v-model="className"
                   :class="apiError ? 'input-error' : ''"
                   :placeholder="$t('LOGIN.PLACEHOLDER')"
                   class="login-input"
                   type="text"
            >
            <div v-if="apiError" class="error-flash-block">
              Er is geen klas met deze naam gevonden.
            </div>
          </div>
          <!--
                    <router-link v-if="route" :to="route" class="start-game-button-wrapper" style="text-decoration: none">
          -->
          <!--          <button class="base-button start-game" @click="onButtonPress">
                      <span>Start het spel</span>
                      <BitsReward v-if="rewardAmount !== null && onWeek" :bits-amount="rewardAmount"/>
                    </button>-->
          <!--
                    </router-link>
          -->

          <button class="base-button start-game" @click="onButtonPress">
            <span>Start het spel</span>
            <BitsReward v-if="rewardAmount !== null && onWeek" :bits-amount="rewardAmount"/>
          </button>
        </div>
        <div class="close-button">
          <button @click="closeButtonPressed">
            <InlineSvg :src="require('../../../assets/icons/icn_cross.svg')"/>
          </button>
        </div>
      </div>
      <PlayWithoutBitsModal v-if="showPlayWithoutBitsModal" :continue-callback="onContinue">

      </PlayWithoutBitsModal>
    </template>
  </Modal>
</template>

<script>
import Modal from './Modal'
import InlineSvg from 'vue-inline-svg'
import BitsReward from '../../elements/basic/BitsReward'
import ApiHandler from '../../../utils/handlers/ApiHandler'
import PlayWithoutBitsModal from './PlayWithoutBitsModal'

export default {
  name: 'GameDetailsModal',
  components: {
    PlayWithoutBitsModal,
    Modal,
    InlineSvg,
    BitsReward
  },
  props: {
    gameId: {
      type: Number,
      required: true
    },
    isShown: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true,
    },
    timesPlayed: {
      type: Number,
      required: true
    },
    bitsEarned: {
      type: Number,
      required: false,
      default: null
    },
    maximumBitsReached: {
      type: Boolean,
      required: false,
      default: false
    },
    rewardAmount: {
      type: Number,
      required: true
    },
    imageSrc: {
      type: String,
      required: true
    },
    useInlineSvg: {
      type: Boolean,
      required: false,
      default: false
    },
    startButtonPressed: {
      type: Function,
      required: false,
      default: () => {
      }
    },
    closeButtonPressed: {
      type: Function,
      required: false,
      default: () => {
      }
    },
    route: {
      type: String,
      required: false,
      default: undefined
    },
    onWeek: {
      type: Boolean,
      required: true
    },
    isLoggedIn: {
      type: Boolean,
      required: true
    },
  },
  emits: ['showClassLevelWarning', 'close'],
  data: () => ({
    className: '',
    classId: null,
    apiError: false,
    showPlayWithoutBitsModal: false,
  }),
  beforeUnmount() {
    const mainScrollBody = document.getElementById('main-content')
    if (mainScrollBody) {
      mainScrollBody.style.overflow = 'auto'
    }
  },
  methods: {
    async onButtonPress() {
      if (this.isLoggedIn) {
        this.onContinue(true)
        return
      }

      if (!this.className || this.className.trim().length === 0) {
        //TODO show a popup instead
        this.showPlayWithoutBitsModal = true
        //this.onContinue()
        return
      }

      this.loginClass()
      //else try and login and watch the result
    },
    loginClass() {
      // this.apiError = false
      const formData = new FormData()
      formData.append('className', this.className)
      ApiHandler.api.post('/auth/login-extra-game-class', formData)
        .then((res) => {
          localStorage.setItem('schoolClass', JSON.stringify(res.data))
          this.apiError = false

          this.classId = res.data.schoolClass.id
          const hasGame = res.data.extraGames.content?.some((game) => game.id === this.gameId)
          this.onContinue(hasGame)
        })
        .catch((error) => {
          this.apiError = true
          console.log('error', error)
        })
    },
    onContinue(hasGame) {
      if (!hasGame) {
        this.$emit('showClassLevelWarning', this.classId)
        this.showPlayWithoutBitsModal = false
        this.$emit('close')
      } else if (this.route) {
        this.$router.push(this.route)
      } else {
        this.startButtonPressed()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

h2, p, button {
  color: white;
  text-align: left;
}

p {
  font-size: rem(16);
}

:deep(.modal-wrapper) {
  padding: 0;
  margin-left: rem(320);
}

.details {
  width: rem(685);
  position: relative;
}

.detail-image-border {
  position: absolute;
  width: rem(420);
  height: rem(590);
  background: linear-gradient(180deg, #F5F5FF 0%, #CCBCDF 100%);
  transform: translate(-50%, -50%);
  top: 50%;
  right: calc(50% + 30px);
  clip-path: polygon(50% 0, 100% 20%, 100% 80%, 50% 100%, 0 80%, 0 20%);

  .detail-image-background {
    position: absolute;
    width: 90%;
    height: 91%;
    transform: translate(50%, -50%);
    top: 50%;
    right: 50%;
    clip-path: polygon(50% 0, 100% 20%, 100% 80%, 50% 100%, 0 80%, 0 20%);
    background: radial-gradient(circle, #817899 0%, #60499A 75%);

    .detail-image {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      transform: translate(50%, -50%);
      top: 50%;
      right: 50%;
      clip-path: polygon(50% 0, 100% 20%, 100% 80%, 50% 100%, 0 80%, 0 20%);
      object-fit: cover;
      object-position: center;

      svg {
        background: red;
      }
    }
  }
}

.login-input {
  height: 50px;
  width: 100%;
  border-radius: 8px;
  background-color: #FEFEFE;
  box-shadow: 2px 2px 0 0 rgba(8, 13, 43, 0.2);
  padding: 16px;
  outline: none;
  font-family: 'Rubik', sans-serif;
  color: #373B52;
  text-align: center;
  font-size: 16px;
  border: none;
}

.login-input::placeholder {
  color: var(--gray_dark);
}

.error-flash-block {
  height: 44px;
  width: 100%;
  border-radius: 8px;
  background-color: rgba(217, 82, 107, 0.2);
  color: white;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-error {
  border: 3px solid #E64D4D;
}

.detail-information {
  padding: rem(28.5) rem(60) rem(37.5) rem(143);
  display: flex;
  flex-direction: column;
  gap: rem(25);

  .preview-image-wrapper {
    display: none;
  }

  .info-wrapper {
    display: flex;
    flex-direction: column;
    gap: rem(12);

    .amount-played-wrapper, .bits-earned {
      display: flex;
      align-items: center;
      padding: rem(10) rem(17);
      background-color: #4D4C89;
      border-radius: 20px;
      //width: fit-content;
      color: white;
      text-align: left;

      .amount-played-highlight {
        font-weight: 600;
      }

      .controller-icon {
        margin-right: 10px;
        width: 32px;
      }
    }

    .max-bits-reached {
      display: flex;
      align-items: center;
      padding: rem(6) rem(17);
      background-color: #00B5AA;
      border-radius: 20px;
      color: white;
      text-align: left;

      .max-bits-reached-text {
        font-weight: 500;
      }

      .bits-icon {
        margin-right: 10px;
        width: 32px;
      }

    }
  }

  .bits-earned .bits-icon {
    height: rem(32);
    width: rem(32);
    margin-right: rem(12);
  }

  .start-game {
    background-color: var(--primary_color);
    height: rem(59);
    width: 100%;
    display: flex;
    align-items: center;
    padding-right: rem(6.5);

    span {
      text-align: center;
      flex-grow: 1;
    }
  }
}

.close-button {
  position: absolute;
  width: rem(43);
  height: rem(43);
  background-color: #4D4C89;
  border-radius: rem(8);
  top: rem(16.5);
  right: rem(16.5);

  button {
    background: none;
    border: none;
    padding: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;

    svg {
      width: 100%;
      height: 100%;
      padding: rem(10);
    }

    :deep(path) {
      fill: white;
    }
  }
}

@media screen and (max-width: 800px) {
  :deep(.modal-wrapper) {
    width: 97%;
    max-height: 97%;
    height: 97%;
    margin-left: 0;
    max-width: unset;
    overflow-y: auto;
    padding: 0 0 10px;
  }

  .details {
    display: flex;
    width: 100%;
    position: static;
    max-height: unset;
    height: 100%;
    min-width: rem(300);
  }

  .detail-information {
    padding: rem(16) rem(16);

    .preview-image-wrapper {
      display: block;
      width: 80%;
      height: auto;
      max-height: rem(350);
      margin: 0 auto;
      padding: rem(8);
      background: linear-gradient(180deg, #F5F5FF 0%, #CCBCDF 100%);
      border-radius: rem(14);

      .preview-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: rem(12);
      }
    }

    .title {
      font-size: 20px;
      line-height: 28px;
      width: calc(100% - 50px);
    }

    .info-wrapper {
      .amount-played-wrapper, .max-bits-reached {
        padding: rem(6) rem(12);
        width: 100%;
        font-size: rem(14);
      }
    }

    .start-game {
      align-items: center;
      padding: 0 rem(6.5);
      margin-bottom: 10px;

      span {
        text-align: center;
        flex-grow: 1;
      }
    }
  }
}

// landscape mobile
@media (orientation: landscape) and (max-height: 500px) {
  $view-width-space-image: 30vw;
  :deep(.modal-wrapper) {
    width: 97%;
    height: 95%;
    margin-left: 0;
    max-width: unset;
  }
  .details {
    display: flex;
    width: 100%;
    position: static;
    max-height: unset;
    height: 100%;

    .detail-image-border {
      height: 100%;
      width: $view-width-space-image;
      right: 0;
      left: calc(#{$view-width-space-image} / 2);

      .detail-image-background {
        position: relative;
        width: 100%;
        height: 100%;

        .detail-image {
          width: 100%;
          height: inherit;
        }
      }
    }

    .description {
      font-size: 14px;
      line-height: 26px;
    }

    .detail-information {
      padding: 20px 16px 16px;
      margin-left: $view-width-space-image;
      gap: 16px;

      .start-game {
        height: 45px;
      }

      .info-wrapper {
        flex-direction: row;
        flex-wrap: nowrap;
      }

      .preview-image-wrapper {
        display: none;
      }
    }
  }
  .start-game-button-wrapper {
    margin-top: auto;
  }
}

.error-icon {
  margin-right: rem(10);
}

</style>
