import apiHandler from '../utils/handlers/ApiHandler'
import GetMissionSessionResponse from './apiResponses/getMissionSessionResponse'
import MissionSlide from '../models/mission/slide/MissionSlide'
import SessionHistory from '../models/mission/history/SessionHistory'
import SlideContentCache from './slideContentCache'

export default class ApiService {
  static slideContentCache = new SlideContentCache();

  /**
   * Get the current mission session
   * @param getSessionRequest {GetMissionSessionRequest}
   * @return {Promise<GetMissionSessionResponse>}
   */
  static async getSession(getSessionRequest) {
    const response = await apiHandler.api.post('/game/mission/get-mission-session', getSessionRequest.toFormData())
    return GetMissionSessionResponse.parseFromDataObject(response.data)
  }

  /**
   * Get a slide from the id of the mission.
   * @param {number} slideId the id of the slide
   * @return {Promise<MissionSlide>} the returned slide.
   */
  static async getSlideContentById(slideId) {
    const cacheResponse = this.slideContentCache.getFromCache(slideId)
    if (cacheResponse) {
      return cacheResponse
    }

    const response = await apiHandler.api.get(`/game/mission/slide/${slideId}`)
    const slide = MissionSlide.parseFromDataObject(response.data)
    this.slideContentCache.addToCache(slide)
    return slide
  }

  static async getSlideContentByMissionAndSlide(missionId, slideIndex) {
    const cacheResponse = this.slideContentCache.getFromCacheUsingMissionIdAndSlideIndex(missionId, slideIndex)
    if (cacheResponse) {
      return cacheResponse
    }

    const response = await apiHandler.api.get(`/game/mission/${missionId}/slide/${slideIndex}`)
    const slide = MissionSlide.parseFromDataObject(response.data)
    this.slideContentCache.addToCache(slide)
    return slide
  }

  /**
   * Get the sessions history.
   * @param sessionId the id of the current missions session.
   * @return {Promise<SessionHistory>} The history of the current session.
   */
  static async getHistory(sessionId) {
    const response = await apiHandler.api.get(`/game/mission/get-session-history/${sessionId}`)
    return SessionHistory.parseFromDataObject(response.data)
  }

  static async submitAnswer(formData) {

  }
}
