<template>
  <div class="joker-wrapper">
    <h3 class="joker-earned-title">Verdiende joker:</h3>
    <img :src="joker.jokerType.icon" clas="joker-icon"/>
    <h2 class="joker-title">{{ joker.jokerType.name }}</h2>
    <p class="joker-description">{{ joker.jokerType.description }}</p>
  </div>
</template>

<script>
export default {
  name: 'JokerCard',
  props: {
    joker: {
      type: Object,
      required: true,
    }
  },
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";
@import "~@/assets/css/global.scss";
@import "~@/assets/css/mission.scss";

.joker-wrapper {
  padding: rem(40);
  background-color: #4D4C89;
  border-radius: rem(20);
  width: rem(695);

  .joker-earned-title {
    color: #FFFFFF;
    font-size: 24px;
    font-weight: bold;
    line-height: 32px;
    text-align: center;
    margin-bottom: 32px;
  }

  .joker-icon {
    margin-bottom: 32px;
  }

  .joker-title {
    color: #FFFFFF;
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 36px;
    text-align: center;
  }

  .joker-description {
    margin-top: 20px;
    color: #FFFFFF;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 38px;
    text-align: center;
  }
}
</style>
