<template>
  <div id="header-wrapper" class="header-wrapper">
    <!-- Top header -->
    <div class="header-top">
      <button v-if="onWeek"
              class="finish-playing-button white-button"
              @click="handleEndGame"
      >
        Klaar met spelen en bits cashen
      </button>
      <BitsDisplay v-if="onWeek"
                   :amount="bits"
                   :large="false"
                   :popup-on-increase="true"
                   :show-double="displayDouble"/>
    </div>

    <!-- Main header -->
    <div class="header-box">
      <div class="title">Streak</div>

      <!-- Streak slide -->
      <div class="streak-container">
        <div v-for="streak in 3">
          <div class="streak-box">
            <!-- Streak circle -->
            <div class="streak-circle">
              <div
                :class="{'streak-circle-colored-invisible' : currentStreak < streak}"
                class="streak-circle-colored"
              ></div>
              <div class="streak-mark"></div>
            </div>
            <!-- Streak Line -->
            <div v-if="streak < 3" class="streak-line">
              <transition name="scale">
                <div
                  v-show="currentStreak > streak"
                  class="streak-line-colored"
                ></div>
              </transition>
            </div>
          </div>
        </div>
      </div>
      <!-- End of streak slide -->
    </div>
  </div>
</template>

<script>
import BitsDisplay from '@/components/elements/basic/BitsDisplay'
import { mapGetters } from 'vuex'

export default {
  name: 'QuizHeader',
  components: {
    BitsDisplay,
  },
  emits: ['end-game'],
  computed: {
    ...mapGetters({
      currentStreak: 'getStreak',
      bits: 'getMediaQuizBits',
      weekStatus: 'getWeekStatus',
    }),
    onWeek() {
      return this.weekStatus?.status === 'active'
    },
  },
  methods: {
    handleEndGame() {
      this.$emit('end-game')
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.scale-enter-from,
.scale-leave-to {
  opacity: 0;
}

.scale-enter-to,
.scale-leave-from {
  opacity: 1;
}

.scale-enter-active,
.scale-leave-active {
  transition: all 0.3s ease;
}

.header-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: auto;
  width: 100%;
  padding: rem(35) rem(35) rem(0) rem(35);
  transition: opacity 0.5s ease;

  .header-top {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;

    .finish-button {
      width: rem(380);
      margin-bottom: rem(12);
    }

    .bits-container {
      display: flex;
      align-items: center;
      color: #ffffff;
      border-radius: rem(8);
      font-size: rem(24);
      font-weight: 600;
      background-color: #25469b;
      padding: rem(10) rem(50) rem(10) rem(10);
    }
  }

  .header-box {
    flex: 1;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: rem(10);
    z-index: -1;
  }

  .title {
    color: #ffffff;
    font-size: rem(24);
    font-weight: 600;
    margin-bottom: rem(18);
    font-size: rem(30);
  }

  .streak-container {
    display: flex;
  }

  .streak-box {
    display: flex;
  }

  .streak-circle {
    background-color: #7b95b2;
    position: relative;
    border-radius: 50%;
    width: rem(30);
    height: rem(30);
  }

  .streak-circle-colored {
    background-image: url(../assets/images/img_correct.svg);
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    //border-radius: 50%;
    width: rem(30);
    height: rem(30);
    //background-color: #0be1d4;
    transform: scale(1.5);
    transition: all 0.8s ease;
  }

  .streak-circle-colored-invisible {
    transform: scale(0);
  }

  .streak-mark {
    position: absolute;
    font-size: rem(22);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .streak-line {
    height: rem(5);
    width: rem(100);
    position: relative;
    background-color: #7b95b2;
    justify-content: baseline;
    align-self: center;
    transform: scale(1.1);
    z-index: -1;
  }

  .streak-line-colored {
    position: absolute;
    height: rem(5);
    width: rem(100);
    background-color: #0be1d4;
  }
}

@media (min-width: $screen-lg) {
  .header-wrapper {
    .header-top {
      flex-direction: row;
    }
  }
  .header-box {
    justify-content: center;
    margin-top: 0;
  }
}

@media screen and (min-width: $screen-lg) {
  .header-box {
    transform: translateY(-45px);
  }
}

// landscape mobile
@media (orientation: landscape) and (max-height: 500px) {
  #header-wrapper {
    padding-top: 24px;
  }
  .day-slide-container .day {
    font-size: 28px;
  }
}
</style>
