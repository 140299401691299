export const state = {
  currentMission: 1, //To be changed, set variable for testing purposes
  currentSlide: 1,
  /**
   * This value is used to record where the user is in the mission, if we do NOT have a session.
   * When playing with a session, use the progress from the session instead.
   * @type {number|null} */
  highestSlideReached: 1,
  day: 1,
  mediaQuizBits: 0,
  streak: 0,
  slideContent: {},
  weekStatus: {},
  /** @type {MissionSession|null} */
  session: null,
  /** @type {historyLocation|null} */
  historyLocation: null,
  /** @type {SessionHistory|null} */
  history: null,
  /** @type {boolean} */
  sessionIsLoading: false,

  /** @type {SubmissionResult | null} */
  submissionResult: null,

  showLoadingScreen: false,

  showingFeedbackModal: false,
}
