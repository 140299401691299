<template>
  <div id="header-wrapper" class="header-wrapper">
    <div class="button-wrapper">
      <img class="nav-btn" src="@/assets/icons/btn_pause.svg" @click="OnPause">
      <img v-if="slideContent.slideIndex > 1 && session?.id !== -1"
           class="nav-btn"
           src="@/assets/icons/icn_back.svg"
           @click="PreviousSlide">
      <img v-if="historyLocation" class="nav-btn" src="@/assets/icons/icn_forward.svg" @click="NextSlide">
      <img v-if="historyLocation" class="nav-btn" src="@/assets/icons/icn_furthest.svg" @click="FurthestSlide">
    </div>

    <div class="header-box">
      <div :title="slideContent.missionDescription" class="mission-title-container">
        <h1 class="mission-title">{{ slideContent.missionTitle }}</h1>
        <div class="progress-bar-container">
          <div id="progress-bar"/>
        </div>
      </div>
    </div>

    <div v-show="showBits" class="header-box header-box-right">
      <BitsDisplay :amount="bits" :large="true" :popup-on-increase="true" :show-double="displayDouble"/>
    </div>
    <div v-show="!showBits" class="header-box"/>

    <pause-modal
      :bits="bits"
      :is-shown="ShowPauseModal"
      :modal-title="$t('PAUSE.PAUSE')"
      :show-bits="showBits"
      @dismiss="UnPause"
      @leave="returnToMenu"
    />
  </div>
</template>

<script>
import PauseModal from '@/components/partials/modal/PauseModal'
import { mapGetters } from 'vuex'
import { gsap } from 'gsap'
import BitsDisplay from './elements/basic/BitsDisplay'
import { ROUTE_NAMES } from '@/router'

export default {
  name: 'GameHeader',
  components: {
    PauseModal,
    BitsDisplay
  },
  data: () => ({
    slideProgressionPercentage: 0,
    ShowPauseModal: false,
  }),
  computed: {
    ...mapGetters({
      day: 'getDay',
      bits: 'getBits',
      slideContent: 'getSlideContent',
      session: 'getSession',
      jokerResult: 'getJokerResult',
      historyLocation: 'getHistoryLocation'
    }),
    displayDouble() {
      return this.jokerResult && this.jokerResult.jokerType.id === 2
    },
    showBits() {
      // console.log('content', this.slideContent)
      return this.slideContent && this.slideContent.missionCategory && this.slideContent.missionCategory.id === 3
    }
  },
  watch: {
    bits(newValue, oldValue) {
      this.bits = newValue - oldValue
    },
    slideContent(newValue, oldValue) {
      this.tweenSlideProgression(newValue.slideIndex, newValue.missionTotalSlideCount)
    },
  },
  mounted() {
    // setTimeout(() => this.tweenSlideProgression(), 0)
  },
  methods: {
    afterEnter() {
      // Play "disappear" animation after x amount of milliseconds
      setTimeout(() => {
        this.bitMessage.show = false
      }, 800)
    },
    tweenSlideProgression(slideContent, totalSlides) {
      const slideProgressionPercentage = ((slideContent - 1) / totalSlides) * 100 + '%'

      gsap.to('#progress-bar', { duration: 0.3, width: slideProgressionPercentage })
    },
    OnPause() {
      this.ShowPauseModal = true
    },
    UnPause() {
      this.ShowPauseModal = false
    },
    // Navigate Slides //
    PreviousSlide() {
      console.log('implement the "go back" functionality')
      this.$store.dispatch('goBackInHistory')
    },
    NextSlide() {
      this.$store.dispatch('goForwardInHistory')
      console.log('implement the "go forward" functionality')
    },
    FurthestSlide() {
      this.$store.dispatch('goFastForwardInHistory')
      console.log('implement the "go to the furthest slide" functionality')
    },
    returnToMenu() {
      //if demo mission or week of, go back to teacher portal
      const classId = this.session ? this.session.schoolClassId : -1
      if (this.slideContent.missionCategory.id === 3) {
        this.$router.push({ name: ROUTE_NAMES.SELECT_MISSION, params: { classId } })
      } else {
        window.open(process.env.VUE_APP_ROOT_CMS + `/leraren-portaal/overzicht${classId ? '/' + classId : ''}`, '_blank')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.header-wrapper {
  // Container
  display: flex;
  align-items: center;
  width: 100%;
  padding: rem(40) rem(35) rem(40) rem(35);
  max-height: 10%;
  transition: opacity 0.5s ease;
  position: sticky;
  top: 0;
  z-index: 3;
  border-bottom: var(--light-grey-border);

  @media screen and (min-width: $screen-720p) {
    padding: rem(15) rem(35) rem(15) rem(35);
  }
}

.header-box {
  flex: 1;
  display: flex;
  justify-content: center;
}

.button-wrapper {
  flex: 1;
}

.btn-wrapper {
  flex: 1;
  width: rem(200);
  // Wrapper for the pause, back and forward buttons
}

.nav-btn {
  // Position pause button to the left of the screen
  width: rem(39);
  height: auto;
  cursor: pointer;
  margin-right: rem(13);
}

.mission-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 540px;

  .mission-title {
    color: #FFFFFF;
    font-size: rem(30);
    font-weight: 600;
    margin-top: rem(6);
  }

  .mission-description {
    // todo? merge error?
  }

  .progress-bar-container {
    width: 100%;
    background-color: #1A1638;
    height: 5px;
    border-radius: 7px;
    display: block;
    margin-top: 16px;
  }

  #progress-bar {
    background-color: #FFFFFF;
    height: 100%;
    width: 0;
    border-radius: 10px;
  }

  @media (min-width: $screen-xs) {
    .slide {
      width: 131px;
    }
  }
  @media (min-width: $screen-sm) {
    .slide {
      width: 290px;
    }
  }
  @media (min-width: $screen-md) {
    .slide {
      width: 480px;
    }
  }
  @media (min-width: $screen-lg) {
    .slide {
      width: 700px;
    }
  }
}

.header-box-right {
  justify-content: right;
}
</style>
