<template>
  <GameHeader/>
  <router-view :key="$route.path"/>
</template>

<script>
import GameHeader from '@/components/GameHeader'

export default {
  name: 'GameMain',
  components: {
    GameHeader,
  },
  // data: () => (
  //   {
  //   }
  // ),
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.content-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  flex-grow: 1;
  text-align: center;
}
</style>
