<template>
  <div :style="cssVars" class="daily-bits-container">
    <div
      v-for="(day, index) in bitsEarned"
      :key="day.date"
      :class="[{'day-light': currentDay >= index + 1}, {'not-current-day': currentDay !== index + 1}, {'current-day': currentDay === index + 1}]"
      class="day-container"
    >
      <div v-if="currentDay === index + 1" class="day-content">
        <p class="day">{{ day.day }}</p>

        <div class="progress-bar">
          <BitsBar :percentage="progress" class="progress-bar"/>
          <img :src="bulbIcons.off" alt="" class="bulb-off bulb-icon ">
          <img :src="bulbIcons.on" alt="" class=" bulb-on bulb-icon">
        </div>

        <div class="lower-text"> {{ day.val + " / " + maxBits + " " + $t("SHARED.BITS_EARNED") }}</div>
      </div>

      <div v-else class="day-content">
        <p class="day">{{ day.day }}</p>
        <div class="bits-container">
          <img v-if="currentDay >= index + 1" :src="require('@/assets/icons/icn_bits.svg')" class="bit-icon">
          <div v-else class="bit-icon temp-off-bit"/>
          <p class="bits">{{ day.val }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from '@vue/runtime-core'
import BitsBar from '@/components/elements/progress/BitsBar.vue'

import { nl } from 'date-fns/locale'
import { format } from 'date-fns'

export default {
  name: 'DailyBitsEarned',
  components: {
    BitsBar
  },
  props: {
    bitsPerDay: {
      type: Array,
      required: true
    },
    maxBits: {
      type: Number,
      required: true
    },
    currentDay: {
      type: Number,
      required: true
    },
    startDate: {
      type: String,
      required: true
    },
    endDate: {
      type: String,
      required: true
    },
    loggedInData: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const bulbIcons = {
      on: require('@/assets/images/bulb_on@2x.png'),
      off: require('@/assets/images/bulb_off@2x.png')
    }

    const progress = props.bitsPerDay[props.currentDay - 1] / props.maxBits

    const cssVars = computed(() => {
      return {
        '--progress': `${progress * 100}%`,
        '--on-bulb-opacity': `${animating.value ? 0 : progress}`,
        '--off-bulb-opacity': '1',
      }
    })

    const bitsEarned = ref([])

    function formatBitsEarned() {
      const current = new Date(props.startDate)

      const bitsEarnedFormatted = []
      props.bitsPerDay.forEach((val, i) => {
        bitsEarnedFormatted.push({
          date: current,
          val,
          day: format(
            new Date(current.valueOf()),
            'EEEEEE.',
            { locale: nl }
          )
        })
        current.setDate(current.getDate() + 1)
      })
      bitsEarned.value = bitsEarnedFormatted
    }

    formatBitsEarned()

    const animating = ref(true)
    const animationTime = 2000
    onMounted(() => {
      setTimeout(() => {
        animating.value = false
      }, animationTime)
    })

    return {
      cssVars,
      bulbIcons,
      animating,
      progress,
      bitsEarned
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";
@import "~@/assets/css/global.scss";

.daily-bits-container {
  width: 100%;
  max-width: fit-content;
  display: flex;
  justify-content: space-between;

  .day-container {
    height: rem(88);
    border-radius: rem(22);
    background-color: #4A3C6B;
    color: #2C2148;

    .day {
      margin-bottom: rem(10);
    }

    .bits {
      margin-left: rem(4);
      margin-top: rem(1);
    }

    &:not(:last-child) {
      margin-right: rem(8);
    }
  }

  .day-light {
    color: #FFF;
    background-color: #4D4C89;
  }

  .current-day {
    display: flex;
    align-items: center;
    width: rem(552);
    padding-left: rem(45);

    .day {
      margin-bottom: 0;
    }
  }

  .not-current-day {
    width: rem(92);

    .day-content {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .bits-container {
    display: flex;
    align-items: center;
    font-weight: 600;
  }

  .bit-icon {
    height: rem(19);
    width: rem(19);
  }

  .temp-off-bit {
    border-radius: 50%;
    background: #2C2148;
  }

  .day {
    text-transform: capitalize;
  }
}

.extra-bits-progressbar {
  width: rem(450);
  background: linear-gradient(133.68deg, #413873 0%, #4A4C90 100%);
  padding: rem(15) rem(45) rem(15) rem(30);
  border-radius: rem(20) rem(20) rem(0) rem(0);
  margin: 0 auto;
  position: relative;
}

.bits-earned {
  font-weight: 500;
}

.progress-bar {
  width: rem(453);
  height: rem(26);
  position: relative;
}

.bulb-icon {
  position: absolute;
  right: 0;
  transition: opacity 2s ease;
  transform: translate(50%, -95%);
  height: rem(110);
}

.bulb-on {
  height: rem(145);
  opacity: var(--on-bulb-opacity);
}

.bulb-off {
  opacity: var(--off-bulb-opacity);
  transform: translate(50%, -98%);

}

:deep .progress-bar-wrapper {
  width: 100%;
  max-width: unset;
}

</style>
