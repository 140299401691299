<template>
  <div ref="card-wrapper" class="cards-wrapper">
    <div v-for="(card, index) in cards"
         :key="`card-${index}`"
         class="card"
         :style="createCardStyling(index)"
    >
      <div v-if="givenAnswer !== null" class="card-feedback" :class="givenAnswer === 1 ? 'positive-feedback-container' : 'negative-feedback-container'" :style="feedbackStyle">
        {{ givenAnswer === 1 ? feedbackPositiveText : feedbackNegativeText }}
      </div>
      <img v-if="showCardImage" class="card-image" :src="card.mediaFileUrl" style="width: 100%; height: 100%; border-radius: 1.1rem;"/>
      <slot v-if="index === 0" :card="card"></slot>
    </div>
  </div>
</template>

<script>
import CardFeedback from '@/components/partials/cards/CardFeedback'

/**
 * This class is used to create a stack of cards.
 * The cards their size (width & height) will be determined,
 * by the size (width & height) of the "Cards" (this) component's parent.
 */
export default {
  name: 'Cards',
  props: {
    cards: {
      type: Array,
      required: true
    },
    feedback: {
      type: Number,
      default: CardFeedback.none
    },
    showCardImage: {
      type: Boolean,
      default: false,
    },
    feedbackPositiveText: {
      type: String,
      default: 'POSITIVE'
    },
    feedbackNegativeText: {
      type: String,
      default: 'NEGATIVE'
    },
    padding: {
      type: String,
      default: '5px'
    },
    givenAnswer: {
      default: null,
    },
    feedbackStyle: {
      type: Object,
      default: () => ({ transform: 'translate(-55px, -10px) rotate(-15deg)', padding: '17px 30px' })
    }
  },
  data: () => ({
    cardSize: { width: '500', height: '500' }
  }),
  computed: {
    cssVars() {
      return {
        '--card-padding': this.padding
      }
    }
  },
  mounted() {
    const content = this.$refs['card-wrapper']
    new ResizeObserver(this.onResizeCard).observe(content)

    // console.log(this.padding)
  },
  methods: {
    createCardStyling(cardIndex) {
      const widthDifference = this.cardSize.width * 0.1 * cardIndex
      const heightDifference = this.cardSize.width * 0.1 * cardIndex
      const properties = {
        '--card-transform-y': `${(cardIndex * 14) + heightDifference}px`,
        '--card-width': this.cardSize.width - widthDifference + 'px',
        '--card-height': this.cardSize.height - heightDifference + 'px',
        '--card-margin': (widthDifference / 2) + 'px',
        'z-index': this.cards.length - cardIndex,
      }
      return Object.assign(properties, this.cssVars)
    },
    hasAnsweredPositive() {
      return this.feedback === CardFeedback.positive
    },
    hasAnswered() {
      return this.feedback !== CardFeedback.none
    },
    /**
     * This method will handle the cards their size.
     * It will be activated when the "Cards" (this) component it's parent is re-sized.
     */
    onResizeCard() {
      const content = this.$refs['card-wrapper']
      const width = content.clientWidth
      const height = content.clientHeight
      this.cardSize = { width, height }
      // console.log('this.cardSize', this.cardSize)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";
@import "~@/assets/css/global.scss";

.cards-wrapper {
  height: 100%;
  width: 100%;
  position: relative;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.cards-wrapper::-webkit-scrollbar {
  display: none;
}

.card {
  height: var(--card-height);
  width: var(--card-width);
  transform: translate(0, var(--card-transform-y));
  margin-left: var(--card-margin);
  position: absolute;
  background-color: #F7F8FB;
  border-radius: rem(12);
  color: var(--text_color);
  padding: var(--card-padding);
  font-size: rem(22);
  box-shadow: rem(0) rem(5) rem(10) rem(0) #000000;

  .card-image {
    object-fit: contain;
  }

  .card-feedback {
    z-index: 1;
    position: fixed;
    color: #FFFFFF;
    border: none;
    border-radius: rem(8);
    box-shadow: rem(4) rem(4) 0 0 rgba(0,0,0,0.1);
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
    letter-spacing: 0;
    line-height: rem(22);
    padding: rem(15) rem(30);
    text-align: center;
    transition: filter 0.15s;
    min-width: rem(162);
    transform: var(--card-feedback-translate);
  }
}

//@media screen and (max-width: $screen-720p) {
//}
</style>
