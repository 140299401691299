<template>
  <div class="quiz-complete-wrapper">
    <!-- <div class="top-section-background">
      <div class="top-section-background-clip"/>
    </div> -->
    <div class="top-section">

      <h1 class="title"> {{ $t('MEDIA_QUIZ_COMPLETE.TITLE') }}</h1>
      <div v-if="loggedInData && correctlyAnswered === 1" class="questions-answered">
        {{ $t('MEDIA_QUIZ_COMPLETE.CORRECT_SINGULAR') }}
      </div>
      <i18n-t v-if="loggedInData && correctlyAnswered !== 1"
              class="questions-answered"
              keypath="MEDIA_QUIZ_COMPLETE.CORRECT"
              tag="div">
        <template #correct>
          {{ correctlyAnswered }}
        </template>
      </i18n-t>
      <div class="trophy-wrapper">
        <img class="complete-trophy" src="@/assets/images/img_trophy@2x.png"/>
        <div class="trophy-background-wrapper">
          <img class="trophy-background swirl" src="@/assets/images/img_swirl.svg"/>
          <img class="trophy-background spirals" src="@/assets/images/img_shapes.svg"/>
        </div>
      </div>
    </div>
    <div class="bottom-section">

      <h2 v-if="loggedInData" class="bits-earned">{{ $t('MEDIA_QUIZ_COMPLETE.BITS_EARNED') }}</h2>
      <BitsDisplay
        v-if="loggedInData"
        :amount="loggedInData ? bits : null"
      />
      <BitsBar
        v-if="loggedInData"
        :bit-count="loggedInData ? loggedInData.mediaquiz.bitsEarned : null"
        :percentage="percentage"
        :maximum-bits="loggedInData?.mediaquiz?.maxBitsEarnable"
      />
      <p v-if="loggedInData" class="class-earned-text" style="margin-top: 10px; margin-bottom: 10px;">
        <template v-if="reachedCap">{{ $t('MEDIA_QUIZ_COMPLETE.CLASS_EARNED_BIT_LIMIT_REACHED') }}</template>
        <template v-else>{{ $t('MEDIA_QUIZ_COMPLETE.CLASS_EARNED') }}</template>
        <br>
        {{ $t('MEDIA_QUIZ_COMPLETE.CLASS_EARNED_BITS_EXPLAIN') }}
        <span class="bold-text">{{ loggedInData.schoolClass.name }}</span>
      </p>
      <i18n-t v-if="!loggedInData" class="questions-answered" keypath="MEDIA_QUIZ_COMPLETE.CORRECT" tag="div">
        <template #correct>
          {{ correctlyAnswered }}
        </template>
      </i18n-t>
      <div class="continue-finish-buttons">
        <BasicButton
          :button-text="reachedCap ? $t('MEDIA_QUIZ_COMPLETE.CONTINUE_NO_BITS') : $t('MEDIA_QUIZ_COMPLETE.CONTINUE')"
          @click="continuePlaying"
        />
        <BasicButton
          :button-class="'white-button'"
          :button-text="$t('MEDIA_QUIZ_COMPLETE.MAIN_MENU')"
          @click="returnToMenu"
        />
      </div>
    </div>
    <Balloons :count="10"/>

  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import apiHandler from '../../utils/handlers/ApiHandler'
import BitsDisplay from '../../components/elements/basic/BitsDisplay.vue'
import BitsBar from '../../components/elements/progress/BitsBar.vue'
import BasicButton from '../../components/elements/basic/BasicButton.vue'
import { computed } from '@vue/runtime-core'
import { useRouter } from 'vue-router'
import Balloons from '@/components/elements/Balloons'
import { mapGetters, useStore } from 'vuex'

export default {
  name: 'QuizComplete',
  components: {
    BitsDisplay,
    BitsBar,
    BasicButton,
    Balloons,
  },
  props: {
    correctlyAnswered: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      bits: 'getMediaQuizBits'
    })
  },
  emits: ['continue'],
  setup(props, { emit }) {
    const loggedInData = ref(null)
    const store = useStore()
    const percentage = computed(() => loggedInData.value ? loggedInData.value.mediaquiz.bitsEarned / loggedInData.value.mediaquiz.maxBitsEarnable : 0)
    const reachedCap = computed(() => percentage.value >= 0.99999)
    const weekStatus = computed(() => store.getters.getWeekStatus)
    const postWeek = computed(() => weekStatus.value.status === 'passed' || weekStatus.value.status === 'passed_pre_ranking')

    if (postWeek.value) {
      localStorage.removeItem('schoolClass')
    }
    const router = useRouter()
    let localStorageLoggedInData = localStorage.getItem('schoolClass')
    if (localStorageLoggedInData) {
      localStorageLoggedInData = JSON.parse(localStorageLoggedInData)
      const formData = new FormData()

      formData.append('className', localStorageLoggedInData.schoolClass.name)
      apiHandler.api.post('/auth/login-extra-game-class', formData)
        .then((res) => {
          localStorage.setItem('schoolClass', JSON.stringify(res.data))
          // this.$router.push('/extra-games')
          loggedInData.value = res.data
          // console.log('res', res)
        })
        .catch((error) => {
          this.apiError = true
          console.log('error', error)
        })
    }

    function continuePlaying() {
      emit('continue')
    }

    function returnToMenu() {
      // router.push({ name: ROUTE_NAMES.EXTRAS })
      router.push('/extra-games')
    }

    return {
      loggedInData,
      percentage,
      reachedCap,
      continuePlaying,
      returnToMenu,
    }
  }

}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";
@import "~@/assets/css/global.scss";

$background-color: #273F7F;
.quiz-complete-wrapper {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-content: center;
  align-items: center;
  color: white;
  height: 100%;
  text-align: center;
  overflow-y: auto;
}

.top-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  background-image: var(--background_overlay_image);
  background-size: 100vw 100vh;
  z-index: 2;
  gap: rem(20);
  clip-path: ellipse(100% 500% at 50% -403%);
}

.top-section-background {
  position: absolute;
  z-index: -1;
  background-color: $background-color;
  width: 100%;
  height: 100%;
}

.top-section-background-clip {
  width: 100%;
  height: 100%;
}

.title {
  margin: rem(48) rem(0) rem(20);
}

.complete-trophy {
  width: rem(200);
  margin-top: rem(20);
  z-index: 2;
  position: relative;
}

.trophy-wrapper {
  position: relative;
}

.trophy-background-wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -30%);
  width: 98vw;
  height: 100vh;
  overflow: hidden;
}

.trophy-background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

.swirl {
  animation-name: spin;
  animation-duration: 20000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes grow {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.spirals {
  animation-name: grow;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
}

.questions-answered {
  font-size: rem(16);
}

.bold-text {
  font-weight: bold;
}

.class-earned-text {
  max-width: rem(1000)
}

.bottom-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $background-color;
  width: 100%;
  flex: 1 0 auto;
  gap: rem(20);
  padding: 500px rem(50) 0;
  margin-top: -500px;

  .bits-earned {
    margin-top: 16px;
  }

  .bits-container {
    background-color: #2F4EA5;
    border-radius: rem(12);
    padding: 16px 60px 16px 12px;
  }
}

.continue-finish-buttons {
  display: flex;
  flex-direction: column;
  gap: rem(16);
  margin-bottom: 16px;
}

@media screen and (max-width: $screen-720p) {
  .top-section {
    .title {
      margin-top: 24px;
      margin-bottom: 0;
      font-size: 28px;
    }
  }

  .bits-earned {
    font-size: 28px;
  }

  .bottom-section {
    padding-left: 16px;
    padding-right: 16px;
  }

  .class-earned-text {
    line-height: unset;
    font-size: 16px;
  }

  :deep(.trophy-wrapper .complete-trophy) {
    margin-top: 0;
    width: 140px;
  }

  :deep(.balloon) {
    width: 102px;
  }
}

@media screen and (max-width: 500px) {
  :deep(.trophy-wrapper .complete-trophy) {
    margin-top: 0;
    width: 100px;
  }
  :deep(.balloon) {
    width: 62px;
  }
}

// landscape mobile
@media (orientation: landscape) and (max-height: 500px) {
  :deep(.balloon) {
    width: 62px;
  }
}
</style>
