export default class TextQuestionContent {
  /**
   * @type {string[]} a list of text lines to show.
   */
  content

  constructor(content) {
    this.content = content
  }

  static parseFromDataObject(data) {
    return new TextQuestionContent(data.content)
  }
}
