<template>
  <Modal :is-shown="isShown">
    <template #content>
      <img src="@/assets/images/img_gold_tip.png" class="tip-icon"/>
      <h1 class="modal-title">{{ $t('SHARED.TIP') }}</h1>
      <p class="modal-text"> {{ tipText }}</p>
      <BasicButton :button-text="$t('SHARED.OK')" @click="$emit('dismiss')"/>
    </template>
  </Modal>
</template>

<script>
import BasicButton from '@/components/elements/basic/BasicButton.vue'
import Modal from './Modal'
export default {
  name: 'TipModal',
  components: {
    BasicButton,
    Modal
  },
  props: {
    isShown: {
      type: Boolean,
      required: true
    },
    tipText: {
      type: String,
      required: true
    }
  },
  emits: ['dismiss']
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.modal-text{
  color: white;
  margin: rem(20) 0;
}

.modal-title{
  color: white;
}

</style>