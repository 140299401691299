import MazeQuestionContentSubSlideAnswer from './MazeQuestionContentSubSlideAnswer'

export default class MazeQuestionContentSubSlide {
  /**
   * @type {number} the id of the sub slide.
   */
  id;

  /**
   * @type {number} the text of the sub slide.
   */
  mediaFileId;

  /**
   * @type {string} the text of the sub slide.
   */
  mediaFileUrl;

  /**
   * @type {MazeQuestionContenSubSlideAnswer[]} an array of possible answers
   */
  answers;

  constructor(id, mediaFileId, mediaFileUrl, answers) {
    answers.sort((a, b) => Math.random() - 0.5)
    this.id = id
    this.mediaFileId = mediaFileId
    this.mediaFileUrl = mediaFileUrl
    this.answers = answers
  }

  static parseFromDataObject(data) {
    return new MazeQuestionContentSubSlide(
      data.id,
      data.mediaFileId,
      data.mediaFileUrl,
      data.answers.map(answer => MazeQuestionContentSubSlideAnswer.parseFromDataObject(answer)))
  }
}
