<template>
  <div class="class-conversation">
    <header class="class-conversation-header">
      <div class="top">
        <div class="return">
          <a :href="returnLink">
            <img src="../assets/icons/icn_arrowback.svg" alt="">
            <p>{{ $t('CLASS_CONVERSATION.BACK') }}</p>
          </a>
        </div>
        <div class="logo">
          <img src="../assets/icons/icn_logo.svg" alt="">
        </div>
      </div>
      <div class="bottom">
        <h1>Klassengesprekken</h1>
        <p>Ga met elkaar in gesprek aan de hand van beeldmateriaal en stellingen en ontdek hoe jullie over dit onderwerp denken.</p>
      </div>
    </header>
    <div ref="conversationCardsWrapperEl" class="conversation-cards-wrapper" @scroll="onScroll">
      <div ref="inside" class="inside">
        <ConversationCard
          v-for="item in conversationMissions"
          :key="item.id"
          :duration-minutes="15"
          :title="item.name"
          :description="item.description"
          :image="item.mediaFileUrl"
          :button-clicked="() => startMission(item.id)"
        />
      </div>
    </div>
    <footer class="class-conversation-footer">
      <div class="scroll-state-wrapper">
        <div v-if="screenAmount" class="inside">
          <div v-for="index in screenAmount" :key="index" class="scroll-state" :class="{ 'active': index === currentIndex }" @click="scrollStateClicked(index)"/>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import ConversationCard from '../components/elements/ConversationCard'
import { ref, onMounted, watch, nextTick } from 'vue'
import { computed } from '@vue/reactivity'
import { useStore } from 'vuex'
import { GET_CLASS_CONVERSATION_MISSIONS } from '@/store/modules/extras/actions'
import { ROUTE_NAMES } from '@/router'
import { useRouter } from 'vue-router'
export default {
  name: 'ClassConversation',
  components: {
    ConversationCard
  },
  setup(props) {
    const store = useStore()
    const router = useRouter()
    const returnLink = computed(() => {
      return `${process.env.VUE_APP_ROOT_CMS}/leraren-portaal/overzicht/`
    })
    store.dispatch(GET_CLASS_CONVERSATION_MISSIONS)
    const conversationMissions = computed(() => store.getters.getClassConversationMissions)

    const conversationCardsWrapperEl = ref({})
    const inside = ref(null)
    const screenAmount = ref(0);
    const currentIndex = ref(1);
    const normalizedScroll = ref(0);

    let insideWidth = 0;
    let screenWidth = 0;
    let adjustedScreenWidth = 0;

    function onScroll() {
      normalizedScroll.value = conversationCardsWrapperEl.value.scrollLeft / conversationCardsWrapperEl.value.scrollLeftMax
    }

    function startMission(id) {
      router.push({ name: ROUTE_NAMES.GAME_SLIDE, params: { id, slideIndex: 1 } })
    }

    onMounted(() => {
      calculateWidths();
      window.addEventListener('resize', calculateWidths);
    });

    watch(conversationMissions, () => {
      nextTick(() => {
        calculateWidths();
      });
    });

    function calculateWidths() {
      if(!inside.value) return;
      insideWidth = inside.value.offsetWidth;
      screenWidth = window.innerWidth;
      adjustedScreenWidth = screenWidth / 2;
      screenAmount.value = Math.ceil(insideWidth / adjustedScreenWidth) - 1;
    }

    function scrollStateClicked(index) {
      currentIndex.value = index;
      conversationCardsWrapperEl.value.scrollLeft = (index - 1) * adjustedScreenWidth;
    }

    return {
      conversationCardsWrapperEl,
      normalizedScroll,
      returnLink,
      scrollStateClicked,
      onScroll,
      conversationMissions,
      startMission,
      inside,
      screenAmount,
      currentIndex
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";
@import "~@/assets/css/global.scss";

.class-conversation-header {
  color: white;
  position: relative;
  .top {
    height: rem(110);
    padding-top: rem(42);

    .return {
      margin-top: rem(22);
      position: absolute;
      display: flex;
      gap: rem(16);
      font-weight: 500;
    }

    .logo {
      height: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .bottom {
    h1 {
      margin-bottom: rem(20);
    }
    p {
      max-width: rem(850);
      margin-bottom: rem(32);
      font-size: rem(16);
      line-height: rem(24);
    }
  }
}

.conversation-cards-wrapper {
  margin-left: rem(-41);
  overflow-x: hidden;
  overflow-y: visible;
  flex-grow: 1;
  scrollbar-color: #4d4363 #4d4c89;
  scrollbar-width: thin;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;

  .inside {
    width: fit-content;
    display: flex;
    gap: rem(20);
    padding-right: rem(41);
    padding-left: rem(41);
    padding-bottom: rem(40);
  }
}

.conversation-cards-wrapper::-webkit-scrollbar {
  display: none;
}

.return {
  cursor: pointer;
  a {
    display: flex;
    align-items: center;
    color: white;
    font-size: rem(18);
    font-weight: 500;
    img {
      margin-right: rem(16);
    }
  }
}

.class-conversation {
  margin-bottom: 0 !important;
  margin-left: rem(41);
  display: flex;
  flex-direction: column;
}

.class-conversation-footer {
  flex-grow: 1;
  max-height: rem(100);
  margin-top: auto;
  .scroll-state-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    .inside {
      height: rem(18);
      display: flex;
      justify-content: center;
      align-items: center;
      gap: rem(35);

      .scroll-state {
        background: white;
        opacity: 0.2;
        border-radius: 50vh;
        width: rem(14);
        height: rem(14);

        cursor: pointer;
        transition: width 0.25s, height 0.25s, opacity 0.25s;

        &.active {
          opacity: 1;
          width: rem(18);
          height: rem(18);
        }
      }
    }
  }
}
</style>
