import ApiHandler from '@/utils/handlers/ApiHandler'
import ExtraGame from '@/models/ExtraGame'
import { SET_EXTRA_GAMES, SET_CLASS_CONVERSATION_MISSIONS } from './mutations'
export const GET_EXTRA_GAMES = 'getExtraGames'
export const GET_CLASS_CONVERSATION_MISSIONS = 'getClassConversationMissions'


export const actions = {
  getExtraGames({ commit }) {
    return ApiHandler.get('game/extra-games/get-extra-games').then(response => {
      if (response) {
        commit(SET_EXTRA_GAMES, response.data)
        return true
      }
      return false
    }).catch(error => {
      console.log(error)
    })
  },
  getClassConversationMissions({ commit }) {
    return ApiHandler.api.get('teacher/get-class-conversation-missions').then(response => {
      if (response) {
        commit(SET_CLASS_CONVERSATION_MISSIONS, response.data)
        return true
      }
      return false
    })
  }

}
