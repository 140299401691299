<template>
  <div class="content-wrapper">
    <img class="back-button"
         src="@/assets/icons/icn_back.svg"
         @click="backToExtras"
    />
    <header class="header">
      <div class="logo-wrapper">
        <img src="@/assets/icons/icn_logo.svg" alt="Logo" class="logo">
      </div>

      <h1 class="title">{{ $t("LOGIN.TITLE") }}</h1>
      <p class="description-text">
        {{ $t('LOGIN.SUBTITLE') }}
      </p>
    </header>
    <form class="login-form">
      <input id="login-class-name-input"
             v-model="className"
             :class="apiError ? 'input-error' : ''"
             class="login-input"
             type="text"
             :placeholder="$t('LOGIN.PLACEHOLDER')"
      >
      <img v-if="apiError" src="../assets/icons/icn_error.svg" class="error-icon">
      <div v-if="apiError" class="error-flash-block">
        Er is geen klas met deze naam gevonden.
      </div>
      <button :disabled="!className" class="pink-button login-button" @click.stop.prevent="loginClass()">Speel voor bits</button>
    </form>

    <footer class="footer">
      <router-link to="/extra-games">
        <button class="base-button no-login-button">{{ $t('LOGIN.PLAY_WITHOUT') }}</button>
      </router-link>
      <p class="description-text">{{ $t('LOGIN.NO_EXTRA_BITS') }}</p>
    </footer>

  </div>
</template>

<script>
import ApiHandler from '@/utils/handlers/ApiHandler'
import { ROUTE_NAMES } from '@/router'

export default {
  name: 'Login',
  // components: {
  // },
  data: () => ({
    className: '',
    apiError: false,
  }),
  mounted() {
    this.$nextTick(() => {
      const loginInputElem = document.getElementById('login-class-name-input')
      if (loginInputElem) loginInputElem.focus()
    })
  },
  methods: {
    loginClass() {
      // this.apiError = false
      const formData = new FormData()
      formData.append('className', this.className)
      ApiHandler.api.post('/auth/login-extra-game-class', formData)
        .then((res) => {
          localStorage.setItem('schoolClass', JSON.stringify(res.data))
          console.log('login-extra-game-class res', res)
          this.apiError = false

          this.$router.push('/extra-games')
        })
        .catch((error) => {
          this.apiError = true
          console.log('error', error)
        })
    },
    backToExtras() {
      this.$router.push({ name: ROUTE_NAMES.EXTRAS })
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

h1, p {
  text-align: center;
}

.content-wrapper {
  padding: 80px rem(42) rem(42);
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 0;
}

.back-button {
  position: absolute;
  cursor: pointer;
  top: rem(30);
  left: rem(80);
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo-wrapper {
  .logo {
    max-width: 300px;
    width: 100%;
  }
}

.title {
  color:white;
  font-size: 31px;
  margin-top: 48px;
  margin-bottom: rem(12);
}

.description-text {
  color: white;
  opacity: 0.7;
  margin-top: rem(4);
  font-size: rem(16);
}

.login-form {
  display: flex;
  flex-direction: column;
  margin-top: rem(46);
  position: relative;
  width: 100%;
  max-width: 420px;
  .error-icon {
    position: absolute;
    right: rem(13);
    top: rem(25);
    transform: translateY(-50%);
  }
  .login-input {
    height: 50px;
    border-radius: 8px;
    background-color: #FEFEFE;
    box-shadow: 2px 2px 0 0 rgba(8,13,43,0.2);
    padding: 16px;
    outline: none;
    font-family: 'Rubik', sans-serif;
    color: #373B52;
    text-align: center;
    font-size: 16px;
    border: none;
    &:placeholder-shown {
      color: #7A95B2;
    }
    &.input-error {
      border: 1.5px solid #E64D4D;
    }
  }

  .error-flash-block {
    height: 44px;
    width: 420px;
    border-radius: 8px;
    background-color: rgba(217,82,107,0.2);
    color: white;
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .login-button {
    height: 50px;
    margin-top: 32px;
    font-size: 18px;
    font-weight: 500;
    transition: opacity 0.2s, color 0.2s;

    &:disabled {
      cursor: not-allowed;
      background-color: #ACABB8;
      opacity: 0.6;
      color: white;
    }
  }
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
  margin-bottom: 32px;
  width: 100%;
  max-width: 420px;

  a {
    width: 100%;
  }

  .no-login-button {
    width: 100%;
    color: #373B52;
  }
}

@media screen and (max-width: 800px) {
  .content-wrapper {
    padding: rem(80) rem(16) rem(42);
  }

  .login-form {
    width: 100%;
  }

  .back-button {
    left: rem(40);
  }
}

@media screen and (max-width: 500px) {
  .title {
    font-size: 26px;
    margin-top: 32px;
  }

  .back-button {
    left: rem(15);
    top: rem(15);
  }

  .login-form {
    margin-top: 20px;
    margin-bottom: 8px;
  }
  .content-wrapper {
    padding: rem(60) rem(16) rem(42);
  }

  .login-form {
    width: 100%;
  }
}
</style>
