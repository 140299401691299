<template>
  <div class="scroll-wrapper">
    <div class="task-wrapper">
      <FeedbackDisplay v-if="shouldBeShowingFeedback"
                       :on-press-next="closeFeedback"
                       :was-correct="showCorrectVideo"/>
      <div v-else>
        <slide-header :description="slideContent.description" :title="slideContent.title"></slide-header>
        <div class="card-container">
          <div class="card-size">
            <cards
              :cards="activeCards"
              :feedback="getCardFeedback()"
              :feedback-negative-text="$t('FOOTER.NETIQUETTE.NOT_OK')"
              :feedback-positive-text="$t('FOOTER.NETIQUETTE.OK')"
              :feedback-style="{transform: 'translate(-55px, -18px) rotate(-15deg)', padding: '17px 30px'}"
              :given-answer="correctAnswer"
              :show-card-image="false"
              padding="19px"
            >
              <template #default="{ card }" class="template">
                <ExamineChat
                  v-if="card.netiquetteTypeId === netiquetteType.chat"
                  :data="card.messages"
                />
                <ExamineComment
                  v-if="card.netiquetteTypeId === netiquetteType.comment"
                  :data="card"
                >
                </ExamineComment>
                <ExamineFriendRequest
                  v-if="card.netiquetteTypeId === netiquetteType.friendRequest"
                  :data="card"
                />
              </template>
            </cards>
          </div>
        </div>
      </div>
    </div>
  </div>
  <game-footer
    v-if="!shouldBeShowingFeedback"
    :choice-data="{
      positiveChoiceText: $t('FOOTER.NETIQUETTE.OK'),
      negativeChoiceText: $t('FOOTER.NETIQUETTE.NOT_OK'),

      positiveChoiceClick: () => checkAnswer(answerTypes.ok),
      negativeChoiceClick: () => checkAnswer(answerTypes.notOk),
    }"
    :feedback-button-click="showFeedback"
    :feedback-button-text="$t('FOOTER.FEEDBACK_BUTTON_TEXT')"
    :feedback-result="result"
    :force-next="shouldBeShowingFeedback"
    :has-feedback-popup="feedbackText"
  ></game-footer>
</template>

<script>
import ExamineChat from '@/components/tasks/netiquette/ExamineChat'
import ExamineFriendRequest from '@/components/tasks/netiquette/ExamineFriendRequest'
import ExamineComment from '@/components/tasks/netiquette/ExamineComment'
import SlideHeader from '@/components/elements/SlideHeader'
import GameFooter from '@/components/GameFooter'
import i18n from '@/setup/i18n/i18n'
import Cards from '@/components/partials/cards/Cards'
import CardFeedback from '@/components/partials/cards/CardFeedback'
import { POST_SUB_SLIDE_ANSWER } from '../../../store/actions'
import { RESET_SLIDE_BONUSES, UPDATE_BITS } from '../../../store/mutations'
import { mapGetters } from 'vuex'
import FeedbackDisplay from '../FeedbackDisplay'

const NetiquetteTypes = {
  chat: 1,
  comment: 2,
  friendRequest: 3,
}

const AnswerTypes = {
  notOk: 0,
  ok: 1,
}

export default {
  name: 'Netiquette',
  components: { FeedbackDisplay, Cards, GameFooter, SlideHeader, ExamineComment, ExamineFriendRequest, ExamineChat },
  props: {
    slideContent: {
      type: Object,
      required: true,
    },
  },
  emits: ['nextSlide'],
  data: () => ({
    activeCards: [],
    netiquetteType: NetiquetteTypes,
    answerTypes: AnswerTypes,
    currentCard: {},
    answeredCards: [],
    result: null,
    feedbackText: '',
    feedbackVideoUrl: '',
    givenAnswer: null,
  }),
  computed: {
    ...mapGetters({
      session: 'getSession',
      historyLocation: 'getHistoryLocation',
      showFeedbackModal: 'getShowFeedbackModal'
    }),
    shouldBeShowingFeedback() {
      return this.historyLocation?.isPostAnswer || this.showFeedbackModal
    },
    correctAnswer() {
      const answers = this.$store.getters.getCorrectAnswers
      console.log('got correct answer', answers)
      if (answers === undefined || answers === null) {
        return null
      }
      console.log('got correct answers', answers)
      return answers
    },
    givenAnswer() {
      const answers = this.$store.getters.getClientAnswer
      console.log('got given answers', answers)
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.selectedAnswer = answers
      return answers
    },
    showCorrectVideo() {
      return this.correctAnswer === this.givenAnswer
    },
  },
  watch: {
    slideContent: {
      immediate: true,
      handler(newVal, oldVal) {
        // select the first card when API comes in
        this.activeCards = [...newVal.questionContent.subSlides]
        if (this.session.currentSubSlideIndex) {
          this.activeCards.splice(0, this.session.currentSubSlideIndex)
        }
        this.currentCard = this.activeCards[0]
      },
    },
  },
  methods: {
    checkAnswer(answerType) {
      const formData = new FormData()
      formData.append('missionId', this.slideContent.missionId)
      formData.append('slideIndex', this.slideContent.slideIndex)
      formData.append('subSlideIndex', this.currentCard.id)
      formData.append('answer', answerType)

      this.$store.dispatch(POST_SUB_SLIDE_ANSWER, formData)
        .then(response => {
          this.feedbackText = response.feedback
          this.feedbackVideoUrl = response.feedbackVideoUrl
          if (response.isAnswerCorrect) {
            this.result = {
              successPercentage: 1,
              title: i18n.global.tc('FOOTER.GOOD_JOB'),
              id: response.correctAnswer,
              feedbackButtonText: this.feedbackText ? i18n.global.tc('FOOTER.FEEDBACK_BUTTON_TEXT') : i18n.global.tc('FOOTER.NEXT'),
            }
            this.$store.commit(UPDATE_BITS, response.awardedBits)
          } else {
            const wrongAnswerText = i18n.global.tc('FOOTER.SINGLE_ANSWER.FEEDBACK_TITLE') + ' ' + (response.correctAnswer === 0 ? i18n.global.tc('FOOTER.NETIQUETTE.NOT_OK') : i18n.global.tc('FOOTER.NETIQUETTE.OK'))
            this.result = {
              successPercentage: 0,
              title: wrongAnswerText,
              feedbackButtonText: this.feedbackText ? i18n.global.tc('FOOTER.FEEDBACK_BUTTON_TEXT') : i18n.global.tc('FOOTER.NEXT'),
            }
          }
          this.givenAnswer = response.correctAnswer
        })
    },
    showNextCard() {
      this.$store.commit(RESET_SLIDE_BONUSES)

      // Reset result / answer
      this.result = null
      this.givenAnswer = null
      //Removes said card
      this.activeCards.splice(0, 1)
      //Sets new current card
      this.currentCard = this.activeCards[0]
    },
    showFeedback() {
      //Show feedback modal
      if (this.feedbackText || this.feedbackVideoUrl) {
        this.$store.dispatch('showFeedbackModal')
      } else if (this.activeCards.length > 1) {
        this.showNextCard()
      } else {
        this.$emit('nextSlide')
      }
    },
    closeFeedback() {
      //Hides feedback modal
      this.feedbackText = ''
      this.$store.dispatch('hideFeedbackModal')
      //If not the last card, show next card
      if (this.activeCards.length > 1) {
        this.showNextCard()
      } else {
        this.$emit('nextSlide')
      }
    },
    hasAnsweredTrue() {
      return this.givenAnswer === AnswerTypes.ok
    },
    hasAnswered() {
      return this.givenAnswer !== 0
    },
    getCardFeedback() {
      if (this.result) {
        if (this.result.successPercentage === 1) {
          return CardFeedback.positive
        } else {
          return CardFeedback.negative
        }
      }
      return CardFeedback.none
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";
@import "~@/assets/css/global.scss";
@import "~@/assets/css/mission.scss";

.card-container {
  display: flex;
  justify-content: center;
  position: relative;

  .card-size {
    width: rem(630);
    height: rem(500);

    .template {
      border-radius: rem(12);
      padding: rem(20);
    }
  }
}

@media screen and (max-width: $screen-720p) {
  .task-wrapper {
    padding-top: 6px;
    padding-bottom: 6px;
  }
  .card-container .card-size {
    height: rem(285);
  }
}
</style>
