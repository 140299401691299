<template>
  <div class="scroll-wrapper">
    <div class="task-wrapper">
      <FeedbackDisplay v-if="shouldBeShowingFeedback"
                       :on-press-next="close"/>

      <slide-header :class="hidden ? 'pre-click-hidden': 'shown'"
                    :description="slideContent.description"
                    :title="slideContent.title"
      />

      <vimeo-player :options="options"
                    :player-height="playerHeight"
                    :player-width="playerWidth"
                    :video-id="slideContent.questionContent.video.getVideoUrl()"
                    @ended="ended"
                    @fullscreenchange="fullScreen"
                    @loaded="onResize"
                    @pause="pause"
                    @playing="play"
      />

    </div>
  </div>
  <game-footer :class="hidden ? 'pre-click-hidden': 'shown'"
               :continue-button-click="finishText"
               :continue-button-text="$t('STATIC.NEXT')"
  />
</template>

<script>
import SlideHeader from '@/components/elements/SlideHeader'
import { POST_SLIDE_ANSWER } from '@/store/actions'
import FeedbackDisplay from '../FeedbackDisplay'
import { mapGetters } from 'vuex'

export default {
  name: 'Video',
  components: { FeedbackDisplay, SlideHeader },
  props: {
    slideContent: {
      type: Object,
      required: true
    }
  },
  emits: ['nextSlide'],
  data: () => ({
    options: {
      muted: false,
      autoplay: true,
    },
    // note defaults 21:9
    playerWidth: (1680 * 0.7),
    playerHeight: (720 * 0.7),
    hidden: true
  }),
  computed: {
    ...mapGetters({ historyLocation: 'getHistoryLocation' }),
    shouldBeShowingFeedback() {
      return this.historyLocation?.isPostAnswer
    },
  },
  mounted() {
    this.setHidden()
    window.addEventListener('click', this.onClickListener)

    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeUnmount() {
    this.setShown()
    window.removeEventListener('resize', this.onResize)
    window.removeEventListener('click', this.onClickListener)
  },
  methods: {
    onClickListener(click) {
      // console.log('click Video listener', click)
      this.setShown()
    },
    onResize() {
      // console.log('hitting on resize?')
      // const videoPlayerWrapper = document.getElementById('video-player-wrapper')

      const maxWidth = Math.min(window.innerWidth * 0.8, window.innerHeight * 0.9)
      // console.log('window.innerHeight', window.innerHeight)
      // console.log('window.innerWidth', window.innerWidth)
      this.playerHeight = maxWidth * 0.5
      this.playerWidth = maxWidth
    },
    finishText() {
      const formData = new FormData()
      this.$store.dispatch(POST_SLIDE_ANSWER, formData).then(response => {
        this.$emit('nextSlide')
      })
    },
    close() {
      this.$emit('nextSlide')
    },
    fullScreen(data) {
      // console.log('full screen', data)
      if (!data.fullScreen) {
        this.hidden = false
      }
    },
    play(data) {
      this.setHidden()
      // console.log('play', data)
    },
    pause(data) {
      this.setShown()
      // console.log('pause', data)
    },
    ended(data) {
      this.setShown()
      // console.log('end', data)
    },
    // toggle(data) {
    // },
    setHidden() {
      const headerWrapperElem = document.getElementById('header-wrapper')
      headerWrapperElem.style.opacity = '0'
      this.hidden = true
    },
    setShown() {
      const headerWrapperElem = document.getElementById('header-wrapper')
      headerWrapperElem.style.opacity = '1'
      this.hidden = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";
@import "~@/assets/css/mission.scss";

.scroll-wrapper {
  // todo: fix actual scroll bars
  overflow: hidden;
}

.mission-wrapper .task-wrapper {
  width: 100%;
  max-width: initial;
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
}

.shown {
  opacity: 1;
  transition: opacity 0.5s ease;
}

.pre-click-hidden {
  opacity: 0;
  transition: opacity 0.5s ease;
}

.video-player-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  width: 100%;
}

:deep(.vimeo-player) {
  width: 100%;
  display: flex;
  justify-content: center;
}

:deep(.mission-title) {
  margin-bottom: 0;
}

@media screen and (max-width: $screen-720p) {
  .mission-wrapper .task-wrapper {
    padding-top: 1rem;
  }
}

@media screen and (max-width: $screen-720p) {
  .mission-wrapper .task-wrapper {
    padding-bottom: 0;
  }
}
</style>
