<template>
  <div class="content-wrapper quiz-wrapper">
    <h1 class="title">Verdien bits met de MediaQuiz</h1>
    <img class="streak-image" :src="StreakImage" />
    <p class="introduction-text">
      In de MediaQuiz verdien je bits als je 3 of meer vragen achter elkaar goed
      beantwoordt. Als je een vraag fout hebt beantwoord, moet je opnieuw spelen
      tot je weer 3 vragen goed hebt om bits te verdienen. Bekijk de vragen en
      bronnen dus goed voordat je antwoord geeft! Bits die je al hebt verdiend,
      blijven bewaard. Ben je klaar met spelen? Druk dan op de knop 'klaar met
      spelen en bits cashen’ en voeg jouw verdiende bits toe aan je
      klassentotaal.
    </p>
  </div>

  <!-- Game Footer -->
  <game-footer
    :continue-button-text="$t('FOOTER.START')"
    :continueButtonClick="startGame"
  />
</template>

<script>
import { useRouter } from "vue-router";
import GameFooter from "@/components/GameFooter";

export default {
  name: "GameIntroductionSlide",
  components: { GameFooter },
  data() {
    return {
      StreakImage: require("@/assets/images/img_streak.png"),
    };
  },
  setup() {
    const router = useRouter();
    function startGame() {
      router.push({ path: "media-quiz" });
    }
    return { startGame };
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";
@import "~@/assets/css/mission.scss";
@import "~@/assets/css/quiz.scss";

.content-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1000px;
  max-width: 90vw;
  margin: 0 auto;
  flex-direction: column;
  flex-grow: 1;
  text-align: center;
  color: var(--answer_default_color);
  font-size: rem(24);
}
.streak-image {
  width: rem(350);
  margin: rem(35);
}
</style>
