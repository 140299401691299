export default class MissionSlideType {
  /**
   * The id of the slide type.
   * @type {number}
   */
  id;

  /**
   * The name of the slide type.
   * @type {string}
   */
  name;

  /**
   * Whether the slide type is static (has no questions to answer).
   * @type {number}
   */
  isStatic;

  constructor(id, name, isStatic) {
    this.id = id
    this.name = name
    this.isStatic = isStatic
  }

  static fromJson(json) {
    if (!json) return null
    return new MissionSlideType(json.id, json.name, json.isStatic)
  }
}
