export const UPDATE_BITS = 'updateBits'
export const SET_MISSION = 'setMission'
export const SET_HISTORY = 'setHistory'
export const SET_HISTORY_LOCATION = 'setHistoryLocation'

export const CLEAR_GAME_STATE = 'clearGameState'
export const SET_SLIDE_CONTENT = 'setSlideContent'

export const SET_WEEK_STATUS = 'setWeekStatus'
export const SET_SESSION = 'setSession'
export const SET_SESSION_IS_LOADING = 'setSessionIsLoading'
// export const PARTIAL_LOGIN = 'partialLogin'
export const ADMIN_CODE = 'adminCode'
export const SET_AWARDED_JOKER = 'setAwardedJoker'
export const SET_AVAILABLE_JOKERS = 'setAvailableJokers'
export const SET_JOKER_RESULT = 'setJokerResult'
export const CLEAR_JOKER_RESULT = 'clearJokerResult'
export const SET_SLIDE_START_TIME = 'setSlideStartTime'
export const SET_BONUS_EARNED = 'setBonusEarned'
export const RESET_SLIDE_BONUSES = 'resetSlideBonuses'
export const CLEAR_JOKER_USED = 'clearJokerUsed'
export const SET_SUBMISSION = 'setSubmission'
export const SET_LOADING = 'setLoading'
export const SET_FEEDBACK_MODAL = 'setFeedbackModal'
export const SET_STREAK = 'setStreak'

export const SET_MEDIA_QUIZ_BITS = 'setMediaQuizBits'
export const ADD_MEDIA_QUIZ_BITS = 'addMediaQuizBits'
export const mutations = {
  updateBits(state, awardedBits) {
    if (state.session) {
      state.session.bitsEarned += awardedBits
    }
  },
  addMediaQuizBits(state, awardedBits) {
    state.mediaQuizBits += awardedBits
  },
  setMediaQuizBits(state, bits) {
    state.mediaQuizBits = bits
  },
  clearGameState(state) {
    state.session = null
  },
  setSlideContent(state, slideContent) {
    state.slideContent = slideContent
    state.submission = null
  },
  setWeekStatus(state, weekStatus) {
    state.weekStatus = weekStatus
  },
  setSession(state, session, resetSession) {
    state.session = session
    if (resetSession) {
      state.submission = null
      state.historyLocation = null
      state.history = null
    }
  },
  setSessionIsLoading(state, isLoading) {
    state.sessionIsLoading = isLoading
  },
  setMission(state, mission) {
    state.mission = mission
  },
  setAwardedJoker(state, joker) {
    state.awardedJoker = joker
  },
  setAvailableJokers(state, availableJokers) {
    state.availableJokers = availableJokers
  },
  setJokerResult(state, jokerResult) {
    if (jokerResult) {
      state.jokerUsed = jokerResult.schoolClassJokerIdUsed
    }
    state.jokerResult = jokerResult
  },
  clearJokerResult(state) {
    state.jokerResult = null
  },
  clearJokerUsed(state) {
    state.jokerUsed = null
  },
  setSlideStartTime(state, time) {
    state.slideStartTime = time
  },
  setBonusEarned(state, bonusEarned) {
    state.bonusEarned = bonusEarned
  },
  setHistory(state, history) {
    state.history = history
  },
  setHistoryLocation(state, historyLocation) {
    state.historyLocation = historyLocation
  },
  setSubmission(state, submission) {
    state.submission = submission
  },
  // partialLogin(state, accessToken) {
  //   LocalDataHandler.setAccessToken(accessToken, '864000')
  //   state.isAuthenticated = true
  // },
  resetSlideBonuses(state) {
    state.jokerResult = null
    state.bonusEarned = null
    state.slideStartTime = Date.now()
  },
  setLoading(state, isLoading) {
    console.log('setLoading', isLoading)
    state.showLoadingScreen = isLoading
  },
  setFeedbackModal(state, showingFeedbackModal) {
    state.showingFeedbackModal = showingFeedbackModal
  },
  setStreak(state, streak) {
    if (streak !== undefined && streak !== null) {
      state.streak = streak
    }
  },
}
