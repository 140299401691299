export default class ExtraGame{
  constructor(id,name,description,link,image,bitsAwarded,createdAt,updatedAt){
    this.id = id
    this.name = name
    this.description = description
    this.link = link
    this.image = image
    this.bitsAwarded = bitsAwarded
    this.createdAt = createdAt
    this.updatedAt = updatedAt
  }

  static parseDataFromObject(data){
    return new ExtraGame(data.id,data.name,data.description,data.link,data.image,data.bitsAwarded,data.createdAt,data.updatedAt)
  }
}