<template>
  <div class="game-complete-wrapper">
    <h2 class="game-title mission-title"> {{ $t('COMPLETE.TITLE') }}</h2>

    <div class="game-complete-content">
      <JokerCard v-if="joker" :joker="joker"/>
      <div class="bits-earned-container">

        <h3 class="bits-earned-title">{{ $t('COMPLETE.BITS_TITLE') }}</h3>
        <div v-if="showBits" class="bits-earned-display">
          <BitsDisplay :amount="bitsToShow"
                       :animate="true"
                       :never-decrease="true" />
        </div>

        <i18n-t class="bits-earned-description mission-text" keypath="COMPLETE.DESCRIPTION_ONE" tag="p">
          &#127775;
        </i18n-t>
        <br>
        <br>
        <i18n-t class="bits-earned-description mission-text" keypath="COMPLETE.DESCRIPTION_TWO" tag="p">
          &#10024;
        </i18n-t>
      </div>
    </div>
    <div class="pink-button menu-button" @click="restart">{{ $t('COMPLETE.MENU_BUTTON') }}</div>
    <Balloons :count="10"/>

  </div>
</template>

<script>
// import { useRoute } from 'vue-router'
// import { CLEAR_GAMESTATE } from '@/store/mutations'
import {
  mapGetters,
  // useStore,
} from 'vuex'
import LocalDataHandler from '@/utils/handlers/LocalDataHandler'
import JokerCard from '@/components/partials/joker/JokerCard'
import BitsDisplay from '../../components/elements/basic/BitsDisplay'
import { ROUTE_NAMES } from '@/router'
import Balloons from '@/components/elements/Balloons'
export default {
  name: 'GameComplete',
  components: {
    Balloons,
    JokerCard,
    BitsDisplay,
  },
  props: {},
  data() {
    return {
      bitsToShow: 0,
      classId: ''
    }
  },
  computed: {
    ...mapGetters({
      bits: 'getBits',
      session: 'getSession',
      slideContent: 'getSlideContent',
      joker: 'getAwardedJoker'
    }),
    showBits() { return this.session && this.session.id !== -1 }
  },
  created() {
    if (this.bitsToShow > 0) {
      LocalDataHandler.saveLocalData('balloon_event', true)
    }
    const key = LocalDataHandler.KEYS.LAST_BITS_EARNED
    if (this.bits !== 0) {
      LocalDataHandler.saveLocalData(key, this.bits)
      this.bitsToShow = this.bits
    } else {
      const stored = LocalDataHandler.getLocalData(key)
      if (stored) {
        this.bitsToShow = stored
      }
    }
  },
  methods: {
    restart() {
      // console.log('this session', this.session)
      const classId = this.session ? this.session.schoolClassId ?? '' : ''
      const isValidMissionCategory = this.slideContent.missionCategory && (this.slideContent.missionCategory.id === 3 || this.slideContent.missionCategory.id === 1)
      if (this.slideContent && this.slideContent.missionCategory && isValidMissionCategory) {
        const queryParam = this.bitsToShow > 0 ? `bitsEarned=${this.bitsToShow}` : ''
        window.location.href = process.env.VUE_APP_ROOT_CMS + `/leraren-portaal/overzicht${classId ? '/' + classId : ''}` + '?' + queryParam
      } else if (this.slideContent && this.slideContent.missionCategory && this.slideContent.missionCategory.id === 2) {
        this.$router.push({ name: ROUTE_NAMES.EXTRAS })
      } else {
        window.location.href = process.env.VUE_APP_ROOT_CMS + `/leraren-portaal/overzicht${classId ? '/' + classId : ''}`
      }
    }
  }

}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";
@import "~@/assets/css/global.scss";
@import "~@/assets/css/mission.scss";

.game-complete-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  color: white;
  align-items: center;
  flex-wrap: nowrap;
  height: 100vh;
  width: 100vw;
}

.game-title {
  margin-top: rem(50);
}

.game-complete-content {
  display: flex;
  justify-content: center;
  width: clamp(calc(0%),rem(1440),calc(95%));
  margin: rem(20) 0;
  gap: rem(30)
}

.bits-earned-container {
  max-width: rem(800);
  width: rem(695);
  height: 100%;
  padding: rem(40);
  background-color: #4D4C89;
  border-radius: rem(20);
  display: flex;
  flex-direction: column;
}

.bits-earned-title {
  color: #FFFFFF;
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
  text-align: center;
}

.bits-earned-display {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: rem(20);
  padding: rem(10) rem(50);
  border-radius: rem(10);
  background-color: #3E3D77;
  width: fit-content;
  margin: rem(50) auto;
}

.bits-earned-icon {
  width: rem(50);
  height: rem(50);
}

.bits-earned-description {
  font-weight: 400;
  margin-bottom: rem(36);
  font-size: rem(24);
  line-height: rem(38);
}

.menu-button {
  width: 280px;
}

@media screen and (max-width: $screen-720p) {
  .game-complete-wrapper {
    margin-bottom: 2rem;
  }
}
</style>
