<template>
  <div class="scroll-wrapper">
    <div class="task-wrapper">
      <FeedbackDisplay v-if="shouldBeShowingFeedback"
                       :custom-feedback-text="currentCard.feedback"
                       :on-press-next="closeFeedback"
                       :was-correct="showCorrectVideo"/>
      <div v-else>
        <slide-header :description="slideContent.description" :title="slideContent.title"/>
        <div class="card-wrapper">
          <phishing-card v-if="animatingCard"
                         id="card-anim"
                         :card-top="0"
                         :card-width-reduction="0"
                         :card-z-index="activeCards.length + 1"
                         :correct-answer="null"
                         :show-result="true"
                         :message-types="messageTypes"
                         :sender-email="animatingCard.sender.sentFrom"
                         :sender-name="animatingCard.sender.name"
                         :subject="animatingCard.subject"
                         :text="animatingCard.text">
          </phishing-card>

          <phishing-card v-for="(card, index) in activeCards"
                         :id="'card-' + index"
                         :key="card.id"
                         :card-top="index"
                         :card-width-reduction="index*2"
                         :card-z-index="activeCards.length - index"
                         :correct-answer="index === 0 ? correctAnswer : null"
                         :show-result="showResult"
                         :is-top-card="index === 0"
                         :message-types="messageTypes"
                         :sender-email="card.emailorPhoneNumber"
                         :sender-name="card.sender"
                         :subject="card.topic"
                         :text="card.message">
          </phishing-card>
        </div>
      </div>
    </div>
  </div>
  <game-footer
    v-if="!shouldBeShowingFeedback"
    :choice-data="{
      positiveChoiceText: $t('FOOTER.PHISHING.TRUE'),
      negativeChoiceText: $t('FOOTER.PHISHING.PHISHING'),
      positiveChoiceClick: () => checkAnswer(messageTypes.real),
      negativeChoiceClick: () => checkAnswer(messageTypes.phishing),
    }"
    :feedback-button-click="showFeedback"
    :feedback-button-text="result && result.feedbackButtonText ? result.feedbackButtonText : ''"
    :feedback-result="result"
    :force-next="shouldBeShowingFeedback"

    :has-feedback-popup="feedbackText"/>

</template>
<script>
import GameFooter from '@/components/GameFooter'
import i18n from '@/setup/i18n/i18n'
import PhishingCard from '@/components/tasks/phishing/PhishingCard'
import gsap from 'gsap'
import SlideHeader from '@/components/elements/SlideHeader'
import { POST_SUB_SLIDE_ANSWER } from '@/store/actions'
import { UPDATE_BITS } from '@/store/mutations'
import { mapGetters } from 'vuex'
import FeedbackDisplay from '../FeedbackDisplay'

const AnswerTypes = {
  real: 1,
  phishing: 0,
}

export default {
  name: 'JudgePhishing',
  components: { FeedbackDisplay, PhishingCard, GameFooter, SlideHeader },
  props: {
    slideContent: {
      type: Object,
      required: true,
    },
  },
  emits: ['nextSlide'],
  data: () => ({
    animatingCard: null,
    currentCard: {},
    // note: these will be spliced after a card has been answered. All slides are still in the slideContent prop
    activeCards: [],
    messageTypes: AnswerTypes,
    feedbackText: '',
    feedbackVideoUrl: '',
    result: null,
    showResult: false,
    showSentFromAddress: false,
  }),
  computed: {
    ...mapGetters({
      session: 'getSession',
      historyLocation: 'getHistoryLocation',
      history: 'getHistory',
      showFeedbackModal: 'getShowFeedbackModal'

    }),
    shouldBeShowingFeedback() {
      return this.historyLocation?.isPostAnswer || this.showFeedbackModal
    },
    correctAnswer() {
      const answers = this.$store.getters.getCorrectAnswers
      console.log('got correct answer', answers)
      if (answers === undefined || answers === null) {
        return null
      }
      return answers
    },
    givenAnswers() {
      /*      if (this.selectedAnswers.length > 0) {
              return this.selectedAnswers
            }*/

      const answers = this.$store.getters.getClientAnswer
      console.log('got given answers', answers)
      if (Array.isArray(answers)) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.selectedAnswer = answers
        return answers
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.selectedAnswers = answers
        return answers
      }
    },
    showCorrectVideo() {
      return this.correctAnswer === this.givenAnswers
    },
  },
  watch: {
    slideContent: {
      immediate: true,
      handler(newVal, oldVal) {
        // console.log('newVal', newVal)
        // console.log('oldVal', oldVal)

        // select the first card when API comes in
        this.activeCards = [...newVal.questionContent.subSlides]

        if (this.historyLocation) {
          this.activeCards.splice(0, this.historyLocation.currentSubSlideIndex)
          this.currentCard = this.activeCards[0]
        } else {
          if (this.session.currentSubSlideIndex) {
            this.activeCards.splice(0, this.session.currentSubSlideIndex)
          }
          this.currentCard = this.activeCards[0]
        }
      },
    },
    historyLocation: {
      immediate: true,
      handler(newVal, oldVal) {
        console.log('got new history location')
        this.activeCards = [...this.slideContent.questionContent.subSlides]

        // console.log('newVal', newVal)
        // console.log('oldVal', oldVal)

        if (newVal) {
          this.activeCards.splice(0, newVal.currentSubSlideIndex)
          this.currentCard = this.activeCards[0]
        } else {
          if (this.session.currentSubSlideIndex) {
            this.activeCards.splice(0, this.session.currentSubSlideIndex)
          }
          this.currentCard = this.activeCards[0]
        }
      },
    }
  },
  // created() {
  // },
  methods: {
    checkAnswer(answerType) {
      this.answer = answerType
      const formData = new FormData()
      formData.append('missionId', this.slideContent.missionId)
      formData.append('slideIndex', this.slideContent.slideIndex)
      // console.log('card', this.currentCard)
      formData.append('subSlideIndex', this.currentCard.id)
      formData.append('answer', answerType)

      this.$store.dispatch(POST_SUB_SLIDE_ANSWER, formData)
        .then(response => {
          // console.log('POST_SUB_SLIDE_ANSWER response', response)
          this.feedbackText = response.feedback
          this.feedbackVideoUrl = response.feedbackVideoUrl
          if (response.isAnswerCorrect) {
            this.result = {
              successPercentage: 1,
              title: i18n.global.tc('FOOTER.GOOD_JOB'),
              // text: response.correctAnswer === AnswerTypes.phishing ? i18n.global.tc('FOOTER.PHISHING.PHISHING') : i18n.global.tc('FOOTER.PHISHING.TRUE'),
              feedbackButtonText: this.feedbackText ? i18n.global.tc('FOOTER.FEEDBACK_BUTTON_TEXT') : i18n.global.tc('FOOTER.NEXT'),
            }
            this.$store.commit(UPDATE_BITS, response.awardedBits)
          } else {
            this.result = {
              successPercentage: 0,
              title: i18n.global.tc('FOOTER.SINGLE_ANSWER.FEEDBACK_TITLE'),
              text: (response.correctAnswer === AnswerTypes.phishing ? i18n.global.tc('FOOTER.PHISHING.PHISHING') : i18n.global.tc('FOOTER.PHISHING.TRUE')),
              feedbackButtonText: this.feedbackText ? i18n.global.tc('FOOTER.FEEDBACK_BUTTON_TEXT') : i18n.global.tc('FOOTER.NEXT'),
            }
          }

          this.correctAnswer = response.correctAnswer
          this.showResult = true
        })
    },
    resetSubSlide() {
      this.correctAnswer = null
      this.currentCard = null
      this.result = null
      this.showSentFromAddress = false
      this.showResult = false
    },
    showNextCard() {
      this.resetSubSlide()
      // Gets the card that was just answered and removes it from activeCards
      this.animatingCard = this.activeCards[0]
      // Remove said card from the active cards array
      this.activeCards.splice(0, 1)

      //Sets new current card
      this.currentCard = this.activeCards[0]
      setTimeout(() => {
        this.tweenCardSwipe(() => {
          this.animatingCard = null
        })
      }, 0)
    },
    showFeedback() {
      if (this.feedbackText || this.feedbackVideoUrl) {
        this.$store.dispatch('showFeedbackModal')
      } else if (this.isLastCard()) {
        this.$emit('nextSlide', 'forceNext')
      } else {
        this.showNextCard()
      }
      //Show feedback modal
    },
    isLastCard() {
      return this.activeCards.length === 1
    },
    closeFeedback() {
      this.$store.dispatch('hideFeedbackModal')
      this.feedbackText = ''
      if (this.isLastCard()) {
        this.$emit('nextSlide', 'forceNext')
      } else if (!this.isLastCard()) {
        this.showNextCard()
      }
    },
    tweenCardSwipe(callback) {
      const scrollBody = document.querySelector('.main-content')
      scrollBody.style.overflowY = 'hidden'
      const targetElement = document.getElementById('card-0')
      targetElement.parent = document.getElementById('card-anim')
      const tween = gsap.to('#card-anim', { duration: 1, rotation: -135, transformOrigin: '-40% 140%' })
      tween.eventCallback('onComplete', callback)
      setTimeout(() => {
        scrollBody.style.overflowY = 'auto'
      }, 1000)
    },
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";
@import "~@/assets/css/mission.scss";

.scroll-wrapper {
  margin-left: 32px;
  margin-right: 32px;
}

.card-wrapper {
  //height: fit-content;
  width: 100%;
  position: relative;
  z-index: 0;

}

</style>
