import i18n from '@/setup/i18n/i18n'

export default class FeedbackResult {
  /**
   * number (between 0 - 1), indicating how much of the answers where correctly answered.
   * @type {number}
   */
  successPercentage;

  /**
   * bold text indicating introducing short or large text answer.
   * @type {string}
   */
  title;

  /**
   * large text explaining what correct answers where on 'improve' & 'incorrect' feedback screens.
   * @type {string|null}
   */
  text;

  constructor(successPercentage, title, text) {
    this.successPercentage = successPercentage
    this.title = title
    this.text = text
  }

  static get GoodJobFeedbackResult() {
    return new FeedbackResult(1, i18n.global.tc('FOOTER.GOOD_JOB'), null)
  }
}
