<template>
  <div class="balloon-holder" :style="cssVars">
    <img
      v-for="index in count"
      :key="index"
      :src="balloons[index % balloons.length]"
      :style="getStyle(index)"
      class="balloon balloon-turn-animation"
      alt=""
    />
  </div>

</template>

<script>
export default {
  name: 'Balloons',
  props: {
    count: {
      type: Number,
      default: 5
    },
    iterations: {
      type: String,
      default: 'infinite'
    }
  },
  data() {
    const balloons = [
      require('@/assets/images/img_balloon_blue.png'),
      require('@/assets/images/img_balloon_red.png')
    ]

    const randoms = []
    for (let i = 0; i < this.count; i++) {
      randoms.push(Math.random())
    }

    return {
      balloons: balloons,
      randoms
    }
  },
  computed: {
    cssVars() {
      return {
        '--balloon-count': this.count,
        '--iterations': this.iterations,
      }
    },
    cssVarsBalloon(index) {
      return {
        '--random-turn-start': Math.random()
      }
    }
  },
  methods: {
    animationTime() {
      return (3 + (Math.random() * 3)).toString() + 's'
    },
    getStyle(index) {
      return {
        'animation-duration': this.animationTime(),
        left: (Math.random() * 100).toString() + '%',
        '--random-turn-start': this.randoms[index]
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

@function count() {
  @return 1000
}

@for $i from 1 through count() {
  .balloon-holder :nth-child(#{$i}n){
    animation-delay: #{$i * 0.5}s;
  }
}

.balloon-holder {
  pointer-events: none;
  z-index: 10;
  @include position(fixed, $top: 0, $right: 0, $bottom: 0, $left: 0);
}

.balloon {
  pointer-events: none;
  animation: balloon-rising 2s cubic-bezier(0.4, 0, 1, 1) 0s var(--iterations), balloon-turn 3s cubic-bezier(0.4, 0, 1, 1) 0s infinite alternate;
  position: absolute;
  opacity: 0.9;
  bottom: -50%;
}

@keyframes balloon-rising {
  from {bottom : -50%;}
  to {bottom: 100%}
}

@keyframes balloon-turn {
  from {transform : rotate(-10deg);}
  to {transform: rotate(10deg)}
}

</style>
