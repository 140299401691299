import MultipleChoiceQuestionContentAnswer from './MultipleChoiceQuestionContentAnswer'
import i18n from '@/setup/i18n/i18n'
import FeedbackResult from '../submission/feedbackResult'

export default class DragAndDropQuestionContent {
  /**
   * The string array for the question, with null spots to be filled in by the answers
   * @type {String|null[]}
   */
  question

  /**
   * @type {MultipleChoiceQuestionContentAnswer[]}
   */
  answers;

  constructor(question, answers) {
    this.question = question
    this.answers = answers
  }

  static parseFromDataObject(data) {
    return new DragAndDropQuestionContent(
      data.question,
      data.answers.map(answer => MultipleChoiceQuestionContentAnswer.parseFromDataObject(answer)))
  }

  getFeedbackResult(userAnswer, correctAnswer) {
    let answer = ''
    console.log('getting feedback result')
    if (this.isAnswerCorrect(userAnswer, correctAnswer)) {
      return FeedbackResult.GoodJobFeedbackResult
    }

    let answerIndex = 0
    this.question.map(segment => {
      if (segment == null) {
        answer += ` <span class="correctAnswer">${this.answers.find((answer) => answer.id === correctAnswer[answerIndex]).answer} </span>`
        answerIndex++
      } else {
        answer += segment
      }
    })
    return new FeedbackResult(this.getSuccesPercentage(userAnswer, correctAnswer), i18n.global.tc('FOOTER.SINGLE_ANSWER.FEEDBACK_TITLE'), answer)
  }

  getSuccesPercentage(userAnswer, correctAnswer) {
    const gotCorrect = userAnswer.reduce((acc, answer, index) => {
      //check it's included in the same position
      if (answer == correctAnswer[index]) {
        return acc + 1
      }
      return acc
    }, 0)
    return gotCorrect / correctAnswer.length
  }

  /**
   * Get if answer is fully correct.
   * @param {number[]} userAnswer
   * @param {number[]} correctAnswer
   * @return {boolean} if the answer is correct.
   */
  isAnswerCorrect(userAnswer, correctAnswer) {
    //check every element is in the same order
    const sameLength = userAnswer.length === correctAnswer.length
    if (!sameLength) {
      return false
    }
    return userAnswer.every((answer, index) => answer === correctAnswer[index])
  }
}
