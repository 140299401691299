<template>
  <div class="scroll-wrapper">

    <div class="task-wrapper">
      <FeedbackDisplay v-if="shouldBeShowingFeedback"
                       :on-press-next="closeFeedback"
                       :was-correct="showCorrectVideo"/>
      <div v-else>
        <slide-header :description="slideContent.description" :title="slideContent.title"/>
        <div class="drag-and-drop-section">
          <div v-for="(segment, segmentIndex) in slideContent.questionContent.question"
               :key="'drag-and-drop-segment-' + segmentIndex"
               class="drag-and-drop-segment"
          >
            <div v-if="segment === null"
                 :id="'dropzone-answer-' + segmentIndex"
                 :ref="'dropzone-answer-' + segmentIndex"
                 :class="clickedOrDraggedAnswer || (dropzones[segmentIndex] && dropzones[segmentIndex].occupiedByAnswer) ? 'dropzone-answer-clickable' : null"
                 :data-dropzone-id="segmentIndex"
                 class="dropzone-answer"
                 @click="setAnswerInDropzone(segmentIndex)"
            >
              <div
                v-if="dropzones[segmentIndex] && dropzones[segmentIndex].occupiedByAnswer"
                class="dropzone-answer-occupied"
              >
                <div
                  :class="getDraggableWordCorrectnessClasses(dropzones[segmentIndex])"
                  class="draggable-word"
                >
                  {{ dropzones[segmentIndex].occupiedByAnswer.answer }}
                </div>
              </div>
            </div>
            <div v-else class="drag-and-drop-text-segment" v-html="segment"></div>
          </div>
        </div>

        <div v-if="!result" ref="draggable-words-list-container" class="draggable-words-list-container">
          <div
            v-for="(answer) in answerOptions"
            :key="answer.id"
            class="draggable-word-container"
          >
            <div
              :id="'draggable-word-' + answer.id"
              :ref="'draggable-word-' + answer.id"
              :data-draggable-word-id="answer.id"
              class="draggable-word"
              @mousedown="onDraggableWordMouseDown($event, answer)"
              @touchstart="onDraggableWordTouchStart($event, answer)"
            >
              {{ answer.answer }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <game-footer v-if="!shouldBeShowingFeedback"
               :continue-button-click="postSlideAnswer"
               :continue-button-disabled="hasUnoccupiedDropzones"

               :continue-button-text="$t('FOOTER.MULTIPLE_ANSWERS.CONTINUE_BUTTON_TEXT')"
               :feedback-button-click="showFeedback"
               :feedback-button-text="$t('FOOTER.FEEDBACK_BUTTON_TEXT')"
               :feedback-result="result"
               :force-next="shouldBeShowingFeedback"
               :has-feedback-popup="!!feedbackText"
  />
</template>

<script>
import { UPDATE_BITS } from '@/store/mutations'
import GameFooter from '@/components/GameFooter'
import SlideHeader from '@/components/elements/SlideHeader'
import { POST_SLIDE_ANSWER, POST_SLIDE_ANSWER_MEDIA_QUIZ } from '@/store/actions'
import { mapGetters } from 'vuex'
import DomHandler from '@/utils/handlers/DomHandler'
import MissionSlide from '../../models/mission/slide/MissionSlide'
import FeedbackDisplay from './FeedbackDisplay'

export default {
  name: 'DragAndDrop',
  components: { FeedbackDisplay, SlideHeader, GameFooter },
  props: {
    slideContent: {
      type: MissionSlide,
      required: true
    },
    isQuiz: {
      type: Boolean,
      required: false,
      default: false
    },
    classId: {
      type: String,
      required: false,
      default: null
    }
  },
  emits: ['nextSlide'],
  data: () => ({
    answerOptions: [],
    usedAnswers: [],
    dropzones: {},
    //correctAnswers: [],

    // whether an answer is being dragged or is 'clicked' (active for click / click system)
    answerBeingDragged: false,

    clickedOrDraggedAnswer: null,

    result: null,
    feedbackTitle: '',
    feedbackText: '',
    feedbackVideoUrl: '',
    startTime: new Date(),
    // response: null,
  }),
  computed: {
    ...mapGetters({
      jokerResult: 'getJokerResult',
      historyLocation: 'getHistoryLocation',
      showFeedbackModal: 'getShowFeedbackModal'
    }),
    correctAnswers() {
      const answers = this.$store.getters.getCorrectAnswers
      if (answers === undefined || answers === null) {
        return []
      }
      console.log('got correct answers', answers)
      if (Array.isArray(answers)) {
        return answers
      } else {
        return [answers]
      }
    },
    givenAnswers() {
      /*      if (this.selectedAnswers.length > 0) {
              return this.selectedAnswers
            }*/

      const answers = this.$store.getters.getClientAnswer
      console.log('got given answers', answers)
      if (answers == null) {
        return []
      }

      if (Array.isArray(answers)) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.selectedAnswers = answers
        return answers
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.selectedAnswers = [answers]
        return [answers]
      }
    },
    showCorrectVideo() {
      return this.correctAnswers.every((value, index) => this.givenAnswers[index] === value)
    },
    hasUnoccupiedDropzones() {
      return Object.keys(this.dropzones).length !== this.usedAnswers.length
    },
    answeredHasBeenPosted() {
      return this.correctAnswers.length > 0
    },
    isMobile() {
      return DomHandler.isMobile()
    },
    shouldBeShowingFeedback() {
      return this.historyLocation?.isPostAnswer || this.showFeedbackModal
    },
  },
  watch: {
    givenAnswers: {
      immediate: true,
      deep: true,
      handler: function(newVal, oldVal) {
        console.log('givenAnswers newVal', newVal)
        console.log('givenAnswers oldVal', oldVal)

        this.updateDropZonesFromHistory(newVal)
      }
    },
    jokerResult: {
      deep: true,
      handler: function(newVal, oldVal) {
        // console.log('jokerResult newVal', newVal)
        // console.log('jokerResult oldVal', oldVal)

        // console.log('newVal.wrongAnswersToRemove', newVal.wrongAnswersToRemove)

        if (newVal && newVal.wrongAnswersToRemove) {
          // let answerOptions = []
          let answerOptions = [...this.answerOptions]
          let usedAnswers = [...this.usedAnswers]

          // console.log('this.answerOptions', this.answerOptions)
          // console.log('this.usedAnswers', this.usedAnswers)

          // todo: check if this is full-proof
          this.clickedOrDraggedAnswer = null
          this.answerBeingDragged = null

          newVal.wrongAnswersToRemove.forEach((answerToRemove) => {
            // if (this.clickedOrDraggedAnswer && this.clickedOrDraggedAnswer.id === answerToRemove.id) {
            //   this.clickedOrDraggedAnswer = null
            // }

            answerOptions = answerOptions.filter((answerOption) => {
              return answerOption.id !== answerToRemove.id
            })

            let dropzoneOccupiedByRemovedAnswer = null
            usedAnswers = usedAnswers.filter((usedAnswer) => {
              if (usedAnswer.id === answerToRemove.id) {
                dropzoneOccupiedByRemovedAnswer = usedAnswer.id
              }
              return usedAnswer.id !== answerToRemove.id
            })

            if (dropzoneOccupiedByRemovedAnswer !== null) {
              // console.log('dropzoneOccupiedByRemovedAnswer', dropzoneOccupiedByRemovedAnswer)
              const usedDropzone = Object.values(this.dropzones).find((dropzone) => dropzone.occupiedByAnswerId === dropzoneOccupiedByRemovedAnswer)
              // console.log('usedDropzone', usedDropzone)

              if (usedDropzone) {
                usedDropzone.occupiedByAnswerId = null
                usedDropzone.occupiedByAnswer = null
              }
            }
          })

          // console.log('#### POST ADJUSTMENT ####')
          // console.log('#### POST ADJUSTMENT ####')
          // console.log('#### POST ADJUSTMENT ####')
          // console.log('#### POST ADJUSTMENT ####')
          // console.log('this.answerOptions', this.answerOptions)
          // console.log('this.usedAnswers', this.usedAnswers)
          // console.log('this.usedAnswers', this.dropzones)

          this.answerOptions = answerOptions
          this.usedAnswers = usedAnswers
        }
      }
    },
    slideContent: {
      // deep: true,
      immediate: true,
      handler: function(newVal, oldVal) {
        const answers = newVal.questionContent.answers
        answers.sort(() => Math.random() - 0.5)
        this.answerOptions = answers
        const dropzones = {}
        console.log('doing dropzones updating')
        let dropZoneIndexIterator = 0
        newVal.questionContent.question.forEach((segment, index) => {
          if (segment === null) {
            dropzones[index] = {
              questionSegmentId: index,
              occupiedByAnswer: null,
              occupiedByAnswerId: null,
              dropzoneIndex: dropZoneIndexIterator,
            }
            dropZoneIndexIterator++
          }
        })
        this.dropzones = dropzones
        if (this.givenAnswers != null) {
          this.updateDropZonesFromHistory(this.givenAnswers)
        }
      }
    }
  },
  methods: {
    updateDropZonesFromHistory(newVal) {
      if (newVal && newVal.length > 0) {
        for (let i = 0; i < newVal.length; i++) {
          const toAdd = newVal[i]
          const answer = this.answerOptions.find((answer) => answer.id === toAdd)
          const dropzonekey = Object.entries(this.dropzones).find(([index, dropzone]) => dropzone.dropzoneIndex === i)
          if (answer && dropzonekey) {
            this.dropzones[dropzonekey[0]].occupiedByAnswerId = answer.id
            this.dropzones[dropzonekey[0]].occupiedByAnswer = answer
          }
        }

        this.answerOptions = this.answerOptions.filter((answer) => !newVal.includes(answer.id))

        /*          this.answerOptions = [...newVal]
                  this.usedAnswers = [...newVal]*/
      }
    },
    getDropzoneRefs() {
      const dropzones = {}
      for (const [key, value] of Object.entries(this.$refs)) {
        if (key.startsWith('dropzone')) {
          dropzones[key] = value
        }
      }
      return dropzones
    },
    getDraggableWordRefs() {
      const draggableWords = {}
      for (const [key, value] of Object.entries(this.$refs)) {
        if (key.startsWith('draggable-word')) {
          draggableWords[key] = value
        }
      }
      return draggableWords
    },
    clearAllClickClassFromDraggableWordRefs() {
      const draggableWordsRefs = this.getDraggableWordRefs()
      for (const i in draggableWordsRefs) {
        // todo: fix $refs structure so that each entry is not array of length 1 and requires [0] to access. NOTE PROBLEM SINCE UPDATING TO VUE 3.2 (from 3.0)
        const dragWordRef = draggableWordsRefs[i][0]
        if (dragWordRef) {
          dragWordRef.classList.remove('draggable-word-selected-by-click')
        }
      }
    },
    onDraggableWordTouchStart(touchStartEvent, answer) {
      // console.log('touchStartEvent', touchStartEvent)
      touchStartEvent.preventDefault()
      if (this.answeredHasBeenPosted) return

      // const draggableWordContainerRef = this.$refs['draggable-words-list-container']
      // todo: fix $refs structure so that each entry is not array of length 1 and requires [0] to access. NOTE PROBLEM SINCE UPDATING TO VUE 3.2 (from 3.0)
      const draggableWordRef = this.$refs['draggable-word-' + answer.id][0]

      const draggableWordRefHalfWidth = draggableWordRef.clientWidth / 2
      const draggableWordRefHalfHeight = draggableWordRef.clientHeight / 2

      const touchMoveHandler = (touchMoveEvent) => {
        // console.log('touchMoveEvent', touchMoveEvent)
        touchMoveEvent.preventDefault()

        if ((touchMoveEvent.timeStamp - touchStartEvent.timeStamp) > 100) {
          if (!this.answerBeingDragged) {
            draggableWordRef.classList.add('being-dragged')
            draggableWordRef.classList.remove('draggable-word-selected-by-click')
            this.answerBeingDragged = true
          } else {
            const newX = touchMoveEvent.touches[0].clientX - draggableWordRefHalfWidth
            const newY = touchMoveEvent.touches[0].clientY - draggableWordRefHalfHeight

            draggableWordRef.style.left = newX.toString() + 'px'
            draggableWordRef.style.top = newY.toString() + 'px'
          }
        }
      }

      const touchEndHandler = (touchEndEvent) => {
        // console.log('touchEndEvent', touchEndEvent)
        touchEndEvent.preventDefault()
        window.removeEventListener('touchmove', touchMoveHandler)
        window.removeEventListener('touchend', touchEndHandler)

        if (!this.answerBeingDragged) {
          this.clearAllClickClassFromDraggableWordRefs()

          draggableWordRef.classList.add('draggable-word-selected-by-click')

          this.removeBeingDraggedCss(draggableWordRef)
          return
        }
        this.answerBeingDragged = false

        const hitDropzones = []
        let droppedInDropZone = false
        for (const key in dropzoneRefs) {
          // todo: fix $refs structure so that each entry is not array of length 1 and requires [0] to access. NOTE PROBLEM SINCE UPDATING TO VUE 3.2 (from 3.0)
          const dropzoneRef = dropzoneRefs[key][0]
          if (this.checkIfAnswerDroppedOnDropzone(draggableWordRef, dropzoneRef)) {
            hitDropzones.push(dropzoneRef)
          }
        }

        // console.log('hitDropzones', hitDropzones)

        // if multiple dropzones are hit, prioritize the one that is empty
        if (hitDropzones.length) {
          let toDropInDropzone = hitDropzones[0]
          // console.log('toDropInDropzone', toDropInDropzone)
          if (hitDropzones.length > 1) {
            for (const key in hitDropzones) {
              const hitDropzone = hitDropzones[key]
              if (!hitDropzone.firstChild.classList || !hitDropzone.firstChild.classList.contains('dropzone-answer-occupied')) {
                toDropInDropzone = hitDropzone
                break
              }
              // console.log('hitDropzone', hitDropzone)
            }
          }
          // console.log('DROPPED ' + draggableWordRef.id + ' on ' + toDropInDropzone.id + ' dataset: ' + toDropInDropzone.dataset.dropzoneId)

          droppedInDropZone = true

          this.removeBeingDraggedCss(draggableWordRef)
          this.setAnswerInDropzone(toDropInDropzone.dataset.dropzoneId)
          // break
        }

        if (!droppedInDropZone) {
          this.removeBeingDraggedCss(draggableWordRef)
          this.clickedOrDraggedAnswer = null
        }
      }

      window.addEventListener('touchmove', touchMoveHandler, {
        passive: false,
      })
      window.addEventListener('touchend', touchEndHandler)

      this.clickedOrDraggedAnswer = answer.id === this.clickedOrDraggedAnswer?.id ? null : answer
      const dropzoneRefs = this.getDropzoneRefs()

      // touchMoveHandler(touchStartEvent, true)
    },
    onDraggableWordMouseDown(mouseDownEvent, answer) {
      // console.log('mouseDownEvent', mouseDownEvent)
      if (this.answeredHasBeenPosted) return
      const mouseMoveHandler = (e, isInitial) => {
        if (!isInitial) {
          this.answerBeingDragged = true
        }

        const newX = mouseDownX - e.clientX
        const newY = mouseDownY - e.clientY

        const rect = draggableWordRef.getBoundingClientRect()
        draggableWordRef.style.left = (rect.left - newX).toString() + 'px'
        draggableWordRef.style.top = (rect.top - newY).toString() + 'px'

        mouseDownX = e.clientX
        mouseDownY = e.clientY
      }

      const mouseUpHandler = (e) => {
        window.removeEventListener('mousemove', mouseMoveHandler)
        window.removeEventListener('mouseup', mouseUpHandler)

        if (!this.answerBeingDragged) {
          this.clearAllClickClassFromDraggableWordRefs()

          draggableWordRef.classList.add('draggable-word-selected-by-click')
          this.removeBeingDraggedCss(draggableWordRef)
          return
        }
        this.answerBeingDragged = false

        const hitDropzones = []
        let droppedInDropZone = false
        for (const key in dropzoneRefs) {
          // todo: fix $refs structure so that each entry is not array of length 1 and requires [0] to access. NOTE PROBLEM SINCE UPDATING TO VUE 3.2 (from 3.0)
          const dropzoneRef = dropzoneRefs[key][0]
          if (this.checkIfAnswerDroppedOnDropzone(draggableWordRef, dropzoneRef)) {
            hitDropzones.push(dropzoneRef)
          }
        }

        // console.log('hitDropzones', hitDropzones)

        // if multiple dropzones are hit, prioritize the one that is empty
        if (hitDropzones.length) {
          let toDropInDropzone = hitDropzones[0]
          // console.log('toDropInDropzone', toDropInDropzone)
          if (hitDropzones.length > 1) {
            for (const key in hitDropzones) {
              const hitDropzone = hitDropzones[key]
              if (!hitDropzone.firstChild.classList || !hitDropzone.firstChild.classList.contains('dropzone-answer-occupied')) {
                toDropInDropzone = hitDropzone
                break
              }
              // console.log('hitDropzone', hitDropzone)
            }
          }
          // console.log('DROPPED ' + draggableWordRef.id + ' on ' + toDropInDropzone.id + ' dataset: ' + toDropInDropzone.dataset.dropzoneId)

          droppedInDropZone = true

          this.removeBeingDraggedCss(draggableWordRef)
          this.setAnswerInDropzone(toDropInDropzone.dataset.dropzoneId)
          // break
        }

        if (!droppedInDropZone) {
          this.removeBeingDraggedCss(draggableWordRef)
          this.clickedOrDraggedAnswer = null
        }
      }

      window.addEventListener('mousemove', mouseMoveHandler)
      window.addEventListener('mouseup', mouseUpHandler)

      const draggableWordContainerRef = this.$refs['draggable-words-list-container']
      console.log('draggableWordContainerRef', draggableWordContainerRef)
      const mouseXInDraggableWordContainerRef = mouseDownEvent.clientX - draggableWordContainerRef.offsetLeft
      const mouseYInDraggableWordContainerRef = mouseDownEvent.clientY - draggableWordContainerRef.offsetTop

      // todo: fix $refs structure so that each entry is not array of length 1 and requires [0] to access. NOTE PROBLEM SINCE UPDATING TO VUE 3.2 (from 3.0)
      const draggableWordRef = this.$refs['draggable-word-' + answer.id][0]
      draggableWordRef.style.left = (mouseDownEvent.clientX - mouseXInDraggableWordContainerRef).toString() + 'px'
      draggableWordRef.style.top = (mouseDownEvent.clientY - mouseYInDraggableWordContainerRef).toString() + 'px'
      draggableWordRef.classList.add('being-dragged')

      // let mouseDownX = mouseDownEvent.clientX - (draggableWordRef.clientWidth / 2)
      // let mouseDownY = mouseDownEvent.clientY - (draggableWordRef.clientHeight / 2)

      let mouseDownX = (mouseDownEvent.clientX - mouseXInDraggableWordContainerRef) + (draggableWordRef.clientWidth / 2)
      let mouseDownY = (mouseDownEvent.clientY - mouseYInDraggableWordContainerRef) + (draggableWordRef.clientHeight / 2)

      this.clickedOrDraggedAnswer = answer.id === this.clickedOrDraggedAnswer?.id ? null : answer
      const dropzoneRefs = this.getDropzoneRefs()

      mouseMoveHandler(mouseDownEvent, true)
    },
    removeBeingDraggedCss(draggableWordRef) {
      draggableWordRef.style.removeProperty('left')
      draggableWordRef.style.removeProperty('top')
      draggableWordRef.classList.remove('being-dragged')
    },
    checkIfAnswerDroppedOnDropzone(draggableWordRef, dropzoneRef) {
      const domRectDraggableWord = draggableWordRef.getBoundingClientRect()
      const domRectDropzone = dropzoneRef?.getBoundingClientRect()
      // TODO: fix this, it's a temporary fix for the issue where the dropzone is not found
      if (!domRectDropzone) window.location.reload()

      return !(
        domRectDraggableWord.top > domRectDropzone.bottom ||
        domRectDraggableWord.right < domRectDropzone.left ||
        domRectDraggableWord.bottom < domRectDropzone.top ||
        domRectDraggableWord.left > domRectDropzone.right
      )
    },
    setAnswerInDropzone(dropZoneIndex) {
      if (this.answeredHasBeenPosted) return
      const dropzone = this.dropzones[dropZoneIndex]
      const answerToDeOccupyIndex = this.usedAnswers.findIndex((answer) => dropzone.occupiedByAnswerId === answer.id)
      const answerToDeOccupy = answerToDeOccupyIndex === -1 ? null : this.usedAnswers[answerToDeOccupyIndex]
      if (!this.clickedOrDraggedAnswer && !answerToDeOccupy) {
        // no answer to be dropped selected, and no previous answer in the dropbox. Do nothing.
        return
      }
      // console.log('setAnswerInDropzone dropZoneIndex', dropZoneIndex)

      let usedAnswers = [...this.usedAnswers]
      let availableAnswers = [...this.answerOptions]

      if (answerToDeOccupy) {
        // make previous answer in this dropbox available again
        // usedAnswers = usedAnswers.splice(answerToDeOccupyIndex, 1)
        usedAnswers = usedAnswers.filter((answer) => answer.id !== answerToDeOccupy.id)
        availableAnswers.push(answerToDeOccupy)
      }

      // if an available answer is 'selected', 'drop' it
      if (this.clickedOrDraggedAnswer) {
        const selectedAnswer = { ...this.clickedOrDraggedAnswer }

        dropzone.occupiedByAnswerId = selectedAnswer.id
        dropzone.occupiedByAnswer = selectedAnswer

        availableAnswers = availableAnswers.filter((answer) => this.clickedOrDraggedAnswer.id !== answer.id)
        usedAnswers.push(selectedAnswer)

        this.clickedOrDraggedAnswer = null
      } else {
        // make dropzone non-occupied again and free the answer
        dropzone.occupiedByAnswerId = null
        dropzone.occupiedByAnswer = null
      }

      this.usedAnswers = usedAnswers
      this.answerOptions = availableAnswers
    },
    postSlideAnswer() {
      let storeDispatchFunction

      const formData = new FormData()
      if (this.isQuiz) {
        storeDispatchFunction = POST_SLIDE_ANSWER_MEDIA_QUIZ
        const schoolData = localStorage.getItem('schoolClass')
        const timeSpend = new Date() - this.startTime
        formData.append('timeTakenInMilliseconds', timeSpend)
        if (schoolData) {
          const schoolDataParsed = JSON.parse(schoolData)
          formData.append('schoolClassId', schoolDataParsed.schoolClass.id)
        }
        formData.append('mediaQuizSlideId', this.slideContent.mediaQuizSlideId)
        if (this.classId !== null) {
          formData.append('classId', this.classId)
        }
      }
      // mission
      else {
        storeDispatchFunction = POST_SLIDE_ANSWER
        formData.append('missionId', this.slideContent.missionId)
        formData.append('slideIndex', this.slideContent.slideIndex)
      }

      const userAnswer = []

      // prepare answers in the right answer array for backend
      for (const [key, dropzone] of Object.entries(this.dropzones)) {
        userAnswer.push(dropzone.occupiedByAnswerId)
        formData.append(`answer[${dropzone.dropzoneIndex}]`, dropzone.occupiedByAnswerId)
      }

      this.$store.dispatch(storeDispatchFunction, formData)
        .then(response => {
          this.result = this.slideContent.questionContent.getFeedbackResult(userAnswer, response.correctAnswer)
          // this.response = response
          this.correctAnswers = response.correctAnswer

          /*      let correctAnswer = ''
                 if (response.isAnswerCorrect) {
                   /!**
                    * Full Correct answer
                    *!/
                   this.result = {
                     successPercentage: 1,
                     title: i18n.global.tc('FOOTER.GOOD_JOB'),
                   }
                 } else {
                   /!**
                    * Not a full wrong answer, but might be  will activate on 1 wrong answer
                    *!/
                   let answerIndex = 0
                   this.slideContent.questionContent.question.map(segment => {
                     if (segment === null) {
                       correctAnswer += ' <span class="correct-answer">' +
                         this.slideContent.questionContent.answers.find(elem => elem.id === this.correctAnswers[answerIndex]).answer +
                         ' </span>'
                       answerIndex++
                     } else {
                       correctAnswer += segment
                     }
                   })
                   this.result = {
                     successPercentage: this.getSuccessPercentage(),
                     title: i18n.global.tc('FOOTER.SINGLE_ANSWER.FEEDBACK_TITLE'),
                     text: correctAnswer,
                   }

                   if (this.isQuiz && this.isMobile) {
                     // mobile mediaquiz will use the feedback modal to show the correct answer, not feedback
                     this.feedbackTitle = i18n.global.tc('FOOTER.SINGLE_ANSWER.FEEDBACK_TITLE')
                     this.feedbackText = correctAnswer
                   }
                 }*/

          this.feedbackText = response.feedback
          this.feedbackVideoUrl = response.feedbackVideoUrl

          if (!this.isQuiz) {
            this.$store.commit(UPDATE_BITS, response.awardedBits)
          }

          this.$nextTick().then(() => {
            const mainContent = document.getElementById('main-content')
            if (mainContent) {
              mainContent.scrollTop = mainContent.scrollHeight
            }
          })
        })
    },
    showFeedback() {
      if (this.feedbackText || this.feedbackVideoUrl) {
        this.$store.dispatch('showFeedbackModal')
      } else {
        this.$emit('nextSlide', this.result && this.result.successPercentage === 1)
      }
    },
    closeFeedback() {
      this.$store.dispatch('hideFeedbackModal')
      this.$emit('nextSlide', this.result && this.result.successPercentage === 1)
    },
    getDraggableWordCorrectnessClasses(dropzone) {
      // API hasn't provided the correct answers yet as the check answer call was not yet made
      if (this.correctAnswers.length === 0) return ''

      // todo fix joker wrong answer removal soon
      // todo fix joker wrong answer removal soon
      // todo fix joker wrong answer removal soon
      // if (!this.answerOptions.includes(id)) {
      //   return 'hide'
      // }
      const isAnswerCorrect = this.correctAnswers[dropzone.dropzoneIndex] === dropzone.occupiedByAnswerId

      return isAnswerCorrect ? 'draggable-word-correct' : 'draggable-word-incorrect'
    },
    getSuccessPercentage() {
      if (this.correctAnswers.length === 0) return -1

      let correctlyAnsweredQuestions = 0

      for (const i in this.dropzones) {
        const dropzone = this.dropzones[i]
        if (this.correctAnswers[dropzone.dropzoneIndex] === dropzone.occupiedByAnswerId) {
          correctlyAnsweredQuestions++
        }
      }
      return correctlyAnsweredQuestions / this.correctAnswers.length
    }
  }
}

</script>

<style lang="scss" scoped>
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";
@import "~@/assets/css/mission.scss";
@import "~@/assets/css/quiz.scss";

$draggable-word-width-720p: 250px;
$draggable-word-height-720p: 45px;

$draggable-word-width-widescreen: 250px;
$draggable-word-height-widescreen: 50px;

$draggable-word-width-mobile: 200px;
$draggable-word-height-mobile: 34px;

.drag-and-drop-section {
  margin-bottom: rem(75);

  @media screen and (min-width: $screen-xl) {
    margin-bottom: rem(20);
  }
}

.drag-and-drop-segment {
  display: inline;

  .drag-and-drop-text-segment {
    display: inline;
    font-size: rem(20);
    line-height: rem(40);
    margin-left: rem(7);
    margin-right: rem(7);
    @media screen and (min-width: $screen-lg-minus) {
      line-height: rem(55);
    }

    @media screen and (min-width: $screen-xl) {
      line-height: rem(40);
    }
  }

  .dropzone-answer {
    min-width: $draggable-word-width-widescreen;
    min-height: $draggable-word-height-widescreen;
    display: inline-block;
    vertical-align: middle;
    @include box-shadow(0, rem(5), rem(2), #000000, rem(-5), true);
    margin: 0 rem(5);
    transition: color 0.2s, background-color 0.2s;
    transform: translateY(-4px);

    // Border
    border: double 2px transparent;
    border-radius: rem(15);
    background-image: linear-gradient(#353467, #353467), linear-gradient(#221f45, #5655a1);
    background-origin: border-box;
    background-clip: content-box, border-box;

    &.dropzone-answer-clickable {
      cursor: pointer;
    }

    .dropzone-answer-occupied {
      cursor: pointer;
    }

    .draggable-word-correct {
      color: #FFFFFF;
      background-color: var(--answer_correct_color);
    }

    .draggable-word-incorrect {
      color: #FFFFFF;
      background-color: var(--answer_incorrect_color);
    }

    .removed-choice {
      background-color: #9590A4;
    }
  }
}

.draggable-words-list-container {
  display: grid;
  padding: rem(5);

  @media screen and (min-width: $screen-sm-minus) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    margin: auto;
  }

  @media screen and (min-width: $screen-lg-minus) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }

  @media screen and (min-width: $screen-xl) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    grid-column-gap: rem(15);
    grid-row-gap: rem(15);
  }

  grid-column-gap: rem(30);
  grid-row-gap: rem(30);
  width: fit-content;
  margin: auto;

  .draggable-word {
    &.draggable-word-selected-by-click {
      background-color: gray;
    }

    &.being-dragged {
      position: fixed;
    }
  }

  .removed-choice {
    background-color: #9590A4;
    pointer-events: none;
  }
}

// todo test, if dragging styling is okay even if width exceeds min-width
.draggable-word-container {
  min-width: $draggable-word-width-widescreen;
  min-height: $draggable-word-height-widescreen;
}

.draggable-word {
  min-width: $draggable-word-width-widescreen;
  min-height: $draggable-word-height-widescreen;
  padding: rem(7);
  border-radius: rem(15);
  background-color: #FFFFFF;
  display: flex;
  color: var(--text_color);
  justify-content: center;
  align-items: center;
  font-size: rem(24);
  line-height: rem(24);
  cursor: grab;
  font-weight: 800;
  user-select: none;
  @include box-shadow(rem(3.5), rem(3.5), 0, rgba(0, 0, 0, .2));
}

.hide {
  display: none;
}

@media screen and (max-width: $screen-720p) {
  .drag-and-drop-segment .dropzone-answer {
    min-width: $draggable-word-width-720p;
    min-height: $draggable-word-height-720p;
  }

  .draggable-word-container {
    min-width: $draggable-word-width-720p;
    min-height: $draggable-word-height-720p;

  }

  .draggable-word {
    font-size: rem(20);
  }
}

// Pure mobile
@media screen and (max-width: $screen-sm) {
  .drag-and-drop-section {
    border-radius: rem(12);
    background-color: #25469B;
    padding: rem(5);
    margin-bottom: rem(15);
  }

  .drag-and-drop-segment {
    .drag-and-drop-text-segment {
      font-size: rem(18);
      line-height: rem(30);
      margin: 0 0.15rem;
    }

    .dropzone-answer {
      min-width: $draggable-word-width-mobile;
      min-height: $draggable-word-height-mobile;
    }
  }

  .draggable-words-list-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: rem(13);
  }

  .draggable-word-container {
    min-width: unset;
    min-height: $draggable-word-height-mobile;
  }

  .draggable-word {
    font-size: rem(15);
    min-width: unset;
    min-height: $draggable-word-height-mobile;
    border-radius: 0.9375rem;
  }
}

@media screen and (max-width: $screen-mobile) {
  .scroll-wrapper {
    margin-top: 16px;
  }
}

// landscape mobile
@media (orientation: landscape) and (max-height: 500px) {
  .quiz-slide {
    .task-wrapper {
      padding-top: 16px;
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  .drag-and-drop-section {
    margin-bottom: 16px;
  }
  .drag-and-drop-text-segment {
    font-size: 18px !important;
    line-height: 36px !important;
  }
  .dropzone-answer {
    min-height: 40px !important;
  }

  .draggable-words-list-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .draggable-word-container {
      //min-width: 200px;
      min-width: 40px;
      min-height: 40px;

      .draggable-word {
        font-size: 18px;
        //min-width: 200px;
        min-width: 40px;
        min-height: 40px;
      }
    }
  }
}
</style>
