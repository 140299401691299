<template>
  <header class="extras-header">
    <div class="logo-wrapper">
      <InlineSvg :src="require('@/assets/images/img_logo_extra.svg')" alt="Logo" class="logo" />
    </div>
    <div v-if="onWeek" class="center-wrapper">
      <img class="week-logo" src="@/assets/images/ic_logo_week-of-mediawisdom@2x.png">
      <i18n-t class="time-left-text" keypath="EXTRA_GAMES.TIME_LEFT" tag="p">
        <template #time>
          <span class="bold"> {{ daysTillEnd }}d {{ hoursTillEnd }}u </span>
        </template>
      </i18n-t>
    </div>
    <div v-if="loggedInData && onWeek" class="logged-in-wrapper">
      <div class="top-row" @click="$emit('logout')">
        <div class="logged-in-class">
          {{ loggedInData.schoolClass.name }}
        </div>
        <img src="@/assets/icons/logout.svg" class="logout-icon" >
      </div>
      <BitsDisplay v-if="onWeek" :amount="loggedInData.schoolClass.bitsEarned" :large="true" :animate="true"/>
    </div>
    <router-link v-if="!loggedInData && onWeek" to="/login">
      <button class="base-button login-button">{{ $t("EXTRA_GAMES.PLAY_FOR_BITS") }}</button>
    </router-link>
  </header>
</template>

<script>
import BitsDisplay from './../elements/basic/BitsDisplay'
import InlineSvg from 'vue-inline-svg'

export default {
  name: 'ExtraGamesHeader',
  components: {
    BitsDisplay,
    InlineSvg
  },
  props: {
    loggedInData: {
      type: Object,
      default: null
    },
    daysTillEnd: {
      type: Number,
      default: 1,
    },
    hoursTillEnd: {
      type: Number,
      default: 1,
    },
    onWeek: {
      type: Boolean,
      required: true
    }

  },
  emits: ['logout'],
  // created() {
    // console.log('this.onWeek', this.onWeek)
    // console.log('this.loggedInData', this.loggedInData)
  // },
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.extras-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px 32px;
  margin-top: 28px;
  height: 170px;

  :only-child {
    margin: 0 auto;
  }

  .logo-wrapper {
    .logo {
      width: 206px;
    }
  }

  .week-logo {
    width: 355px;
  }

  .login-button {
    color: #373B52;
  }
}

.center-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: rem(10);
}

.time-left-text {
  color: white;
  background-color: var(--primary_color);
  border-radius: rem(35);
  font-size: rem(14);
  padding: rem(6) rem(12);
  white-space: nowrap;

  .bold {
    font-weight: 600;
  }
}

.logged-in-wrapper {
  display: flex;
  color: white;
  flex-direction: column;
  gap: rem(14);
  align-items: flex-end;

  .top-row {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;

    .logged-in-class {
      font-weight: 700;
      font-size: 18px;
      margin-right: 16px;
    }

    .logout-icon {
      width: rem(32);
      height: rem(32);
    }
  }
}

@media screen and (max-width: 800px) {
  .extras-header {
    flex-direction: column;
    height: fit-content;
    gap: rem(16);
  }
  .time-left-text {
    font-size: rem(12);
  }
  .week-logo {
    width: calc(100% - 32px)
  }
}

</style>
