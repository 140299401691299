<template>
  <GameSlide :data-ready="dataReady"
             :mission-slide-type-id="missionSlideTypeId"
             :slide-content="slide"
             @slide-complete="nextSlide()"/>
</template>

<script>
import { mapGetters } from 'vuex'
import { ROUTE_NAMES } from '@/router'
import { GET_SESSION, GET_SLIDE_CONTENT_BY_ID } from '@/store/actions'
import GameSlide from './GameSlide.vue'
import { SET_SLIDE_START_TIME } from '@/store/mutations'

export default {
  name: 'GameSlideBySession',
  components: {
    GameSlide
  },
  props: {
    id: {
      type: String,
      required: true
    },
    classId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    dataReady: false,
  }),
  computed: {
    ...mapGetters({
      mission: 'getMission',
      slide: 'getSlideContent',
      /** @type {MissionSession|null} */
      session: 'getSession',
      bit: 'getBits'
    }),
    missionSlideTypeId() {
      return this.slide && this.slide.missionSlideType ? this.slide.missionSlideType.id : 0
    }
  },
  created() {
    this.getGameSession()
  },
  methods: {
    getGameSession() {
      this.dataReady = false
      this.$store.dispatch(GET_SESSION, true).then(response => {
        if (response) {
          // console.log('dispatch GET_SESSION this.session', this.session)
          //if already done, go to finish page
          if (this.session.finishedAt) {
            this.$router.push({ name: ROUTE_NAMES.GAME_COMPLETE })
          } else {
            this.getSlide()
          }
        }
      })
    },
    getSlide() {
      this.$store.dispatch(GET_SLIDE_CONTENT_BY_ID, this.session.currentMissionSlideId)
        .then(response => {
          this.dataReady = true
          this.$store.commit(SET_SLIDE_START_TIME, Date.now())
        })
        .catch(error => {
          console.log(error.response)
        })
      /*

            this.$store.dispatch(GET_SLIDE_CONTENT_SESSION, this.session.id)
              .then(response => {
                this.dataReady = true
                this.$store.commit(SET_SLIDE_START_TIME, Date.now())
              })
              .catch(error => {
                console.log(error.response)
              })*/
    },
    nextSlide() {
      if (this.slide.slideIndex === this.slide.missionTotalSlideCount) {
        //check the subslides are also ok
        if (this.slide?.questionContent?.subSlides != null) {
          const maxSubSlides = this.slide.questionContent.subSlides.length ?? 0
          const currentSubSlide = this.session.currentSubSlideIndex
          if (currentSubSlide < maxSubSlides) {
            this.getGameSession()
            return
          }
        }

        this.$router.push({ name: ROUTE_NAMES.GAME_COMPLETE })
      } else {
        this.getGameSession()
      }
    },
  }
}
</script>

<style>

</style>
