export default class Mission {
  id;

  name;

  description;

  mediaFileId;

  activeCoverMediaFileId;

  playedCoverMediaFileId;

  createdAt;

  updatedAt;

  isActivated;

  durationEstimated;

  suitableForHighSchool;

  missionJokerId;

  dayOfMM;

  canBePlayedAlone;

  constructor(id, name, description, mediaFileId, activeCoverMediaFileId, playedCoverMediaFileId, createdAt, updatedAt, isActivated, durationEstimated, suitableForHighSchool, missionJokerId, dayOfMM, canBePlayedAlone) {
    this.id = id
    this.name = name
    this.description = description
    this.mediaFileId = mediaFileId
    this.activeCoverMediaFileId = activeCoverMediaFileId
    this.playedCoverMediaFileId = playedCoverMediaFileId
    this.createdAt = createdAt
    this.updatedAt = updatedAt
    this.isActivated = isActivated
    this.durationEstimated = durationEstimated
    this.suitableForHighSchool = suitableForHighSchool
    this.missionJokerId = missionJokerId
    this.dayOfMM = dayOfMM
    this.canBePlayedAlone = canBePlayedAlone
  }

  static parseFromDataObject(data) {
    return new Mission(data.id,
      data.name,
      data.description,
      data.mediaFileId,
      data.activeCoverMediaFileId,
      data.playedCoverMediaFileId,
      data.createdAt,
      data.updatedAt,
      data.isActivated,
      data.durationEstimated,
      data.suitableForHighSchool,
      data.missionJokerId,
      data.dayOfMM,
      data.canBePlayedAlone)
  }
}
