export default class ImageQuestionContent {
  /**
   * @type {string}
   * @description the url of the image to show.
   */
  content

  constructor(content) {
    this.content = content
  }

  static parseFromDataObject(data) {
    return new ImageQuestionContent(data.content)
  }
}
