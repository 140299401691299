<template>
  <div class="scroll-wrapper">
    <div class="task-wrapper">
      <FeedbackDisplay v-if="shouldBeShowingFeedback"
                       :on-press-next="closeFeedback"
                       :was-correct="showCorrectVideo"/>
      <div v-else>

        <h1 class="slide-title">{{ slideContent.title }}</h1>
        <p class="text" v-html="slideContent.description"></p>

        <div :style="historyLocation !== null ? 'pointer-events: none' : ''" class="picture-answers">
          <div
            v-for="(answer, index) in slideContent.questionContent.answers"
            :key="answer.id"
            :class="{'disabled-answer-wrapper': removedAnswers.includes(answer.id)}"
            class="answer-wrapper"
          >
            <label :for="`answer-${answer.id}`" class="check-label">
              <div
                :class="{
                  'disabled-answer': removedAnswers.includes(answer.id),
                  'selected-answer': selectedAnswer && selectedAnswer === answer.id,
                  'unselected-answer': selectedAnswer && selectedAnswer !== answer.id,
                  'correct-answer-outline': correctAnswers === answer.id
                }"

                class="answer"
              >
                <!--          :style="`background-image: url(${answer.answer})`"-->
                <div
                  v-if="showAnswersOverlay || correctAnswers != null"
                  :class="{'overlay-correct': answer.id !== selectedAnswer && answer.id === correctAnswers, 'overlay-incorrect': answer.id !== selectedAnswer && answer.id !== correctAnswers}"
                  class="answer-overlay"
                />
                <img :src="answer.answer" alt="" class="answer-image">
                <input
                  :id="`answer-${answer.id}`"
                  v-model="selectedAnswer"
                  :value="answer.id"
                  name="basic-checkbox"
                  type="radio"
                />
                <span class="answer-letter">{{ alphabet[index] }}.</span>
                <span class="check-mark"></span>
              </div>
              <div v-if="slideContent?.questionContent" style="display: none"> {{ givenAnswers }}</div>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <game-footer
    v-if="!shouldBeShowingFeedback"
    :continue-button-click="checkAnswers"
    :continue-button-disabled="!selectedAnswer"

    :continue-button-text="$t('FOOTER.SINGLE_ANSWER.CONTINUE_BUTTON_TEXT')"
    :feedback-button-click="showFeedback"

    :feedback-button-text="$t('FOOTER.FEEDBACK_BUTTON_TEXT')"
    :feedback-result="result"
    :force-next="shouldBeShowingFeedback"
    :has-feedback-popup="feedbackText"
  />
</template>

<script>
import { POST_SLIDE_ANSWER } from '@/store/actions'
import { UPDATE_BITS } from '@/store/mutations'
import i18n from '@/setup/i18n/i18n'
import { mapGetters } from 'vuex'
import { alphabet } from '../../utils/data/alphabet'
import FeedbackDisplay from './FeedbackDisplay'

export default {
  name: 'SingleImageSelect',
  components: { FeedbackDisplay },
  props: {
    slideContent: {
      type: Object,
      required: true,
    }
  },
  emits: ['nextSlide'],
  data: () => ({
    alphabet: alphabet,
    selectedAnswer: null,
    correctAnswer: null,
    showAnswersOverlay: false,
    showCorrect: false,
    showIncorrect: false,

    feedbackText: '',
    feedbackVideoUrl: '',
    removedAnswers: [],
  }),
  computed: {
    ...mapGetters({
      jokerResult: 'getJokerResult',
      historyLocation: 'getHistoryLocation',
      showFeedbackModal: 'getShowFeedbackModal'
    }),
    shouldBeShowingFeedback() {
      return this.historyLocation?.isPostAnswer || this.showFeedbackModal
    },
    correctAnswers() {
      const answers = this.$store.getters.getCorrectAnswers
      if (answers === undefined || answers === null) {
        return answers
      }
      console.log('got correct answers', answers)
      return answers
    },
    givenAnswers() {
      /*      if (this.selectedAnswers.length > 0) {
              return this.selectedAnswers
            }*/

      const answers = this.$store.getters.getClientAnswer
      console.log('got given answers', answers)
      this.selectedAnswer = answers

      if (answers == null) {
        return []
      }
      console.log('we set selected answer in given answers')

      return answers
    },
    showCorrectVideo() {
      return this.givenAnswers === this.correctAnswers
    }
  },
  watch: {
    jokerResult: {
      deep: true,
      handler: function(curr, prev) {
        if (curr && curr.wrongAnswersToRemove) {
          this.removedAnswers = curr.wrongAnswersToRemove.map(value => value.id)
          if (this.removedAnswers.includes(this.selectedAnswer)) {
            this.selectedAnswer = null
          }
        }
      }
    }
  },
  methods: {
    checkAnswers() {
      const formData = new FormData()
      formData.append('missionId', this.slideContent.missionId)
      formData.append('slideIndex', this.slideContent.slideIndex)
      formData.append('answer', this.selectedAnswer)
      this.$store.dispatch(POST_SLIDE_ANSWER, formData)
        .then(response => {
          this.correctAnswer = response.correctAnswer
          if (response.isAnswerCorrect) {
            this.result = {
              successPercentage: 1,
              title: i18n.global.tc('FOOTER.GOOD_JOB'),
            }
          } else {
            this.correctAnswer = this.slideContent.questionContent.answers.filter(elem => elem.id === response.correctAnswer)[0].id
            this.result = {
              successPercentage: 0,
              title: i18n.global.tc('FOOTER.SINGLE_ANSWER.FEEDBACK_TITLE'),
              text: this.alphabet[this.slideContent.questionContent.answers.findIndex((answer) => {
                return answer.id === this.correctAnswer
              })],
            }
          }
          this.feedbackText = response.feedback
          this.feedbackVideoUrl = response.feedbackVideoUrl
          this.$store.commit(UPDATE_BITS, response.awardedBits)
        })
    },
    showFeedback() {
      this.selectedAnswer = null
      //Show feedback modal
      if (this.feedbackText || this.feedbackVideoUrl) {
        this.$store.dispatch('showFeedbackModal')
      } else {
        this.$emit('nextSlide')
      }
    },
    closeFeedback() {
      //Hides feedback modal
      this.$store.dispatch('hideFeedbackModal')
      this.$emit('nextSlide')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";

.task-wrapper {
  color: #FFFFFF;
  padding-top: 48px;
  width: 100%;
}

.slide-title {
  margin-bottom: rem(25);
}

.disabled {
  cursor: default;
  pointer-events: none;
}

.text {
  margin-bottom: rem(25);
  font-size: rem(20);
  line-height: rem(35);
}

.picture-answers {
  display: flex;
  gap: 32px;
  justify-content: center;
  padding: rem(3) 40px;
  flex-wrap: nowrap;
  align-items: center;
  height: 55vh;
  min-width: rem(1000);
  //overflow: hidden;
}

.answer-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  max-width: 25vw;
  height: 100%;
  max-height: 25vw;
  min-width: 10rem;

  .answer-letter {
    font-size: rem(30);
    font-weight: 500;
    top: rem(18);
    left: rem(18);
    user-select: none;
    position: absolute;
  }

  .answer.unselected-answer {
    opacity: 0.5;
  }

  .answer {
    width: 100%;
    height: 100%;
    font-size: rem(24);
    font-weight: 800;
    border-radius: rem(10);
    color: #FFFFFF;
    position: relative;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      overflow: hidden;
      border-radius: inherit;
    }

    .answer-overlay {
      @include position(absolute, $top: 0, $right: 0, $bottom: 0, $left: 0);
      border-radius: rem(10);
      z-index: 2;
    }

    .overlay-correct {
      background-color: rgba(1, 170, 161, 0.5);
    }

    .overlay-incorrect {
      background-color: rgba(60, 58, 58, 0.75);
    }
  }

  .selected-answer {
    outline: solid 2px deeppink;
  }

  .correct-answer-outline {
    outline: solid 2px rgba(1, 170, 161, 1);
  }
}

.disabled-answer {
  pointer-events: none;
  opacity: 0.5;
  background-color: #9590A4;
}

.disabled-answer-wrapper {
  pointer-events: none;
  border-radius: rem(20);
}

.check-answer-wrapper {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  border-top: solid 1px #FFFFFF;
  padding: rem(40);

  .check-answer-btn {
    float: right;
    background-color: deeppink;
    color: #FFFFFF;
    font-size: rem(18);
    padding: rem(15) rem(30);
    border-radius: rem(5);
    border: none;

    &:disabled {
      background-color: #ACABB8;
    }
  }
}

.check-label {
  margin: 0;
  display: block;
  cursor: pointer;
  text-align: left;
  width: 100%;
  height: 100%;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked ~ .check-mark {
      background-color: deeppink;
      border: none;

      &:after {
        display: block;
      }
    }
  }

  .check-mark {
    position: absolute;
    top: rem(18);
    right: rem(18);
    border-radius: 50%;
    height: rem(27);
    width: rem(27);
    border: 2px solid deeppink;
    background-color: white;

    &:after {
      content: "";
      display: none;
      position: absolute;
      top: rem(2);
      left: rem(9);
      width: rem(10);
      height: rem(18);
      border: solid #FFFFFF;
      border-width: 0 rem(4) rem(4) 0;
      transform: rotate(45deg);
    }
  }

  .check-mark-error {
    @extend .check-mark;
    border: 2px solid red;
  }
}

.incorrect-answer, .correct-answer {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: rem(40);
  text-align: left;
  font-size: rem(24);
  line-height: rem(38);

  .result-icon {
    background-color: #BABABA;
    width: rem(40);
    height: rem(40);
    margin-right: rem(20);
  }

  .result-text {
    margin-right: auto;
  }

  .answer-is {
    font-weight: 500;
  }
}

.incorrect-answer {
  background-color: rgba(255, 20, 147, 0.2);

  .wrong {
    color: red;
    font-size: rem(28);
  }
}

.correct-answer {
  background-color: rgba(47, 108, 195, 0.5);

  .correct {
    color: darkcyan;
    font-size: rem(28);
  }
}

@media screen and (max-width: $screen-sm) {
  .answer-wrapper {
    .answer {
      font-size: rem(15);
    }
  }
}

@media screen and (max-width: $screen-720p) {
  .task-wrapper {
    padding-top: 32px;

    .title {
      margin-top: 16px;
    }

    .text {
      margin-bottom: 0;
    }
  }
}
</style>
