<template>
  <div id="text-scroll-wrapper" class="scroll-wrapper">
    <div class="task-wrapper">
      <FeedbackDisplay v-if="shouldBeShowingFeedback"
                       :on-press-next="() => {}"/>
      <slide-header :description="slideContent.description" :title="slideContent.title"/>

      <div v-for="(questionContent, index) in content" :key="index">
        <div v-if="content[index] === dots"
             :class="{'saving' : index === counter, 'hidden': index > counter }"
             class="dot-space">
          <div class="dot-wrapper">
            <span class="dot">.</span><span class="dot">.</span><span class="dot">.</span>
          </div>
        </div>
        <p v-else
           :class="{'fadein' : index === counter || index === counter - 1, 'hidden': index > counter}"
           class="mission-text"
           v-html="content[index]"/>
      </div>
    </div>
  </div>
  <game-footer
    :continue-button-click="finishText"
    :continue-button-text="$t('STATIC.NEXT')"
    :style="dotRenderingFinished ? 'opacity: 1' : 'opacity: 0'"
  />
</template>

<script>
import SlideHeader from '@/components/elements/SlideHeader'
import { POST_SLIDE_ANSWER } from '@/store/actions'
import FeedbackDisplay from '../FeedbackDisplay'
import { mapGetters } from 'vuex'

export default {
  name: 'Text',
  components: { FeedbackDisplay, SlideHeader },
  props: {
    slideContent: {
      type: Object,
      required: true
    }
  },
  emits: ['nextSlide'],
  computed: {
    ...mapGetters({ historyLocation: 'getHistoryLocation' }),
    shouldBeShowingFeedback() {
      return this.historyLocation?.isPostAnswer
    },
  },
  data: () => ({
    counter: 0,
    content: [],
    // todo:  stupid solution
    dots: '. . .',
    dotRenderingFinished: false,
    timer: null,
    timerDot: null,
    setInterval: null,
  }),
  created() {
    this.initContent()
  },
  mounted() {
  },
  methods: {
    finishText() {
      const formData = new FormData()
      this.$store.dispatch(POST_SLIDE_ANSWER, formData).then(response => {
        this.$emit('nextSlide')
      })
      // Initialize new content, after next slide
      this.initContent()
    },
    initContent() {
      this.content = []
      this.counter = 1
      let lines = this.slideContent.questionContent.content
      if (typeof lines === 'string') {
        lines = [lines]
      }
      for (let i = 0; i < lines.length; i++) {
        this.content.push(lines[i])
        if (i < lines.length - 1) {
          this.content.push(this.dots)
        }
      }
      if (lines.length === 1) {
        this.dotRenderingFinished = true
      } else {
        this.initTimers()
      }
    },
    beforeUnMount() {
      this.clearTimers()
    },
    initTimers() {
      this.timerDot = setInterval(() => {
        this.increaseCount()
        this.timer = setTimeout(() => this.increaseCount(), 500)
      }, 2500)
    },
    clearTimers() {
      clearTimeout(this.timer)
      clearInterval(this.timerDot)
    },
    increaseCount() {
      if (this.counter === this.content.length - 1) {
        this.dotRenderingFinished = true
        this.clearTimers()
        return
      } else if (this.counter < this.content.length) {
        this.counter++
      }

      this.$nextTick(() => {
        const scrollBodyTextElem = document.getElementById('text-scroll-wrapper')
        if (scrollBodyTextElem) {
          const newScrolLHeight = this.counter * 60

          if (newScrolLHeight > scrollBodyTextElem.clientHeight) {
            scrollBodyTextElem.scrollTo({ top: newScrolLHeight, behavior: 'smooth' })
          }
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";
@import "~@/assets/css/global.scss";
@import "~@/assets/css/mission.scss";

.task-wrapper {
  width: 100%;
  padding-bottom: 24px !important;
}

.mission-text {
  font-size: rem(22);
  text-align: center;
}

.dot-space {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: rem(16);
  margin-bottom: rem(20);

  .dot-wrapper {
    font-size: rem(24);
    color: var(--highlight_color);
    font-weight: 700;
    //transform: translate(-6px, -4px);
    transform: translateY(-4px);

    .dot {
      margin-left: 4px;
      margin-right: 4px;
    }
  }
}

.hidden {
  visibility: hidden;
}

@keyframes blink {
  /**
   * At the start of the animation the dot
   * has an opacity of .2
   */
  0% {
    opacity: 0;
  }
  /**
   * At 20% the dot is fully visible and
   * then fades out slowly
   */
  20% {
    opacity: 0.2;
  }
  /**
   * Until it reaches an opacity of .2 and
   * the animation can start again
   */
  100% {
    opacity: 1;
  }
}

.saving span {

  /**
   * Use the blink animation, which is defined above
   */
  animation-name: blink;
  /**
   * The animation should take 1.4 seconds
   */
  animation-duration: 0.1s;
  /**
   * It will repeat itself forever
   */
  animation-iteration-count: 1;
  /**
   * This makes sure that the starting style (opacity: .2)
   * of the animation is applied before the animation starts.
   * Otherwise we would see a short flash or would have
   * to set the default styling of the dots to the same
   * as the animation. Same applies for the ending styles.
   */
  animation-fill-mode: both;
}

.saving span:nth-child(2) {
  /**
   * Starts the animation of the third dot
   * with a delay of .2s, otherwise all dots
   * would animate at the same time
   */
  animation-delay: .5s;
}

.saving span:nth-child(3) {
  /**
   * Starts the animation of the third dot
   * with a delay of .4s, otherwise all dots
   * would animate at the same time
   */
  animation-delay: 1s;
}

.shift-move,
.shift-enter-active,
.shift-leave-active {
  transition: all 0.25s ease-in-out;
}

.shift-enter-from,
.shift-leave-to {
  opacity: 0;
}

.fadein {
  animation-name: blink;
  animation-duration: 0.2s;
}
</style>
