import NetiquetteQuestionContentSubSlide from './NetiquetteQuestionContentSubSlide'

export default class NetiquetteQuestionContent {
  /**
   * @type {NetiquetteQuestionContentSubSlide[]}
   */
  subSlides;

  constructor(subSlides) {
    this.subSlides = subSlides
  }

  static parseFromDataObject(data) {
    return new NetiquetteQuestionContent(
      data.subSlides.map(subSlide => NetiquetteQuestionContentSubSlide.parseFromDataObject(subSlide)))
  }
}
