import Mission from '../../models/mission/Mission'
import MissionSession from '../../models/mission/MissionSession'
import NextMissionSlide from '../../models/mission/nextMissionSlide'

export default class GetMissionSessionResponse {
  hasExtraSounds

  isNewSession

  missionSession

  mission

  nextMissionSlide

  availableJokers

  constructor(hasExtraSounds, isNewSession, missionSession, mission, nextMissionSlide, availableJokers) {
    this.hasExtraSounds = hasExtraSounds
    this.isNewSession = isNewSession
    this.missionSession = missionSession
    this.mission = mission
    this.nextMissionSlide = nextMissionSlide
    this.availableJokers = availableJokers
  }

  static parseFromDataObject(data) {
    return new GetMissionSessionResponse(data.hasExtraSounds,
      data.isNewSession,
      MissionSession.parseFromDataObject(data.missionSession),
      Mission.parseFromDataObject(data.mission),
      NextMissionSlide.parseFromDataObject(data.nextMissionSlide),
      data.availableJokers
    )
  }
}
