<template>
  <div class="scroll-wrapper">
    <div class="task-wrapper">
      <FeedbackDisplay v-if="shouldBeShowingFeedback" :on-press-next="closeFeedback" :was-correct="showCorrectVideo"/>
      <div v-else>

        <slide-header :description="slideContent.description" :title="slideContent.title"/>

        <div class="article-wrapper">
          <div class="article">
            <div class="article-img">
              <img :src="slideContent.questionContent.mediaFile.url">
            </div>
            <div class="article-title">
              <p>{{ slideContent.questionContent.articleTitle }}</p>
            </div>
            <div class="article-text">
              <p>{{ slideContent.questionContent.articleContent }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <game-footer
    v-if="!shouldBeShowingFeedback"
    :choice-data="{
      positiveChoiceText: slideContent.questionContent.answers[0],
      negativeChoiceText: slideContent.questionContent.answers[1],
      positiveChoiceClick: () => checkAnswer(answerTypes.true),
      negativeChoiceClick: () => checkAnswer(answerTypes.false)
    }"
    :feedback-button-click="showFeedback"
    :feedback-button-text="$t('FEEDBACK.VIEW_YOUR_FEEDBACK')"
    :feedback-result="result"
    :force-next="shouldBeShowingFeedback"
    :has-feedback-popup="feedbackText"/>
</template>

<script>
import FeedbackDisplay from './FeedbackDisplay'
import { POST_SLIDE_ANSWER } from '@/store/actions'
import { UPDATE_BITS } from '@/store/mutations'
import GameFooter from '@/components/GameFooter'
import i18n from '@/setup/i18n/i18n'
import SlideHeader from '@/components/elements/SlideHeader'
import { mapGetters } from 'vuex'

const answerTypes = {
  true: 0,
  false: 1
}

export default {
  name: 'TrueOrFalse',
  components: { SlideHeader, GameFooter, FeedbackDisplay },
  props: {
    slideContent: {
      type: Object,
      required: true,
    }
  },
  emits: ['nextSlide'],
  data: () => ({
    answerTypes,
    result: undefined,
    feedbackText: '',
    feedbackVideoUrl: '',
  }),
  computed: {
    ...mapGetters({
      historyLocation: 'getHistoryLocation',
      showFeedbackModal: 'getShowFeedbackModal'
    }),
    shouldBeShowingFeedback() {
      return this.historyLocation?.isPostAnswer || this.showFeedbackModal
    },
    correctAnswers() {
      const answers = this.$store.getters.getCorrectAnswers
      if (answers === undefined || answers === null) {
        return null
      }
      console.log('got correct answers', answers)
      return answers
    },
    givenAnswers() {
      /*      if (this.selectedAnswers.length > 0) {
              return this.selectedAnswers
            }*/

      const answers = this.$store.getters.getClientAnswer
      console.log('got given answers', answers)
      if (Array.isArray(answers)) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.selectedAnswer = answers
        return answers
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.selectedAnswers = answers
        return answers
      }
    },
    showCorrectVideo() {
      return this.correctAnswers === this.givenAnswers
    },
  },
  methods: {
    checkAnswer(choice) {
      const formData = new FormData()
      formData.append('missionId', this.slideContent.missionId)
      formData.append('slideIndex', this.slideContent.slideIndex)
      formData.append('answer', choice)
      this.$store.dispatch(POST_SLIDE_ANSWER, formData)
        .then(response => {
          const resultText = this.slideContent.questionContent.answers[response.correctAnswer]
          if (response.isAnswerCorrect) {
            this.result = {
              successPercentage: 1,
              title: i18n.global.tc('FOOTER.GOOD_JOB'),
              // text: resultText,
            }
          } else {
            this.result = {
              successPercentage: 0,
              title: i18n.global.tc('FOOTER.SINGLE_ANSWER.FEEDBACK_TITLE'),
              text: resultText,
            }
          }
          this.feedbackText = response.feedback
          this.feedbackVideoUrl = response.feedbackVideoUrl
          this.$store.commit(UPDATE_BITS, response.awardedBits)
        })
    },
    showFeedback() {
      if (this.feedbackText || this.feedbackVideoUrl) {
        this.$store.dispatch('showFeedbackModal')
      } else {
        this.$emit('nextSlide')
      }
    },
    closeFeedback() {
      //Hides feedback modal
      this.$store.dispatch('hideFeedbackModal')
      this.$emit('nextSlide')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";
@import "~@/assets/css/mission.scss";

.article-wrapper {
  margin-top: 35px;
  padding: rem(22);
  background-color: #ffffff;
  border-radius: rem(15);
  margin: auto;
  max-width: 50%;
}

.article {
  background-color: #ffffff;
  border-radius: rem(15);
  height: rem(500);
  color: var(--text_color);
  text-align: left;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  overflow: auto;

  @media screen and (min-width: $screen-xl) {
    height: auto;
  }

  &::-webkit-scrollbar {
    width: rem(10);
  }

  &::-webkit-scrollbar-track {
    background-color: #b6b9c7;
  }

  &::-webkit-scrollbar-thumb {
    color: var(--text_color);
    @include border-radius(rem(4));
  }

  .article-img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    margin-bottom: rem(15);

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      min-width: 10%;
      border-radius: rem(8);
    }
  }

  .article-title {
    font-size: rem(25);
    font-weight: bold;
    margin-bottom: rem(13);
  }

  .article-text {
    font-size: rem(22);
    line-height: rem(35);
  }
}
</style>
