<template>
  <div
    class="game-box-wrapper"
  >
    <div class="inavailable-overlay" v-if="isUpcoming">
      <div class="inavailable-text">{{ $t('EXTRA_GAMES.COMING_SOON') }}</div>
    </div>
    <div class="image-wrapper">
      <img
        :alt="'Afbeelding ' + gameData.name"
        :src="gameData.mediaFileUrl"
        class="cover-image"
        loading="lazy"
        draggable="false"
      >
    </div>
    <div v-if="onWeek && gameData.bitsAwarded !== undefined" class="bit-ribbon">
      <img
        class="bit-image"
        src="@/assets/icons/icn_bits.svg"
      >
      <div class="bit-amount">
        {{ gameData.bitsAwarded }}
      </div>
    </div>
    <div v-if="isLoggedIn && onWeek" class="amount-played-ribbon">
      <img src="@/assets/icons/joystick.svg">
      <div class="amount-played-text">{{ gameData.extraGamePlayedCount }}</div>
      <div class="vertical-line"/>
      <img src="@/assets/icons/icn_bits.svg">
      <!-- Change to currently earned bits by class -->
      <div class="amount-earned-text">{{ bitsEarned ?? gameData.totalBitsEarned }} verdiend</div>
    </div>
  </div>
</template>

<script>

import ApiHandler from '../../../utils/handlers/ApiHandler'

export default {
  name: 'GameBox',
  components: {},
  props: {
    // note: 'holds the games data, along with image source'
    gameData: {
      type: Object,
      required: true,
    },
    onWeek: {
      type: Boolean,
      required: true
    },
    isLoggedIn: {
      type: Boolean,
      required: false,
      default: true
    },
    loggedInData: {
      type: Object,
      required: false,
      default: () => ({})
    },
    isUpcoming: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data: () => ({
    className: '',
    apiError: false,
  }),
  computed: {
    // note: 'returns the image source of the game'
    bitsEarned() {
      return this.loggedInData ? this.loggedInData.extraGames.content.filter(game => game.id === this.gameData.id)[0].bitsEarned : 0
    },
  },
  methods: {

    loginClass() {
      // this.apiError = false
      const formData = new FormData()
      formData.append('className', this.className)
      ApiHandler.api.post('/auth/login-extra-game-class', formData)
        .then((res) => {
          localStorage.setItem('schoolClass', JSON.stringify(res.data))
          console.log('login-extra-game-class res', res)
          this.apiError = false
        })
        .catch((error) => {
          this.apiError = true
          console.log('error', error)
        })
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/global.scss";
@import "~@/assets/css/base.mixins";

$border-radius-game-box: rem(24);

.game-box-wrapper {
  position: relative;
  height: 400px;
  max-height: 400px;
  border-radius: $border-radius-game-box;
  cursor: pointer;
  background: linear-gradient(180deg, #201B39 0%, #2F234B 100%, #404FD8 100%);
  box-shadow: inset 0 1px 2px 0 #341C69, 0 2px 30px 0 rgba(0, 0, 0, 0.2);
  transition: filter 0.2s;
  filter: saturate(1.2);

  &:hover {
    filter: saturate(1.8);
  }
}

.image-wrapper {
  width: inherit;
  height: inherit;
  border-radius: $border-radius-game-box;

  .cover-image {
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: $border-radius-game-box;
  }
}

.bit-ribbon {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding: 10px;
  width: 105px;
  background-color: white;
  font-size: 18px;
  font-weight: 600;
  color: var(--text_color);
  border-top-right-radius: $border-radius-game-box;
  border-bottom-left-radius: $border-radius-game-box;

  .bit-image {
    width: rem(32);
    height: rem(32);
    margin-right: 8px;
  }
}

.amount-played-ribbon {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  padding: 10px;
  width: auto;
  height: 53px;
  background-color: #402F79;
  color: white;
  font-size: 18px;
  font-weight: 600;
  border-bottom-left-radius: $border-radius-game-box;
  border-top-right-radius: $border-radius-game-box;

  img {
    margin-left: 2px;
    margin-right: 6px;
    width: 32px;
  }

  .amount-earned-text {
    margin-right: 16px;
  }

  .vertical-line {
    width: 2px;
    height: 100%;
    background-color: #7053CE;
    margin: 0 16px;
  }
}

.inavailable-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(33, 26, 57, 0.5) 0%, rgba(0, 0, 0, 0.6) 100%);
  border-radius: $border-radius-game-box;

  .inavailable-text {
    color: white;
    font-weight: 600;
    font-size: rem(20);
    position: absolute;
    bottom: rem(16);
    width: 100%;
    text-align: center;
    padding: 0 rem(56);
    line-height: rem(28);
    color: var(--gray-light-a);
  }
}

@media screen and (max-width: 800px) {
  .game-box-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .image-wrapper {
    border-radius: $border-radius-game-box;

    .cover-image {
      object-fit: cover;
      height: 100%;
      width: 100%;
      border-radius: $border-radius-game-box;
    }
  }
}

</style>
