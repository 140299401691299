export default class MissionCategory {
  /**
   * The id of the category.
   * @type {number}
   */
  id;

  /**
   * The name of the category.
   * @type {string}
   */
  name;

  constructor(id, name) {
    this.id = id
    this.name = name
  }

  static fromJson(json) {
    if (!json) return null
    return new MissionCategory(json.id, json.name)
  }
}
