// export mq constants
export const MQ_XS = 'MQ_XS'
export const MQ_SM = 'MQ_SM'
export const MQ_MD = 'MQ_MD'
export const MQ_LG = 'MQ_LG'
export const MQ_XL = 'MQ_XL'
export const MQ_XXL = 'MQ_XXL'
const TAG_START = '<'
const TAG_END = '>'

export const TABLE_COLORS = {
  redMain: '#FF2151',
  greenMain: '#417505'
}

export default {
  /**
   * Dom and layout manipulation
   */
  disableScroll: function() {
    document.body.style.overflow = 'hidden'
    document.body.style.position = 'fixed'
    document.body.style.top = '0'
    document.body.style.right = '0'
    document.body.style.bottom = '0'
    document.body.style.left = '0'
  },
  enableScroll: function() {
    document.body.style.overflow = ''
    document.body.style.position = ''
    document.body.style.top = ''
    document.body.style.right = ''
    document.body.style.bottom = ''
    document.body.style.left = ''
  },
  getScreenWidth: function() {
    const width = window.innerWidth
    if (width < 576) {
      return MQ_XS
    } else if (width >= 576 && width < 768) {
      return MQ_SM
    } else if (width >= 768 && width < 992) {
      return MQ_MD
    } else if (width >= 992 && width < 1200) {
      return MQ_LG
    } else if (width >= 1200 && width < 1600) {
      return MQ_XL
    } else {
      return MQ_XXL
    }
  },
  isSmallScreen: function() {
    switch (this.getScreenWidth()) {
      case MQ_XS:
        return true
      case MQ_SM:
        return true
      default:
        return false
    }
  },
  isTabletMobile: function() {
    switch (this.getScreenWidth()) {
      case MQ_XS:
        return true
      case MQ_SM:
        return true
      case MQ_MD:
        return true
      default:
        return false
    }
  },
  isMobile: function() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  },
  scrollUp: function() {
    scroll(0, 0)
  },
  /**
   * String and HTML manipulation
   */
  parseHtmlString: function(htmlString, className = '') {
    // add class to the tag
    if (htmlString.indexOf(TAG_START) !== -1 && htmlString.indexOf(TAG_END) !== -1) {
      htmlString = htmlString.replace(TAG_END, ` class="${className}"${TAG_END}`)
    } else {
      htmlString = `<p>${htmlString}</p>`
      htmlString = htmlString.replace(TAG_END, ` class="${className}"${TAG_END}`)
    }
    return htmlString
  },
  addTextColor: function(text, indexStart, searchTerm, className) {
    const textStart = text.substring(0, indexStart)
    const textMiddle = text.substr(indexStart, searchTerm.length)
    const textEnd = text.substring(indexStart + searchTerm.length)
    return `${textStart}<span class="${className}">${textMiddle}</span>${textEnd}`
  },
  removeTextColor: function(text) {
    const tagIndex = text.indexOf(TAG_START)
    if (tagIndex === -1) return text
    const textStart = text.substring(0, tagIndex)
    const textEnd = text.substring(text.indexOf(TAG_END) + 1)
    return this.removeTextColor(`${textStart}${textEnd}`)
  },
  replaceDotWithComma: function(number) {
    return String(number).replace(/[.]/gi, ',')
  },
  replaceCommaWithDot: function(number) {
    return String(number).replace(/[,]/gi, '.')
  },
  isValidString: function(text) {
    const lowerCase = text.toLowerCase()
    return lowerCase !== 'false' && lowerCase !== 'null' && lowerCase !== 'undefined'
  },
  replaceBackSlashes: function(link) {
    return link.replace(/\\/g, '/')
  },
  prependZeroToNumber(num) {
    return num < 10 ? '0' + num : num
  },
  camelCaseToSpacedNames: function(text) {
    // insert a space before all caps
    return text.replace(/([A-Z])/g, ' $1')
    // uppercase the first character
      .replace(/^./, function(str) {
        return str.toUpperCase()
      })
  },
  kebabCaseToSpacedNames: function(text) {
    return text.replace(/-/g, ' ')
      .replace(/^./, function(str) {
        return str.toUpperCase()
      })
  },
  /**
   * Date manipulation
   */

  /** This function receives a timestamp and
   * returns a formatted date (eg. 10-09-2019)
   */
  formatDateDash: function(timestamp) {
    const dateToFormat = new Date(timestamp)
    let month = dateToFormat.getMonth() + 1
    if (month < 10) {
      month = `0${month}`
    }
    let day = dateToFormat.getDate()
    if (day < 10) {
      day = `0${day}`
    }
    return `${day}-${month}-${dateToFormat.getFullYear()}`
  },

  /** This function receives a date, checks if it is equal to
   *  the date of today. Returns true or false.
   */
  isToday: function(date) {
    const today = new Date()
    return today.getDate() === date.getDate() &&
      today.getMonth() === date.getMonth() &&
      today.getFullYear() === date.getFullYear()
  },

  /** This function receives a date. Returns the
   *  difference between the current date and the
   *  received date in timestamp.
   */
  getTimestampDiff: function(date) {
    const nowTimestamp = new Date().getTime()
    const dateTimestamp = new Date(date * 1000).getTime()
    return Math.abs(dateTimestamp - nowTimestamp)
  },

  /** This function receives a timestamp. Returns the
   *  formatted time (eg. 10:25) for the received timestamp.
   */
  getTimeTodayOrDate: function(timestamp) {
    const date = new Date(timestamp * 1000)
    if (!this.isToday(date)) {
      let month = date.getMonth() + 1
      month = month < 10 ? `0${month}` : month
      let day = date.getDate()
      day = day < 10 ? `0${day}` : day
      return `${day}/${month}/${date.getFullYear().toString().substr(-2)}`
    }
    let hour = date.getHours()
    hour = hour < 10 ? `0${hour}` : hour
    let minutes = date.getMinutes()
    minutes = minutes < 10 ? `0${minutes}` : minutes
    return `${hour}:${minutes}`
  },

  /** This function receives a date. Returns how
   *  long ago was the received date.
   */
  getTimeAgo: function(date) {
    const diff = this.getTimestampDiff(date)
    const SECOND = 1000
    const MINUTE = SECOND * 60
    const HOUR = MINUTE * 60
    const DAY = HOUR * 24
    const MONTH = DAY * 30
    const YEAR = MONTH * 12

    const seconds = Math.floor(diff / SECOND)
    const minutes = Math.floor(diff / MINUTE)
    const hours = Math.floor(diff / HOUR)
    const days = Math.floor(diff / DAY)
    const months = Math.floor(diff / MONTH)
    const years = Math.floor(diff / YEAR)

    let timeDiff = 0
    let text = ''

    if (seconds < 60) {
      seconds === 1 ? text = 'second' : text = 'seconds'
      timeDiff = seconds
    } else if (minutes < 60) {
      minutes === 1 ? text = 'minute' : text = 'minutes'
      timeDiff = minutes
    } else if (hours < 24) {
      hours === 1 ? text = 'hour' : text = 'hours'
      timeDiff = hours
    } else if (days < 30) {
      days === 1 ? text = 'day' : text = 'days'
      timeDiff = days
    } else if (months < 12) {
      months === 1 ? text = 'month' : text = 'months'
      timeDiff = months
    } else if (years > 0) {
      years === 1 ? text = 'year' : text = 'years'
      timeDiff = years
    }
    return `${timeDiff} ${text} ago`
  },
}
