<template>
  <div class="select-mission-container">
    <div class="header-container">
      <div class="back">
        <a :href="returnLink">
          <img alt="" src="@/assets/icons/icn_arrowback.svg">
          <p>{{ $t('SELECT_MISSION.BACK') }}</p>
        </a>
      </div>
      <div class="center-logo-wrapper">
        <img alt="" src="@/assets/icons/icn_logo.svg">
      </div>
      <div class="right-logo-wrapper">
        <img alt="" src="@/assets/images/ic_logo_week-of-mediawisdom@3x.png">
      </div>
    </div>
    <div class="missions-scroll-wrapper scroll-bar-styling">
      <div class="missions-wrapper">
        <MissionDayOverview
          v-for="mission in missions"
          :id="mission.state === 'open' ? 'open-mission-day' : null"
          :key="mission.id"
          :has-flags="lastUnlockedMission ? lastUnlockedMission.id === mission.id : false"
          :mission="mission"
          @selected="startMission(mission)"
        />
      </div>
    </div>
    <Balloons v-if="showBalloons" :count="20" :iterations="'2'"/>
  </div>
</template>

<script>
import MissionDayOverview from '../components/MissionDayOverview'
import ApiHandler from '@/utils/handlers/ApiHandler'
import { nextTick, ref } from 'vue'
import { ROUTE_NAMES } from '@/router'
import { computed } from '@vue/reactivity'
import { useRouter } from 'vue-router'
// import { useStore } from 'vuex'
import LocalDataHandler from '../utils/handlers/LocalDataHandler'
import Balloons from '@/components/elements/Balloons'

export default {
  name: 'SelectMission',
  components: {
    MissionDayOverview,
    Balloons,
  },
  props: {
    classId: {
      type: Number,
      default: null,
    }
  },
  setup(props) {
    const centerImages = [
      require('@/assets/images/img_gesprek.png'),
      require('@/assets/images/img_crypto.png')
    ]
    // const route = useRoute()
    const router = useRouter()
    // const store = useStore()
    const lastUnlockedMission = computed(() => {
      return missions.value.slice().reverse().find((mission) => mission.state === 'completed')
    })
    const returnLink = computed(() => {
      return `${process.env.VUE_APP_ROOT_CMS}/leraren-portaal/overzicht/${props.classId}`
    })
    const showBalloons = ref(LocalDataHandler.getLocalData('balloon_event') === true)
    LocalDataHandler.removeLocalData('balloon_event')

    if (!props.classId && props.classId !== 0) {
      router.push({ name: ROUTE_NAMES.EXTRAS })
      return
    }

    function loginExtraGameClass() {
      const formData = new FormData()
      formData.append('classId', props.classId)
      return ApiHandler.api.post('/auth/login-extra-game-class', formData)
        .then((res) => {
          localStorage.setItem('schoolClass', JSON.stringify(res.data))
        })
    }

    loginExtraGameClass()

    const missions = ref([])
    ApiHandler.api.get('/game/mission/get-week-mission-overview/' + props.classId)
      .then((res) => {
        // console.log('res get week mission overview', res)
        missions.value = res.data
        nextTick().then(() => {
          const scrollWrapper = document.getElementById('open-mission-day')
          if (scrollWrapper) {
            scrollWrapper.scrollIntoView({ behavior: 'smooth', block: 'end' })
          }
        })
      })
      .catch((error) => {
        console.log('error', error.response)
        router.push({ name: ROUTE_NAMES.EXTRAS })
      })

    return {
      missions,
      centerImages,
      returnLink,
      lastUnlockedMission,
      showBalloons
    }
  },
  methods: {
    startMission(mission) {
      if (this.classId && this.classId !== '-1') {
        this.$router.push({ name: ROUTE_NAMES.GAME_SESSION, params: { id: mission.id, classId: this.classId } })
      } else {
        this.$router.push({
          name: ROUTE_NAMES.GAME_SLIDE,
          params: { id: mission.id, slideIndex: 1 },
          query: { adminCode: 'DTT-MM-<3' }
        })
      }
    }
  },
  template: 'OverviewMission'
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";
@import "~@/assets/css/global.scss";

.select-mission-container {
  overflow-y: auto;
  height: 100%;
  justify-content: center;
  border-radius: 0;
  z-index: 1;
  margin-bottom: rem(0);
  display: flex;
  flex-direction: column;
}

.header-container {
  display: flex;
  justify-content: space-between;
  margin: min(rem(35), 5vh) rem(35);
  align-items: center;

  .back {
    cursor: pointer;
    max-height: 10vh;

    a {
      display: flex;
      align-items: center;
      color: white;
      font-size: rem(18);
      font-weight: 500;

      img {
        margin-right: rem(16);
      }
    }
  }

  .center-logo-wrapper {
    height: min(rem(69), 10vh);
    width: rem(155);

    img {
      width: 100%;
      height: 100%;
    }
  }

  .right-logo-wrapper {
    height: min(rem(63), 10vh);

    width: rem(258);

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.media-icon {
  justify-self: center;
}

.missions-scroll-wrapper {
  overflow: auto;
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

.missions-wrapper {
  gap: rem(15);
  align-items: center;
  margin-bottom: rem(80);
  max-height: min(80vh, rem(600));
  margin-top: rem(55);
  display: grid;
  grid-auto-flow: column;
  grid-auto-rows: 1fr;

  &::before, &::after {
    content: '';
    margin: auto;
  }
}

@media screen and (max-width: $screen-720p) {
  .missions-wrapper {
    margin-bottom: rem(40);
    transform: scale(0.7);
  }
}

@media screen and (max-width: $screen-680p) {
  .missions-wrapper {
    margin-bottom: rem(40);
    transform: scale(0.6);
  }
}

@media screen and (max-width: $screen-1440) {
  .missions-wrapper {
    margin-bottom: rem(80);

  }

  .mission-container {
    padding: rem(16);

    &.completed, &.locked {
    }
  }

  .missions-scroll-wrapper {
    //margin-right: 10px;
  }
}
</style>
