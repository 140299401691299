import SessionHistorySlide from './SessionHistorySlide'
import HistoryLocation from './HistoryLocation'

export default class SessionHistory {
  /**
   * The current slide index. (index starts at 1).
   * @type {number}
   */
  currentSlideIndex;

  /**
   * The current slide id.
   * @type {number}
   */
  currentSlideId;

  /**
   * The current sub slide index, if a subslide exists (index starts at 0).
   * @type {number|null}
   */
  currentSubSlideIndex;

  /**
   * The previous slides.
   * @type {SessionHistorySlide[]}
   */
  previousSlides;

  constructor(currentSlideIndex, currentSlideId, currentSubSlideIndex, previousSlides) {
    this.currentSlideIndex = currentSlideIndex
    this.currentSlideId = currentSlideId
    this.currentSubSlideIndex = currentSubSlideIndex
    this.previousSlides = previousSlides
  }

  static parseFromDataObject(data) {
    return new SessionHistory(
      data.currentSlideIndex,
      data.currentSlideId,
      data.currentSubSlideIndex,
      data.previousSlides?.map(previousSlide => SessionHistorySlide.parseFromDataObject(previousSlide)) ?? null
    )
  }

  /**
   * Return a history location object representing the current state of the history.
   * @return {HistoryLocation}
   */
  getCurrentHistoryLocation() {
    const currentSlide = (this.currentSlideIndex - 1) ?? (this.previousSlides.length - 1)
    let isPostAnswer = false
    const currentSubSlideIndex = this.currentSubSlideIndex

    if (this.currentSubSlideIndex !== null) {
      const currentSubSlide = this.previousSlides[currentSlide].subSlideAnswers[currentSubSlideIndex]
      isPostAnswer = currentSubSlide.answerGiven !== null
    } else {
      isPostAnswer = this.previousSlides[currentSlide].answerGiven !== null
    }

    return new HistoryLocation(currentSlide, isPostAnswer, currentSubSlideIndex, false)
  }
}
