<template>
  <button
    type="button"
    :class="buttonClass"
  >
    {{ buttonText }}
  </button>
</template>

<script>
export default {
  name: 'BasicButton',
  props: {
    buttonText: {
      type: String,
      required: true
    },
    buttonClass: {
      type: String,
      default: 'pink-button'
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/css/base.variables";
@import "../../../assets/css/base.mixins";

</style>
