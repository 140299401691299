export default class MazeQuestionContentSubSlideAnswer {
  /**
   * @type {number} the id of the answer.
   */
  id;

  /**
   * @type {string[]} list of directions.
   */
  answer

  constructor(id, answer) {
    this.id = id
    this.answer = answer
  }

  static parseFromDataObject(data) {
    return new MazeQuestionContentSubSlideAnswer(
      data.id,
      data.answer)
  }
}
