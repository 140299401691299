import PhishingQuestionContentSubSlide from '../phishing/PhishingQuestionContentSubSlide'

export default class PhishingQuestionContent {
  /**
   * @type {PhishingQuestionContentSubSlide[]}
   */
  subSlides;

  constructor(subSlides) {
    this.subSlides = subSlides
  }

  static parseFromDataObject(data) {
    return new PhishingQuestionContent(
      data.subSlides.map(subSlide => PhishingQuestionContentSubSlide.parseFromDataObject(subSlide)))
  }
}
