<template>
  <div class="theme-mission-box">
    <div class="top">
      <img :src="imageSrc" alt="" class="media-box">
    </div>
    <div class="bottom">
      <h3 class="title">{{ title }}</h3>
      <p class="description">{{ description }}</p>
      <button class="start-button" @click="() => onButtonClick()">Start missie</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ThemeMissionBox',
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    imageSrc: {
      type: String,
      required: true
    },
    onButtonClick: {
      type: Function,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

p, h3 {
  color: white;
}

.theme-mission-box {
  width: 100%;
  border-radius: rem(20);
  background-color: #626DC6;
  display: flex;
  flex-direction: column;
  gap: rem(16);

  .top {
    width: 100%;
    background: radial-gradient(circle, rgba(181,211,241,1) 0%, rgba(114,170,229,1) 60%);
    border-radius: rem(20);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: rem(32);

    .media-box {
      height: rem(276);
      width: 100%;
      object-fit: contain;
      object-position: center;
    }
  }

  .bottom {
    width: 100%;
    padding: 0 rem(32) rem(24);
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: rem(12);

    .title {
      text-transform: uppercase;
      font-size: rem(20);
    }

    .description {
      font-size: rem(14);
      line-height: rem(20);
    }

    .start-button {
      width: 100%;
      border: none;
      padding: rem(12) 0;
      background-color: white;
      font: var(--font-family-primary);
      font-weight: 500;
      color: #373B52;
      border-radius: rem(8);
      transition: filter .2s;
      cursor: pointer;
      margin-top: auto;

      &:hover {
        filter: brightness(.9);
      }
      &:active {
        filter: brightness(.8);
      }
    }
  }
}
</style>
