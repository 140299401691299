import DilemmaQuestionContentAnswer from './DilemmaQuestionContentAnswer'

export default class DilemmaQuestionContent {
  /**
   * @type {DilemmaQuestionContentAnswer[]}
   */
  answers = [];

  /**
   * Create a new dilemna question content.
   */
  constructor(answers) {
    this.answers = answers
  }

  static parseFromDataObject(data) {
    return new DilemmaQuestionContent(
      data.answers.map(answer => DilemmaQuestionContentAnswer.parseFromDataObject(answer)))
  }
}
