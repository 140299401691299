export default class TrueOrFalseQuestionContent {
  /**
   * @type {String[]}
   */
  answers;

  /**
   * @type {String}
   */
  articleTitle;

  /**
   * @type {String}
   */
  articleContent;

  /**
   * A media file contining an id, original file name, and url for the image.
   */
  mediaFile;

  constructor(answers, articleTitle, articleContent, mediaFile) {
    this.answers = answers
    this.articleTitle = articleTitle
    this.articleContent = articleContent
    this.mediaFile = mediaFile
  }

  static parseFromDataObject(data) {
    return new TrueOrFalseQuestionContent(
      data.answers,
      data.articleTitle,
      data.articleContent,
      data.mediaFile)
  }
}
