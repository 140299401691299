export default class NextMissionSlide {
  /**
   * example data
   * {
   *         "id": 136,
   *         "title": "Video 5",
   *         "description": null,
   *         "sortIndex": 7,
   *         "missionSlideTypeId": 3
   *     }
   */

  constructor(id, title, description, sortIndex, missionSlideTypeId) {
    this.id = id
    this.title = title
    this.description = description
    this.sortIndex = sortIndex
    this.missionSlideTypeId = missionSlideTypeId
  }

  static parseFromDataObject(data) {
    return new NextMissionSlide(data.id,
      data.title,
      data.description,
      data.sortIndex,
      data.missionSlideTypeId
    )
  }
}
