<template>
  <h2 v-if="title" class="mission-title">
    {{ title }}
    <slot name="title-slot"></slot>
  </h2>
  <div v-if="description" class="mission-description">
    <span v-html="description"/>
  </div>
  <div v-else class="no-description-space"></div>
</template>

<script>
export default {
  name: 'SlideHeader',
  props: {
    title: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      required: false,
      default: '',
    },
    // class: {
    //   type: String,
    //   default: null,
    // },
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";
@import "~@/assets/css/mission.scss";

.no-description-space {
  margin-top: rem(16);
}

.mission-description {
  margin-bottom: rem(16);
}

@media screen and (max-width: $screen-720p) {
  .mission-description {
    margin-top: rem(16);
    :deep(p) {
      line-height: unset;
    }
  }

  .no-description-space {
    margin-top: 0;
  }
}

// landscape mobile
@media (orientation: landscape) and (max-height: 500px) {
  .no-description-space {
    margin-top: 0;
  }
}
</style>
