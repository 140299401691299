<template>
  <div v-if="jokers" class="joker-section-wrapper">
    <div class="joker-icon-list-wrapper">
      <div
        v-for="joker in jokers"
        :key="joker.schoolClassJokerId"
        :data-joker-type-id="joker.jokerTypeId"
        class="joker-icon-wrapper"
        :class="{'disabled-joker-icon' : (activatedJoker && activatedJoker.schoolClassJokerId !== joker.schoolClassJokerId) || !joker.jokerAvailableForThisSlide, 'activated-joker-icon' : activatedJoker && activatedJoker.schoolClassJokerId === joker.schoolClassJokerId}"
        :title="joker.jokerTypeName + ': ' + joker.jokerTypeDescription"
        @click="selectJoker(joker)"
      >
        <img :src="joker.jokerTypeIcon"
             class="joker-icon-img"
        />
      </div>
    </div>
    <div :class="isJokerPromptShown ? 'joker-prompt-shown' : null" class="joker-prompt-and-button-wrapper">
      <div class="select-joker-prompt">
        <p>{{ $t('JOKERS.SELECT_JOKER_PROMPT') }}</p>
      </div>
    </div>
  </div>
  <JokerActivateModal :joker="selectedJoker" @choice="(value) => value ? useJoker() : unselectJoker()"/>
  <JokerCannotApplyModal :explanation-text="cannotApplyJokerText" @close="closeCannotApplyModal()"/>
</template>

<script>

import JokerActivateModal from '@/components/partials/modal/JokerActivateModal'
import { mapGetters } from 'vuex'
import { ACTIVATE_JOKER } from '@/store/actions'
import store from '@/store'
import JokerCannotApplyModal from '@/components/partials/modal/JokerCannotApplyModal'

export default {
  name: 'JokerSelector',
  components: {
    JokerCannotApplyModal,
    JokerActivateModal,
  },
  props: {
    hasFinishedAnswer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activatedJoker: null,
      selectedJoker: null,
      cannotApplyJokerText: '',
    }
  },
  computed: {
    ...mapGetters({
      jokers: 'getAvailableJokers',
      session: 'getSession'
    }),
    isJokerPromptShown() {
      return this.hasFinishedAnswer && (!store.getters.getJokerUsed && store.getters.getAvailableJokers?.some(joker => joker.jokerAvailableForThisSlide))
    }
  },
  methods: {
    selectJoker(joker) {
      console.log('selectJoker', joker)

      // todo: use constants
      // todo: use constants
      // todo: use translations
      // todo: use translations
      if (this.activatedJoker) {
        // alert('Je hebt al een joker ingezet voor deze dia')
        this.cannotApplyJokerText = 'Jullie hebben al een joker ingezet voor deze vraag.'
      }
      else if (joker.jokerAvailableForThisSlide) {
        this.selectedJoker = joker
        this.cannotApplyJokerText = ''
      }
      else {
        // Static slide types cannot have any applicable jokers at all
        const anyJokerAvailableForThisSlide = this.jokers.find((joker) => joker.jokerAvailableForThisSlide)
        console.log('anyJokerAvailableForThisSlide', anyJokerAvailableForThisSlide)

        if (joker.jokerTypeId === 4) {
          // alert('Jullie zitten op dit moment nog niet in een streak. Beantwoord eerst drie opeenvolgende vragen goed om een streak te creëren. Zet deze joker vervolgens in om de streak te behouden..')
          this.cannotApplyJokerText = 'Jullie zitten op dit moment nog niet in een streak. Beantwoord eerst drie opeenvolgende vragen goed om een streak te creëren. Zet deze joker vervolgens in om de streak te behouden.'
        }
        else if (joker.jokerTypeId === 3) {
          // alert('Deze missie dia bevat helaas geen extra tip')
          this.cannotApplyJokerText = 'Deze missie dia bevat helaas geen extra tip.'
        }
        else if (!anyJokerAvailableForThisSlide) {
          // alert('Op deze missie dia kan je helaas geen jokers inzetten')
          this.cannotApplyJokerText = 'Jokers inzetten kan op het moment dat jullie finalevragen beantwoorden.'
        }
      }
    },

    unselectJoker() {
      this.selectedJoker = null
    },

    useJoker() {
      this.activatedJoker = { ...this.selectedJoker }
      this.selectedJoker = null
      this.cannotApplyJokerText = ''
      const formData = new FormData()
      // console.log('useJoker() => this.session', this.session)
      formData.append('sessionId', this.session.id)
      formData.append('schoolClassJokerId', this.activatedJoker.schoolClassJokerId)
      this.$store.dispatch(ACTIVATE_JOKER, formData)
    },

    closeCannotApplyModal() {
      this.cannotApplyJokerText = ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";
@import "~@/assets/css/global.scss";

$joker-icon-size: 80px;

.joker-section-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .joker-icon-list-wrapper {
    display: flex;
    align-items: center;
    flex-grow: 1;
    gap: rem(16);

    .joker-icon-wrapper {
      width: $joker-icon-size;
      height: $joker-icon-size;
      background-color: #4D4C89;
      border-radius: 50%;
      padding: rem(8);
      cursor: pointer;

      .joker-icon-img {
        width: 100%;
      }

      &.disabled-joker-icon {
        opacity: 0.4;
        cursor: not-allowed;

        //.joker-icon-img {
        //}
      }

      &.activated-joker-icon {
        cursor: default;
        animation: pulse 2s infinite ease
      }
    }
  }
}

.joker-prompt-and-button-wrapper {
  transition: opacity 0.5s ease;
  opacity: 0;
  pointer-events: none;
  user-select: none;

  &.joker-prompt-shown {
    opacity: 1;
    pointer-events: auto;
  }

  .select-joker-prompt {
    padding: 1.2rem;
    background-color: var(--back_color);
    border-radius: rem(15);
    margin-right: 1.6rem;
    color: white;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.237);
  }

  100% {
    transform: scale(1);
  }
}

</style>
